@import '../../../../theme/helpers/palette.scss';

.incoming-report-row-container {
  .negative-number-color {
    color: $error;
  }

  .positive-number-color {
    color: $light-green;
  }

  .name-initials {
    text-decoration: underline;
  }
}
