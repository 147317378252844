@import '../../theme/helpers/all';

$default-padding: 40px;

.upload-trades {
  .header {
    padding-top: 40px;
    margin: 0 30px;

    h1 {
      float: left;
      font-size: 350%;
      font-weight: 600;
    }
  }

  .upload-area {
    margin: 20px 30px 0 30px;
    padding: $default-padding 100px;

    .dropzone {
      height: 150px;
      border: 2px dashed rgb(102, 102, 102);
      border-radius: 5px;
      cursor: pointer;

      .message {
        color: pallete('white');
        font-weight: 200;
        font-size: 30px;
        text-align: center;
        margin: 55px 0;

        .inner-text {
          padding-left: 10px;
        }
      }
    }

    .results {
      .key {
        color: pallete('light-orange');
        padding-right: 15px;
      }

      .value {
        color: pallete('white');
      }
    }

    .results {
      padding-top: 20px;
    }
  }
}
