@import '../../theme/helpers/all';

.rfqs {
  .header {
    display: flex;
    justify-content: space-between;
    margin-left: 24px;
    padding-top: 24px;
    align-items: center;
    margin-right: 10px;
  }

  .summary {
    float: right;
  }

  .table {
    td {
      vertical-align: middle;
    }
  }

  .results-presenter {
    .panel.panel-filled {
      padding: 0;
    }
  }
}
