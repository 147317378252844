$alabaster: #f9f9f9;
$alto: #ddd;
$background-color: white;
$base-color: curious-blue;
$base-dark: #2a2d33;
$black: #000;
$base-black: #1c1e20;
$boulder: #777;
$grey-box-background-color: #35353c;
$dark-grey-color: #292d33;
$champagne: #faebcc;
$curious-blue: #249dda;
$dark-grey: #3a3b41;
$opaque-orange: rgba(255, 121, 63, 0);
$dark-orange: #f35d00;
$error-background: #f9bdbb;
$error: #d12c2c;
$font-color: boulder;
$gallery: #ededed;
$grey-10: #f2f2f2;
$grey-20: #f5f5f5;
$grey-30: #ededed;
$grey-40: #dcdcdc;
$grey-50: #fafafa;
$grey-60: #a0a0a0;
$grey-70: #a1a1a4;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;
$grey-A100: #d5d5d5;
$grey-A200: #aaaaaa;
$grey-A400: #303030;
$grey-A700: #616161;
$grey-border-color: #616779;
$grey: #808080;
$grey-dark: #888888;
$header-color: tundora;
$light-blue: #00c2e0;
$very-light-green: #7a9d76;
$light-green: #77b546;
$middle-green: #1ab517;
$toggle-green: #3bae2f;
$light-grey: #aaaaaa;
$light-orange: #f6a821;
$hover-green: rgba(119, 181, 70, 0.122);
$medium-blue: #4d81cb;
$accepted-quote-blue-border: #094292;
$best-quote: #77b546;
$medium-orange: #f49422;
$mid-blue: #026aa7;
$mid-grey: #434449;
$pearl-lusta: #fcf8e3;
$potters-clay: #8a6d3b;
$silver-chalice: #aaa;
$light-silver: #d7d7d7;
$silver: #ccc;
$success: #77b546;
$tundora: #444;
$very-light-grey: #f6f6f6;
$very-dark-grey: #33353c;
$white: #fff;
$yellow: #f6a820;
$yellow-opacity-7: rgba(246, 168, 32, 0.7);
$yellow-hover: #e19612;
$hot-invalid-value: #ff4c42;
$sandbox-result-text: rgba(255, 255, 255, 0.741);
$sandbox-border: rgba(204, 204, 204, 0.439);
$sandbox-green: #76b44e;
$sandbox-red: #d22f33;
$sandbox-header-default: #222;
$success-text: #155724;
$error-text: #721c24;
$warning: #ffad0d;
$dark-text: #37373c;
$modal-header-background-color: #1d1f24;
$modal-border-color: #3d404c;
$modal-content-color: #21242a;
$cancelled: #aaaaaa;
$closed: #3bae2f;
$won: #3bae2f;
$lost: #d12c2c;
$ongoing: #f6a820;
$pending: #249dda;
