@import '../../theme/helpers/all';

$select-filter-size: 120px;
$download-template-size: 350px;
$spreadsheet-image-size: 70px;

.onboarding-container {
  padding: $common-space;

  &-header {
    border-bottom: 2px solid $mid-grey;
    padding-bottom: $normal;

    .totalizers {
      &.is-loading .flex-column label:last-child {
        @include includeLoadingSkeleton($dark: true, $rounded: true);
      }

      .flex-column {
        margin-right: $huge;

        label {
          font-size: $normal;

          &:first-child {
            font-size: $small;
            color: var(--primary-color);
          }
        }
      }
    }

    .action-buttons {
      gap: $normal;
      height: $large;
    }
  }

  .results .table-options {
    margin: $normal 0;
  }

  &-filters {
    gap: $small;

    .select-filter {
      width: $select-filter-size;
    }

    > * {
      margin-bottom: 0;
    }
  }
}
