@import '../../../theme/helpers/palette.scss';

.not-confirmed-tag {
  font-size: 9px;
  margin-left: 5px;
  background-color: $error;
  color: $white;
  padding: 1px 4px;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold;
  vertical-align: middle;
}
