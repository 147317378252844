@import '../../../../theme/helpers/palette';
@import '../../../../theme/helpers/guideline';

.rfq-bank-selection {
  padding: $normal;

  p {
    color: $grey-dark;
    font-weight: bold;
  }

  > div > p:last-child {
    font-size: 1rem;
  }

  .bank-options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $normal;

    > div {
      input {
        display: none;

        &[type='checkbox']:checked + label {
          background-color: $toggle-green;
          color: $white;
          font-weight: bold;
        }

        &:disabled + label {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      label {
        border: 1px solid $grey-100;
        padding: $small;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey-100;
        color: $mid-grey;
        border-radius: $border-radius;
        cursor: pointer;
        margin: 0;
      }
    }
  }

  &.field-error {
    color: $error;

    p {
      color: $error;
    }

    .bank-options > div > label {
      border-color: $error;
      color: $error;
    }
  }
}
