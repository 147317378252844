@import '@tame-your-theme/scss';
@import '../../../theme/helpers/all';

.save-buy-sell-bond-rfq-quote-container {
  form {
    > .react-display-flex-row {
      padding: $normal;

      p {
        font-weight: bold;
      }

      > .react-display-flex-row {
        gap: $tiny;
      }
    }

    fieldset {
      display: grid;
      gap: $small;
      grid-template-columns: repeat(3, 1fr);
      padding: $normal;
      padding-top: 0;

      button {
        grid-column: span 3;
      }

      .validated-field {
        padding: 0;
      }
    }
  }
}

.save-issuer-quote-drawer {
  .drawer-content-wrapper-header {
    > .flex {
      flex: 1;
    }
  }

  .drawer-content-wrapper-body {
    .table .status-column {
      display: flex;
      justify-content: flex-end;

      button {
        width: $huge;
      }

      div {
        width: $huge;
        background-color: $grey-400;
        padding: $tiny 0;
        text-align: center;
        border-radius: $border-radius;

        &.accepted,
        &.rejected {
          color: $white;
          font-weight: bold;
        }

        &.accepted {
          background-color: $success;
        }

        &.rejected {
          background-color: $error;
        }
      }
    }

    .form-control:not(:disabled) {
      border: 1px solid darken-theme-color(--dark-white-background-color, 10%);
      background-color: transparent;
      color: var(--dark-white-background-contrast-color);
    }
  }
}

.not-interested-popover.popover.left > .arrow {
  $margin-top-toggle-offset: -26px;

  margin-top: $margin-top-toggle-offset;
}
