@import '../../theme/helpers/all';

.dashboard-container {
  margin: 0;

  .rfqs-container {
    margin: 0 30px;
  }

  .tab-content {
    width: 100%;
  }

  .header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;

    .new-rfq-button {
      height: 2.5em;
      margin: 0.5em;
      width: 10em;
      justify-self: end;
      align-self: end;
    }

    h1 {
      margin: 0.6em 0 0 0;
      padding-bottom: 0;
      cursor: default;
      margin-bottom: $normal;
    }
  }

  .results-presenter .table {
    font-size: 14px;

    tr {
      height: $table-row-height;

      td {
        vertical-align: middle;

        &.actions {
          width: 120px;
          text-align: right;

          button {
            color: $white;

            &:hover {
              color: $grey-900;
            }
          }
        }
      }
    }
  }

  .dashboard-items {
    display: grid;
    height: 100ch;
    gap: 1em;
    grid-template-areas:
      'incoming adi-trades'
      'outgoing adi-trades';
    grid-template-columns: 60% 40%;

    > div {
      overflow: auto;
      &:nth-child(1) {
        grid-area: incoming;
      }

      &:nth-child(2) {
        grid-area: outgoing;
      }

      &:nth-child(3) {
        grid-area: adi-trades;
      }
    }

    .summary-container {
      background-color: $very-dark-grey;
      position: sticky;
      top: 0;
      padding: $small $small 0;
    }

    .panel-presenter {
      .panel-filled {
        overflow: auto;
        padding: 0;
        margin-top: 0;
        background-color: $very-dark-grey;
        min-height: initial;
        height: initial;

        .table-container {
          padding-right: $small;
          padding-left: $small;
          max-height: initial;

          table.table {
            thead > tr > th,
            tbody > tr > td {
              padding-left: 6px;
              padding-right: 6px;
            }
          }
        }

        .no-data {
          margin-bottom: $large;
          padding-top: $normal;
        }
      }
    }
  }

  @media only screen and (min-width: 1600px) {
    .dashboard-items {
      flex-direction: row;
    }

    .header {
      .new-rfq-button {
        margin: 0;
      }
    }
  }

  .dashboard-container-header {
    background-color: #40424a;

    .new-rfq-button {
      min-height: 50px;
    }
  }

  .tab-pane > .results-presenter {
    margin-top: $normal;
  }
}
