@import '../../../../../../theme/helpers/all';

$send-unsolicited-ratesheet-dialog: 63rem;

.send-unsolicited-ratesheet-dialog {
  .modal-body {
    width: $send-unsolicited-ratesheet-dialog;
    background-color: $modal-header-background-color;
  }
}
