@import '../../../../theme/helpers/all';

$default-dialog-response-border: 1px solid #ffffff1a;
$label-grey: #ffffff80;

.response-container {
  background-color: $mid-grey;
  border-radius: 10px;

  padding: 0 20px;

  .response-item {
    &:not(:first-child) {
      border-top: $default-dialog-response-border;
    }

    p {
      color: white;
      word-break: break-word;

      &:first-of-type {
        flex: 1 1 30%;
      }

      &:last-of-type {
        flex-basis: 70%;
      }
    }

    p:first-child {
      color: $label-grey;
    }

    padding: 15px 0;

    &.hilite {
      font-weight: bold;
    }
  }

  hr {
    border: $default-dialog-response-border;
    margin: 0;
  }
}
