@import '@tame-your-theme/scss';

@import '../../theme/helpers/palette.scss';
@import '../../theme/helpers/guideline.scss';

.input-search {
  .input-group {
    border: 1px solid darken-theme-color(--background-main-contrast-color, 50%);
    border-radius: $hair;

    input {
      border-color: transparent;
      border-top-left-radius: $hair;
      border-bottom-left-radius: $hair;
      background-color: transparent;
      color: var(--background-main-contrast-color);

      &:focus {
        color: var(--primary-color);
      }

      &::placeholder {
        color: var(--background-main-contrast-color);
      }
    }

    .input-group-addon:last-child {
      border-top-right-radius: $hair;
      border-bottom-right-radius: $hair;
      background-color: transparent;
      color: var(--background-main-contrast-color);

      border: none;
    }

    &:focus-within {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
    }

    transition: border 0.5s ease-out, box-shadow 0.5s ease-out;
  }

  .glyphicon.glyphicon-remove {
    cursor: pointer;

    &:hover {
      transition: 0.5s;
      color: $error;
    }
  }
}
