@import './loader.scss';
@import './button.scss';
@import './light-table.scss';

.big-title {
  font-size: 350%;
  padding-bottom: 5px;
}

.big-label {
  font-size: 180%;
}

.circle {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.weight-600 {
  font-weight: 600;
}
