@import '../../theme/helpers/all';

$border-radius: 10px;

.confirm-offer-dialog {
  .modal-body {
    .amount {
      background-color: $middle-green;
      font-size: 6rem;
      text-align: center;
      border-radius: $border-radius;
      color: $white;
    }

    .details-box {
      background-color: var(--background-dark-color);
      color: var(--background-dark-contrast-color);
      margin-top: 30px;
      font-size: 2rem;
      padding: 20px;
      border-radius: $border-radius;

      .percentage {
        padding-left: $tiny;
      }
    }

    .message-box {
      font-size: 2rem;
      padding: 0 0 20px;
      color: $white;
    }

    &.has-message-box {
      padding-top: 20px;
    }
  }
  .modal-footer {
    display: flex;
    justify-content: center;

    p {
      text-align: center;
      padding: $normal 0;
    }

    button {
      font-size: 2rem;
    }

    > div {
      width: 470px;

      .btn {
        border-radius: $border-radius;
        height: 60px;
      }

      .btn-solid-primary {
        color: $dark-grey-color;

        &:hover,
        &:focus {
          color: $white;
        }

        ~ .btn {
          border: 2px solid var(--primary-text-color);
          color: var(--primary-text-color);
          margin: 0;
          margin-top: $normal;
          @include transition(all 0.4s);

          &:hover {
            color: var(--primary-color);
            border-color: var(--primary-color);
          }
        }
      }
    }
  }

  &.is-loading {
    pointer-events: none;
  }
}
