$currentDirectory: "./";

@font-face {
  font-family: "bravi-manager";
  src: url($currentDirectory + "bravi-manager.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "bravi-manager.woff") format("woff"),
    url($currentDirectory + "bravi-manager.ttf") format("truetype"),
    url($currentDirectory + "bravi-manager.svg#bravi-manager") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "bravi-manager";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-adicionar:before {
  content: "\61";
}
.icon-alerta:before {
  content: "\6f";
}
.icon-android:before {
  content: "\76";
}
.icon-apple:before {
  content: "\77";
}
.icon-apps:before {
  content: "\70";
}
.icon-apps-instalados:before {
  content: "\75";
}
.icon-atencao:before {
  content: "\62";
}
.icon-buscar:before {
  content: "\64";
}
.icon-calendario:before {
  content: "\65";
}
.icon-check:before {
  content: "\66";
}
.icon-config:before {
  content: "\73";
}
.icon-deletar:before {
  content: "\67";
}
.icon-editar:before {
  content: "\68";
}
.icon-editar-categoria:before {
  content: "\69";
}
.icon-imagem:before {
  content: "\6a";
}
.icon-info:before {
  content: "\6b";
}
.icon-link:before {
  content: "\74";
}
.icon-menu:before {
  content: "\71";
}
.icon-publicar:before {
  content: "\6c";
}
.icon-rascunho:before {
  content: "\6d";
}
.icon-relatorios:before {
  content: "\79";
}
.icon-relatorios-erro:before {
  content: "\78";
}
.icon-upload:before {
  content: "\6e";
}
.icon-usuarios:before {
  content: "\72";
}
.icon-youtube:before {
  content: "\63";
}
