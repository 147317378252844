@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';
@import '../reports.scss';

$box-min-width: 330px;
$number-of-report-boxes: 3;
$box-gutter: $normal;
$grey-box-padding: $normal;

.reports-by-period-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .reports-tab-container-header {
    width: 100%;
  }

  .feature-upsell-container {
    display: flex;
    align-items: center;
    gap: 1em;

    .feature-upsell-message {
      color: $white;
    }

    button {
      min-width: 10rem;
    }
  }

  .datepicker-container {
    width: 100%;
  }

  .download-button-wrapper {
    .btn-download-reports {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 10rem;
      height: 40px;
    }
  }

  .selectors-container {
    width: 100%;

    .item-selector {
      margin: 0 15px;

      &__item .checkbox {
        label::after {
          color: $very-light-green;
        }

        input[type='checkbox']:checked + label::before {
          background-color: $dark-grey;
          border-color: $very-light-green;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    > label {
      color: white;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: $normal;
    }

    .month-picker {
      background: $dark-grey-color;
      padding: $normal;
      border-radius: $tiny;
      min-width: $box-min-width;
    }
  }

  h1 {
    margin-top: $large;
    margin-bottom: $normal;
    font-weight: bold;
    font-size: 60px;
  }

  .format-selector {
    margin-left: 50px;

    .radio-group {
      display: flex;
      flex-direction: row;

      > .radio-item-box {
        background-color: transparent;
        border: 0;
        color: $white;
        min-width: 6em;
        padding-left: 0;
      }

      > .radio-item {
        padding-left: 2em;
        font-weight: normal;

        > span.circle {
          left: 0;
        }
      }
    }
  }

  .monthly-report-selector {
    display: flex;

    .format,
    .datepicker-from,
    .datepicker-to {
      > span {
        color: $light-silver;
        margin-bottom: $tiny;
      }
    }

    &.is-disabled {
      .format {
        opacity: 0.5;
      }
    }
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

@media (max-width: $mobile-width) {
  .reports-by-period-container {
    .datepicker-container {
      flex-direction: column-reverse !important;

      .month-picker {
        margin: $normal 0;
      }
    }

    .selectors-container {
      flex-direction: column !important;

      .item-selector {
        margin: 0;
      }
    }
  }
}
