.outgoing-quotes-table {
  padding: 1em;
  width: 100%;

  .maturity-column {
    text-align: center;
  }

  td, th {
    padding: 0.5em 0.5em;
  }
}
