.chart-legend {
  padding-top: 10px;
  padding-bottom: 50px;

  .square {
    font-size: 140%;
  }

  .dotted {
    border-bottom: 3px dotted;
    width: 15px;
    display: inline-block;
    margin-bottom: 3px;
  }

  .legend-label {
    font-size: 96%;
    padding-right: 20px;
    padding-left: 4px;
  }
}
