@import '../../../../../theme/helpers/all';

.update-ratesheet-button {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $tiny;
  }

  .should-update-ratesheet {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
}
