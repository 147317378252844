@import '@tame-your-theme/scss';

@import '../../theme/helpers/palette.scss';
@import '../../theme/helpers/mixins.scss';

$header-color: $grey-100;
$header-contrast-color: $base-dark;
$nav-color: $dark-grey-color;
$nav-contrast-color: $grey-70;

:root {
  --actions-hover: 0.8;

  @include create-theme-color(--header-color, $header-color);
  @include create-theme-color(--header-contrast-color, $header-contrast-color);
  @include create-theme-color(--nav-color, $nav-color);
  @include create-theme-color(--nav-contrast-color, $nav-contrast-color);
  @include create-theme-color(--primary-color, $yellow);
  @include create-theme-color(--background-main-color, $base-dark);
  @include create-theme-color(--background-main-contrast-color, $grey-40);
  @include create-theme-color(--background-light-color, $white);
  @include create-theme-color(--background-light-contrast-color, $dark-grey-color);
  @include create-theme-color(--background-dark-color, $mid-grey);
  @include create-theme-color(--background-dark-contrast-color, $white);
  @include create-theme-color(--primary-text-color, $white);
  @include create-theme-color(--primary-contrast-color, $dark-grey-color);
  @include create-theme-color(--paper-color, $grey-40);
  @include create-theme-color(--paper-contrast-color, $dark-grey-color);
  @include create-theme-color(--secondary-text-color, $grey-10);

  --grey-30: #{$grey-30};
  --grey-50: #{$grey-50};
  --grey-100: #{$grey-100};
  --grey-200: #{$grey-200};
  --grey-300: #{$grey-300};
  --grey-400: #{$grey-400};
  --grey-500: #{$grey-500};
  --grey-600: #{$grey-600};
  --grey-700: #{$grey-700};
  --grey-800: #{$grey-800};
  --grey-900: #{$grey-900};
  --grey-A100: #{$grey-A100};
  --grey-A200: #{$grey-A200};
  --grey-A400: #{$grey-A400};
  --grey-A700: #{$grey-A700};
}

:export {
  themePrimaryColor: #{$yellow};
  themeNavColor: #{$nav-color};
  themeNavContrastColor: #{$nav-contrast-color};
  themeHeaderColor: #{$header-color};
  themeHeaderContrastColor: #{$header-contrast-color};
  themeBackgroundMainColor: #{$base-dark};
  themeBackgroundMainContrastColor: #{$grey-40};
  themeBackgroundLightColor: #{$white};
  themeBackgroundLightContrastColor: #{$dark-grey-color};
  themeBackgroundDarkColor: #{$mid-grey};
  themeBackgroundDarkContrastColor: #{$white};
  themePaperColor: #{$grey-40};
  themePaperContrastColor: #{$dark-grey-color};
  themePrimaryTextColor: #{$white};
  themePrimaryContrastColor: #{$dark-grey-color};
  themeSecondaryTextColor: #{$grey-10};
}
