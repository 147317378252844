@import '../../../theme/helpers/all';

$max-tabs: 6;
$tab-width: 175px;
$tag-font-size: 10px;
$tag-size: 16px;
$icon-size: 22px;
$slider-height: 2px;
$hair: 3px;

@mixin set-slide-position($width) {
  @for $i from 1 through $max-tabs {
    li:nth-of-type(#{$i}) {
      &.active {
        ~ .slide {
          left: calc((#{$width}) * #{$i - 1});
        }
      }
    }
  }
}

.light-tabs {
  .tab-content {
    padding: $common-space;
    border-radius: $tiny;
  }

  .slide-tabs {
    font-size: 1.2rem;
    margin: 0;

    .nav-tabs {
      padding-right: 0;
      background-color: $dark-grey;

      > li {
        width: $tab-width;

        a {
          padding: $tiny $small;
        }

        .tag {
          margin: $tiny 0;
          position: absolute;
          font-size: $tag-font-size;

          > span {
            width: $tag-size;
            line-height: $tag-size;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            background-color: $curious-blue;
            color: $white;
            border-radius: 50%;
            font-weight: bold;
          }
        }

        .circle-alert {
          display: inline-flex;
          margin: 0;
          padding: $hair;
          background-color: $error;
          border-radius: $border-radius;
        }

        .circle-alert.compliant {
          background-color: $success;
        }
      }

      li.active a:hover,
      li.active a:focus {
        background-color: initial;
      }

      @include set-slide-position($tab-width);

      .slide {
        top: calc(#{$large} - #{$tiny});
        width: $tab-width;
      }
    }

    .icon {
      vertical-align: middle;
      $icons: dashboard hourglass;
      $dashboard-icons-path: '../../../assets/icons/dashboard/';

      @each $icon in $icons {
        &.#{$icon} {
          @include image-2x($icon, $icon-size, $icon-size, $dashboard-icons-path);
        }
      }
    }
  }

  .tab-container-header {
    background-color: $dark-grey;
    height: $large;
  }

  .tab-pane > .results-presenter {
    margin-top: $normal;
  }

  &.fixed {
    > .tab-container-header {
      position: sticky;
      top: 60px;
      z-index: 1028;
    }
  }
}

.light-tabs.secondary {
  .slide-tabs {
    min-width: $tab-width;

    .nav-tabs {
      background-color: transparent;

      .slide {
        width: $tab-width;
        height: $hair;
        top: calc(#{$large} - #{$tiny} + 1px); // 1px is aimed to compensate the 3px highlight height
      }

      > li {
        width: $tab-width;
      }

      @include set-slide-position($tab-width);
    }
  }

  .tab-container-header {
    height: $large;
    background-color: transparent;
    border-bottom: 2px solid $mid-grey;
  }
}
