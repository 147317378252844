body.no-scroll {
  overflow: hidden;
}

.iframe-container {
  position: relative;
  margin-top: 15px;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    padding-top: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    border-width: 0;
  }
}
