@import '../../theme/helpers/all';

.results-presenter {
  > div {
    padding-left: 0;
    padding-right: 0;
  }

  .panel-filled {
    border-radius: $border-radius;

    .records-loading {
      margin-bottom: $panel-margin-bottom;
    }

    table.table {
      > tbody > tr,
      > thead > tr {
        height: $table-row-height;

        > td,
        > th {
          padding: 8px;
          vertical-align: middle;
        }
      }
    }
  }

  .panel.panel-filled {
    background-color: transparent;
  }

  .results {
    border-radius: $border-radius;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: $small;

    h4 {
      color: $white;

      &.result-title {
        display: inline-block;
        font-size: 1.8rem;
      }
    }
  }
}
