@import '../../../../theme/helpers/palette.scss';
@import '../../../../theme/helpers/guideline.scss';

.drawer.send-quote {
  .drawer-content-wrapper {
    box-shadow: none;

    &-header {
      border-bottom: none;

      button.reset-tour {
        display: flex;
        margin-left: $small;
        align-items: center;
        justify-content: center;
        padding: $tiny;
        background-color: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        border-radius: 50%;
        width: $normal;
        height: $normal;
        font-size: 1rem;

        &:hover {
          border: 1px solid var(--primary-contrast-color);
          color: var(--primary-contrast-color);
        }

        &:focus {
          outline: none;
        }
      }
    }

    &-body {
      border-right: 1px solid $grey-400;
    }
  }

  &.first {
    margin-right: 66vw;
  }

  &.second {
    margin-right: 33vw;
  }

  &.history-date .drawer-content-wrapper-body {
    background-color: $grey-200;
  }

  &.history-tenor .drawer-content-wrapper-body {
    background-color: $grey-50;
  }

  .drawer-content-wrapper-header {
    height: 70px;
    box-sizing: content-box;
    padding: 0 $small;
  }

  .no-mask {
    right: 0px;
    transform: none;
  }

  .loader {
    display: flex;
    justify-content: center;
    margin-top: $normal;

    .ball-pulse > div {
      background-color: var(--primary-color);
    }
  }
}
.switcher-button {
  position: absolute;
  right: 0;
  height: 40px;
  z-index: 99999;
  box-shadow: 0 $tiny 2px 0 rgba(60, 64, 67, 15%);
  border-radius: 38px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: $small;
  background-color: var(--background-light-color);
  color: var(--primary-color);
  border: none;
  top: 40vh;

  svg {
    color: var(--primary-color);
  }

  &:hover {
    background-color: var(--background-light-contrast-color);
    color: var(--background-light-color);
    border: none;

    svg {
      color: white;
    }
  }

  &:active,
  &:focus {
    background-color: var(--background-light-contrast-color);
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 15%);
  }
}

@media (max-width: $large-desktop-width) {
  .drawer.send-quote {
    .rc-table thead tr th,
    .rc-table tbody tr td {
      padding: $small $tiny;
    }
  }
}

@media (max-width: $normal-desktop-width) {
  $font-large: 2rem;
  $font-normal: 1.2rem;
  $font-small: 1rem;

  .drawer.send-quote {
    .drawer-content-wrapper-header {
      h2 {
        font-size: $font-large;

        & > span:last-child {
          font-size: $font-large;
        }
      }

      button.close {
        width: $normal;
        height: $normal;
        margin-left: 0;
        font-size: $font-large;
      }

      .sell-bond-rfq-status {
        font-size: $font-normal;
      }
    }

    .rc-table thead tr th,
    .rc-table tbody tr td {
      font-size: $font-small;
      padding: $small $tiny;
    }

    .history-data-header {
      font-size: $font-normal;
      padding: $small;
    }

    .send-quote {
      &-header {
        font-size: $font-normal;

        > .react-display-flex-row {
          padding: 0;
        }
      }

      & .auto-fill-rates {
        padding: $small;
        font-size: $font-normal;

        button {
          font-size: $font-normal;
        }
      }

      > .react-display-flex-column {
        padding: $small;

        button {
          font-size: $font-normal;
        }
      }
    }
  }
}
