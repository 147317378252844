@import '../../theme/helpers/palette.scss';

.licenced-feature-container {
  $gap: 2rem;
  $carousel-radius: 2px;
  $indicator-color: $grey-400;

  height: calc(100vh - 300px);

  > div {
    height: 100%;
  }

  .flex-row {
    gap: $gap;
    justify-content: center;
  }

  .message {
    gap: $gap;
    flex-basis: 50rem;
    justify-content: center;

    &-text {
      white-space: break-spaces;
    }
  }

  button {
    max-width: 15em;
  }

  .carousel {
    max-width: 600px;
    border-radius: $carousel-radius;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.135), 0 0px 10px rgba(0, 0, 0, 0.27);

    .carousel-inner,
    img {
      border-radius: $carousel-radius;
    }

    ol.carousel-indicators > li {
      border: 1px solid $indicator-color;
      &.active {
        background-color: $indicator-color;
      }
    }
  }

  .carousel-control.left,
  .carousel-control.right {
    filter: none;
    background-image: none;
  }
}
