@import '../../../theme/helpers/guideline.scss';

.results-presenter .results .table-options {
  &-filter {
    flex: 1;
  }

  &-actions {
    padding-top: $tiny;

    button {
      margin: $normal 0 0 $small;
    }

    svg {
      padding-top: $hair;
    }
  }
}
