@import '../../theme/helpers/all';

.value-highlight {
  display: flex;
  align-items: center;
  gap: $tiny;
  margin-right: $small;

  &.is-loading > .white-color {
    @include includeLoadingSkeleton($dark: true, $rounded: true, $size: 'md');
  }
}
