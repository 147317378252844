@import '@tame-your-theme/scss';

@import '../../theme/helpers/guideline.scss';
@import '../../theme/helpers/palette.scss';

.form-group {
  &.has-error {
    label {
      color: $error;
    }
  }
}

.form-group {
  label {
    color: var(--background-main-contrast-color);
  }
}

input,
textarea {
  &.form-control {
    background-color: var(--background-main-color);
    color: var(--background-main-contrast-color);
    border-color: darken-theme-color(--background-main-contrast-color, 50%);

    &:focus {
      border-color: var(--primary-color);
      box-shadow: 0 0 0 1px var(--primary-color);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--background-main-contrast-color);
      -webkit-box-shadow: 0 0 0px 1000px var(--background-main-color) inset;
      caret-color: var(--background-main-contrast-color);
    }

    .input-group & {
      height: $input-group-height;

      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.light {
  input.form-control,
  .form-group:not(.has-error) .form-control {
    border: transparent;
  }
}

.input-group-addon {
  background-color: $dark-grey-color;
  border-color: $dark-grey-color;
  color: $light-grey;

  &:last-child {
    background-color: $dark-grey-color;
  }
}

.label-success {
  background-color: #77b546;
}

.label-danger {
  background-color: #ff3e3d;
}

.modal-backdrop.in {
  opacity: 0.5;
}

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin-top: -30px;
}

@media (min-width: 768px) {
  .modal-dialog {
    min-width: 600px;
    max-width: 80%;
    width: auto;
  }
}
