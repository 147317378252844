@import '../helpers/palette.scss';
@import '../helpers/guideline.scss';

.primary-button {
  background-color: var(--primary-color);
  border-radius: $border-radius;
  border: 0;
  color: var(--primary-contrast-color);
  min-height: 36px;

  &:hover {
    background-color: var(--primary-color-hover);
    color: var(--primary-contrast-color);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: $grey-500;
    border: 1px solid $grey-border-color;
    color: $white;
    opacity: 0.5;
  }
}

.navbar-nav .primary-button,
.navbar-default .navbar-nav > li > .primary-button {
  @extend .primary-button;
  font-weight: 500;

  &:disabled {
    background-color: $light-silver;
    border: 0;
  }

  &:focus {
    background-color: var(--primary-color);
  }
}
