@import '@tame-your-theme/scss';

@import '../../../theme/helpers/all.scss';

$min-dt-height: 40px;
$min-data-description-width: 150px;
$section-padding: $normal;

.drawer {
  @include setDarkWhiteTheme(dark);

  $alpha: 9;

  .drawer-content {
    background-color: var(--dark-white-background-color);

    &-wrapper {
      background-color: var(--dark-white-background-color);
      width: 100%;
      height: 100%;

      &-header {
        background-color: rgba(var(--dark-white-background-color-rgb), #{$alpha});
        border-bottom: 1px solid rgba(var(--dark-white-background-contrast-color-rgb), 0.1);
        padding: $normal;

        > .flex {
          gap: $normal;
        }

        h2 {
          color: var(--dark-white-background-contrast-color);
        }

        .sell-bond-rfq-status {
          width: auto;
          padding: $tiny $small;
          border-radius: $normal;

          &.verbose {
            width: auto;
            border-radius: none;
            font-weight: normal;
          }
        }

        button.close {
          @extend %rounded-button;
          opacity: 1;
          margin-left: $small;
          border-radius: $border-radius;

          &:hover {
            background-color: var(--background-light-contrast-color);
          }
        }
      }

      &-body {
        background-color: var(--dark-white-background-color);
        height: 100%;
        overflow: auto;

        label {
          color: var(--dark-white-background-contrast-color);
        }

        dl {
          display: grid;
          gap: $small;
          grid-template-columns: repeat(3, 1fr);

          padding: $section-padding;

          border-bottom: 1px solid rgba(var(--dark-white-background-contrast-color-rgb), 0.1);

          > div {
            gap: $small;
            min-width: $min-data-description-width;
            min-height: $min-dt-height;

            dt {
              color: $grey-700;
              margin-bottom: $tiny;
            }

            dd {
              color: var(--dark-white-background-contrast-color);
            }
          }

          & ~ section {
            padding: $small $section-padding $small $section-padding;

            h4 {
              color: var(--dark-white-background-contrast-color);
              font-weight: bold;
            }
          }
        }

        .results-presenter {
          margin-left: $small;
          margin-right: $small;

          .show-message {
            color: var(--dark-white-background-contrast-color);
            background-color: var(--dark-white-background-color);
          }

          table {
            th {
              color: rgba(var(--dark-white-background-contrast-color-rgb), 0.5);
            }

            &.table-striped > tbody > {
              tr {
                color: var(--dark-white-background-contrast-color);

                &:nth-of-type(even) {
                  background-color: transparent;
                }

                &:nth-of-type(odd) {
                  background-color: rgba(var(--dark-white-background-contrast-color-rgb), 0.05);
                }

                &:hover {
                  background-color: rgba(var(--dark-white-background-contrast-color-rgb), 0.1);
                }
              }
            }

            td {
              .action-column {
                text-align: right;
              }
            }
          }
        }

        .is-loading {
          dl dd {
            @include includeLoadingSkeleton();
            color: transparent;
          }

          .validated-field input {
            @include includeLoadingSkeleton();
            color: transparent;
          }
        }

        .highlight {
          background: rgba(var(--dark-white-background-contrast-color-rgb), 0.1);
        }
      }

      &-footer {
        background-color: rgba(var(--dark-white-background-color-rgb), #{$alpha});
        display: flex;
        border-top: 1px solid rgba(var(--dark-white-background-contrast-color-rgb), 0.1);
        padding: $normal;

        img {
          margin: 0 auto;
        }
      }
    }

    .rc-collapse {
      background-color: darken-theme-color(--background-main-color, 5%);
      border: 0;
      border-bottom: 1px solid rgba(var(--dark-white-background-contrast-color-rgb), 0.1);

      p {
        margin: 0;
      }

      .rc-collapse-item {
        border-color: rgba(var(--dark-white-background-contrast-color-rgb), 0.1);

        .alert {
          &-warning {
            border-color: var(--primary-color);
            background-color: var(--primary-color);
            color: var(--primary-contrast-color);
          }
        }

        &-active {
          .rc-collapse-header {
            border-bottom: 1px solid rgba(var(--dark-white-background-contrast-color-rgb), 0.1);
          }
        }
      }

      &-header {
        display: flex;
        flex-direction: row-reverse;

        > svg {
          font-size: 2.5rem;

          & ~ span {
            color: $light-grey;
            font-size: 2rem;
            flex: 1;
          }
        }

        &:hover {
          background-color: var(--background-main-color-hover);
        }
      }

      &-content {
        color: var(--secondary-text-color);
        background-color: darken-theme-color(--background-main-color, 5%);
        padding-bottom: $normal;
      }

      * {
        outline: none;
      }
    }

    .dialog-header {
      padding: $normal;
      gap: $normal;
    }

    .drawer-content-wrapper-footer {
      justify-content: flex-end;

      button {
        width: 12rem;
        @extend %primary-button;
      }
    }
  }

  &.light {
    @include setDarkWhiteTheme(white);
    @include includeThemedSelect();

    .is-open .Select-control {
      box-shadow: none;
    }

    .Select-control,
    .Select-control .Select-value .Select-value-label,
    .form-control {
      background-color: $grey-100;
      color: var(--dark-white-background-contrast-color);
    }

    .datepicker-component.datepicker-bg {
      background-color: $grey-100;
      color: var(--dark-white-background-contrast-color);
    }

    .validated-field {
      .flex-row > span:first-child {
        color: $grey-dark;
        font-weight: bold;
      }

      .datepicker-component {
        border: 1px solid transparent;

        input {
          border: none;

          + .input-group-addon {
            border-bottom: none;
          }
        }
      }

      &.field-error {
        .datepicker-component {
          border: 1px solid $error;

          input + .input-group-addon {
            color: $error;
          }
        }

        .flex-row > span:first-child {
          color: $error;
        }
      }
    }

    .skeleton-table {
      > tbody > tr > td > div {
        @include includeLoadingSkeleton($rounded: true);
      }
    }
  }
}
