@import '../../../../../../theme/helpers/palette';
@import '../../../../../../theme/helpers/guideline';

$border: 1px solid $light-silver;
$form-input-height: 3.8rem;

.request-quotes-to-sell-drawer {
  .drawer-content-wrapper-body {
    display: flex;
    flex-direction: column;
  }

  .request-quotes-form {
    margin: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: $grey-dark;

    .form-button {
      margin: $normal;
    }

    .form-group-item {
      border-top: $border;
      border-bottom: $border;
      padding: $small $normal $small $normal;

      > div:last-child {
        gap: $normal;
      }

      .information-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0;
        margin-bottom: $tiny;
        font-size: $tiny;
        width: $small;
        height: $small;
      }

      .validated-field {
        padding: 0;

        &.field-error input {
          border-color: transparent;
        }

        > input,
        .datepicker-component,
        .Select-control {
          background-color: $grey-100;
          border-radius: $border-radius;
          border: 1px solid $light-silver;
          color: $mid-grey;
          font-size: 1.6rem;
          height: $form-input-height;

          input {
            padding-left: $small;
          }

          > * {
            border-bottom: none;
          }
        }

        .select-control {
          &.is-open {
            > .Select-control {
              &:active,
              &:focus-within,
              &:focus-visible,
              &:focus {
                border-color: $light-silver;
              }
            }
          }
        }

        .Select.has-value.Select--single > .Select-control .Select-value .Select-value-label {
          color: $mid-grey;
        }

        .Select.is-focused:not(.is-open) > .Select-control {
          border-color: $light-silver;
        }

        .sell-all-toggle {
          > span,
          > div {
            margin-right: $tiny;
            margin-bottom: $tiny;
          }
        }
      }
    }
  }
}
