@import '../../../../theme/helpers/guideline.scss';
@import '../../../../theme/helpers/palette.scss';

.password-field {
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: $small;
    background-color: transparent;
    font-size: 1rem;

    &:hover {
      color: $yellow;
    }

    &:focus {
      outline-width: 0;
    }
  }
}
