@import '../../../theme/helpers/palette';
@import '../../../theme/helpers/guideline';

.buy-sell-bonds-create-rfq-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .react-display-flex-row {
    gap: $normal;
  }

  .validated-field {
    padding: 0;
    min-height: 90px;
  }

  .datepicker-component input[type='text'] {
    height: 32px;
  }

  .form-group-item {
    border-bottom: 1px solid $light-silver;
    padding: $small $normal $small $normal;
  }

  .has-austraclear-toggle {
    padding: $normal;
    padding-bottom: $tiny;
    gap: $tiny;

    > p {
      margin: 0;
      font-weight: bold;
    }
  }

  .rfq-bank-selection {
    padding: $normal;

    .error-message {
      min-height: 25px;
    }

    &.field-error {
      p {
        color: $error;
      }
    }
  }

  .btn-solid-primary {
    margin: $normal;
  }
}
