@import '../../theme/helpers/all';

.panel-collapsible {
  &.panel {
    background-color: $very-dark-grey;
  }

  a:hover,
  a,
  a:focus {
    color: inherit;
    text-decoration: none;
  }

  &.with-border {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border: 0;
    border-left: 3px solid;

    &.green {
      border-left-color: $success;
    }

    &.red {
      border-left-color: $error;
    }
  }

  &.panel {
    border-color: $very-dark-grey;

    .panel-heading {
      font-size: 1.4rem;
      background-color: $very-dark-grey;
      border-color: $very-dark-grey;
      padding-right: 0;

      .loader,
      .no-records-message {
        padding-top: 10px;
      }

      .summary-highlight {
        > p {
          font-size: 1.2rem;
          margin-bottom: 0;
        }
      }

      &:hover {
        background-color: $dark-grey;
        cursor: pointer;
      }

      .glyphicon {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 $small;
      }
    }

    .panel-body {
      padding: 0 $normal;
      margin-bottom: 0;

      .results-presenter {
        .results.panel.panel-filled {
          margin-bottom: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
        .table {
          td {
            vertical-align: middle;
          }
        }
      }

      .footer {
        margin: 0 0 $normal;
      }
    }
  }

  &.is-loading {
    .summary-highlight > div {
      height: $normal;

      @include includeLoadingSkeleton($dark: true, $rounded: true, $size: 'md');
    }

    .value-highlight > .white-color {
      @include includeLoadingSkeleton($dark: true, $rounded: true, $size: 'md');
    }
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.7;

    .glyphicon-chevron-down {
      visibility: hidden;
    }
  }
}
