@import '../../../../theme/helpers/all';

label.auto-fill-label {
  cursor: pointer;
}

.drawer.send-quote .drawer-content-wrapper-body {
  dl {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    border: none;
    row-gap: $normal;

    > div {
      gap: 0;
    }
  }

  dt {
    color: $dark-orange;
    opacity: 0.8;
    font-weight: normal;
  }
}

.send-quote {
  > .react-display-flex-column {
    padding: $normal $small;

    &.send-quote-header {
      padding: 0;
    }
  }

  &-header {
    background-color: var(--background-light-color);
    color: $base-black;

    > .react-display-flex-row {
      padding: 0 $normal;
      margin-bottom: $normal;

      > .react-display-flex-column {
        > dd {
          font-weight: bold;
        }

        > dt {
          color: $dark-orange;
          font-weight: normal;
          opacity: 0.6;
        }
      }
    }

    > .react-display-flex-column {
      background-color: $grey-50;
      padding: 0 $normal;

      > .react-display-flex-row {
        padding: $small 0;

        dt {
          font-weight: normal;
        }
      }

      .react-display-flex-row + .react-display-flex-row {
        border-top: 1px solid $grey-10;
      }
    }

    a {
      color: $base-black;
    }
  }

  .auto-fill-rates {
    padding: $small $normal;
    border-top: 1px solid $grey-400;
    border-bottom: 1px solid $grey-400;

    > .react-display-flex-row {
      gap: $normal;

      svg {
        font-size: $normal;
        color: $yellow;
      }

      > .react-display-flex-column {
        font-weight: bold;
        color: $base-black;
      }

      .btn-link {
        display: inherit;
        padding: 0;
        color: $yellow;
        width: fit-content;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .light-table tr td {
    font-size: 1.2rem;

    input.form-control {
      font-size: 1.2rem;
      padding: $tiny;
      background-color: var(--background-light-color);
      border: 1px solid $grey-300;
    }

    .input-warning {
      width: 2.3rem;
      height: $small;
      gap: 0;

      svg {
        color: var(--primary-color);
      }
    }

    .maturity-date,
    .maturity-days {
      gap: $tiny;

      svg {
        color: var(--primary-color);
      }
    }
  }
}

.quotes-confirmation {
  max-width: 600px;

  .drawer-popover {
    h3 {
      color: $base-black;
      margin-bottom: $normal;
    }

    .light-table {
      margin-bottom: $small;

      tr td {
        .react-display-flex-row {
          gap: $small;
        }
      }
    }

    .confirmation-warning {
      gap: $normal;
      color: $base-black;

      svg {
        color: var(--primary-color);
      }

      > .react-display-flex-column {
        margin: $small 0;
        line-height: $normal;
      }
    }
  }
}

.modal.update-ratesheet-dialog {
  z-index: 9999;
}
