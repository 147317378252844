@import '../../../theme/helpers/guideline';

.single-proposed-tenant {
  &-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $small 0;

    .form-legend {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      margin: $tiny $small;
    }

    .validated-field {
      min-height: $huge;
    }

    > button {
      margin: $small;
      margin-bottom: 0;
    }
  }
}
