@import '../../theme/helpers/palette.scss';

.login-container {
  display: flex;
  height: 100%;
  background-color: $white;

  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: #30333a;
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
  }

  .loader-inner > div {
    background-color: $very-dark-grey;
  }

  .logo {
    margin-bottom: 40px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 40px;
    width: 100%;
    height: 100%;
    background-color: $very-dark-grey;

    p {
      color: $white;
      font-size: 30px;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding-left: 40px;
    color: $very-dark-grey;

    div.announce {
      .arrow {
        margin-left: 0.3em;
        color: black;
      }
      padding: 1em;
      border-radius: 0.5em;
      color: black;
      background-color: $champagne;
      margin-bottom: 2em;
      font-size: 16px;
      color: $yellow;
      a {
        color: black;
      }
      a:hover {
        text-decoration: underline;
      }
    }

    form {
      width: 320px;
      height: 236px;
    }

    .center-link {
      display: flex;
      justify-content: center;
      padding-top: 15px;
      font-size: 14px;
    }

    .forgot-password {
      margin-top: 50px;
    }

    .two-factor-code-form #two-factor-code-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 35px;
    }
  }
}
