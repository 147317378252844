@import '../../theme/helpers/palette.scss';
@import '../../theme/helpers/guideline.scss';

.add-trade {
  .form-section {
    .row {
      margin: 0;
    }

    .panel.panel-filled {
      background-color: transparent;
      margin: 0;
      padding: $small;

      form {
        width: 100%;
        margin: 0;

        .validated-input .form-group {
          margin: 0;

          .datepicker-component.datepicker-bg {
            input:focus {
              border-color: transparent;
            }
          }

          .help-block {
            margin-top: 0;
            margin-bottom: $small;
          }
        }
      }
    }
  }

  form.form-switcher {
    background-color: $very-light-grey;
    padding: $normal;

    .form-group,
    .Select-control {
      background-color: $white;
      border-radius: $border-radius;
    }

    .Select.is-focused.is-open > .Select-control {
      background: transparent;
    }

    .Select-control .Select-multi-value-wrapper .Select-value {
      background: transparent;

      .Select-value-label {
        background: transparent;
      }
    }
  }

  .empty-form {
    padding-top: $huge;

    img {
      width: $large;
    }

    span {
      font-size: 1.8rem;
    }
  }

  label {
    color: var(--dark-white-background-contrast-color);
  }
}
