@import '../../../theme/helpers/all.scss';

.blob {
  background-color: $yellow;
  border-radius: 50%;
  box-shadow: 0 0 0 0 $yellow;
  margin: 10px;
  transform: scale(1);
  animation: pulse-blob 2s infinite;
}

.blob-sm {
  height: $tiny;
  width: $tiny;
}

.blob-md {
  height: $small;
  width: $small;
}

.blob-lg {
  height: $normal;
  width: $normal;
}

@keyframes pulse-blob {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $yellow-opacity-7;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px $opaque-orange;
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 $opaque-orange;
  }
}
