@import '../../../../../../theme/helpers/palette';
@import '../../../../../../theme/helpers/guideline';

.trade-info {
  background-color: $grey-300;
  color: $mid-grey;

  > div {
    padding: $small;
    border-top: 1px solid $light-silver;

    &:last-child {
      border-bottom: 1px solid $light-silver;
    }
  }

  .flex-column {
    > p {
      margin: 0;
    }

    > span {
      font-size: 1.6rem;
      color: $grey-dark;
      margin-bottom: $tiny;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    padding: 0;
  }
}
