@import '../../../theme/helpers/guideline';
@import '../../../theme/helpers/palette';

.dropdown-container {
  position: relative;

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $tiny;
    background-color: transparent;
    border: 1px solid var(--primary-text-color);
    color: var(--primary-text-color);
    border-radius: 50%;
    width: $normal;
    min-height: $normal;
    font-size: 1rem;

    &:hover {
      border-color: var(--primary-color);
      color: var(--primary-color);
    }

    &:focus {
      outline: none;
    }
  }

  .dropdown-menu {
    left: auto;
    right: 0;

    li button {
      padding: 7px 13px;
      width: 100%;
      text-align: start;
      color: var(--primary-contrast-color);
      border: none;
      background-color: transparent;

      &:hover,
      &:focus {
        background-color: $grey-20;
        outline: 0;
      }
    }
  }
}
