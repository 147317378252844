@import '../../theme/helpers/palette.scss';
@import '../../theme/helpers/guideline.scss';

.chart-to-download {
  position: fixed;
  left: -100rem;
}

.chart-download {
  color: $base-dark;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-top: 5px;

  .pieLabel > div,
  .flot-tick-label.tickLabel {
    color: $base-dark !important;
  }
}

.download-chart-button {
  button {
    width: 13rem;
    float: right;
  }

  .chart-to-download {
    position: absolute;
  }
}
