@import '../../../theme/helpers/all';

.need-to-confirm {
  .message {
    span {
      display: block;
      font-size: 15px;
      padding: 20px 0px;

      &:first-child {
        font-size: 24px;
      }
    }
  }
}
