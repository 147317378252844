@import '../../../theme/helpers/all';

.skeleton-chart {
  background-color: $dark-grey;
  padding: $small;

  > * {
    margin: $small 0;
    @include includeLoadingSkeleton($dark: true, $size: 'lg');
  }
}
