$currentDirectory: "./";

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_regular_macroman/Roboto-Regular-webfont.eot");
  src: url($currentDirectory + "roboto_regular_macroman/Roboto-Regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_regular_macroman/Roboto-Regular-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_regular_macroman/Roboto-Regular-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_regular_macroman/Roboto-Regular-webfont.svg#RobotoRegular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_italic_macroman/Roboto-Italic-webfont.eot");
  src: url($currentDirectory + "roboto_italic_macroman/Roboto-Italic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_italic_macroman/Roboto-Italic-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_italic_macroman/Roboto-Italic-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_italic_macroman/Roboto-Italic-webfont.svg#RobotoItalic")
      format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_bold_macroman/Roboto-Bold-webfont.eot");
  src: url($currentDirectory + "roboto_bold_macroman/Roboto-Bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_bold_macroman/Roboto-Bold-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_bold_macroman/Roboto-Bold-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_bold_macroman/Roboto-Bold-webfont.svg#RobotoBold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_bolditalic_macroman/Roboto-BoldItalic-webfont.eot");
  src: url($currentDirectory + "roboto_bolditalic_macroman/Roboto-BoldItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_bolditalic_macroman/Roboto-BoldItalic-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_bolditalic_macroman/Roboto-BoldItalic-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_bolditalic_macroman/Roboto-BoldItalic-webfont.svg#RobotoBoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_thin_macroman/Roboto-Thin-webfont.eot");
  src: url($currentDirectory + "roboto_thin_macroman/Roboto-Thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_thin_macroman/Roboto-Thin-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_thin_macroman/Roboto-Thin-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_thin_macroman/Roboto-Thin-webfont.svg#RobotoThin")
      format("svg");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_thinitalic_macroman/Roboto-ThinItalic-webfont.eot");
  src: url($currentDirectory + "roboto_thinitalic_macroman/Roboto-ThinItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_thinitalic_macroman/Roboto-ThinItalic-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_thinitalic_macroman/Roboto-ThinItalic-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_thinitalic_macroman/Roboto-ThinItalic-webfont.svg#RobotoThinItalic")
      format("svg");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_light_macroman/Roboto-Light-webfont.eot");
  src: url($currentDirectory + "roboto_light_macroman/Roboto-Light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_light_macroman/Roboto-Light-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_light_macroman/Roboto-Light-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_light_macroman/Roboto-Light-webfont.svg#RobotoLight")
      format("svg");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_lightitalic_macroman/Roboto-LightItalic-webfont.eot");
  src: url($currentDirectory + "roboto_lightitalic_macroman/Roboto-LightItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_lightitalic_macroman/Roboto-LightItalic-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_lightitalic_macroman/Roboto-LightItalic-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_lightitalic_macroman/Roboto-LightItalic-webfont.svg#RobotoLightItalic")
      format("svg");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_medium_macroman/Roboto-Medium-webfont.eot");
  src: url($currentDirectory + "roboto_medium_macroman/Roboto-Medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_medium_macroman/Roboto-Medium-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_medium_macroman/Roboto-Medium-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_medium_macroman/Roboto-Medium-webfont.svg#RobotoMedium")
      format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url($currentDirectory + "roboto_mediumitalic_macroman/Roboto-MediumItalic-webfont.eot");
  src: url($currentDirectory + "roboto_mediumitalic_macroman/Roboto-MediumItalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "roboto_mediumitalic_macroman/Roboto-MediumItalic-webfont.woff")
      format("woff"),
    url($currentDirectory + "roboto_mediumitalic_macroman/Roboto-MediumItalic-webfont.ttf")
      format("truetype"),
    url($currentDirectory + "roboto_mediumitalic_macroman/Roboto-MediumItalic-webfont.svg#RobotoMediumItalic")
      format("svg");
  font-weight: 300;
  font-style: italic;
}
