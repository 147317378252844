@import '../../theme/helpers/guideline';

.home {
  display: flex;
  flex-direction: column;

  &.main-loader {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }

  .action-buttons {
    display: flex;
    align-self: flex-end;
    margin-right: $common-margin;
    margin-top: 15px;

    button {
      margin-left: 1em;
    }

    .loader {
      margin: 8.5px 0;
    }
  }

  .navbar {
    border-radius: 0px;
  }

  .navbar-default {
    border-width: 0 0 1px 0;
  }

  > div {
    & > .row {
      padding: 20px $common-padding 0px $common-padding;
    }
  }
}

.home-container {
  .actions {
    padding: $tiny $small;
  }
}
