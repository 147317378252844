@import '../../theme/helpers/all';

$highlight-padding-summary: 20px;

.enter-quotes {
  padding: 0 $common-padding;

  .secondary-panel {
    justify-content: space-between;
  }

  .panel .panel-heading div.row {
    height: auto;
  }

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;

    > div {
      display: flex;
      flex: 1;

      @media (max-width: 1380px) {
        justify-content: space-between;
      }

      .summary-highlight {
        padding: 10px $highlight-padding-summary 15px $highlight-padding-summary;
      }

      .summary-settlement-date {
        display: flex;
        align-items: center;

        svg {
          margin-left: $small;
          font-size: 2rem;
          fill: $yellow;
        }

        &.highlight {
          background-color: $mid-grey;
        }
      }
    }
  }

  span.kyc-ok {
    font-size: x-small;
    background-color: $success;
    color: white;
    border-radius: 8px;
    padding: 0 6px;
    font-weight: bolder;
    margin-left: 6px;
  }

  .enter-quotes-loader {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
  }

  .panel-collapsible.panel {
    &.empty-panel {
      .panel-heading {
        text-align: center;
      }
    }

    .panel-heading {
      padding: 0;
    }

    .panel-heading .highlight-panel {
      font-size: 16px;
    }

    .panel-heading .secondary-panel {
      font-size: 14px;
    }

    .panel-heading {
      border-bottom: none;
    }

    &.with-border {
      $dealt-radius: 3px;
      border-left: $dealt-radius solid;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      &.blue {
        border-left-color: $curious-blue;
      }

      &.orange {
        border-left-color: $yellow;
      }

      &.green {
        border-left-color: $success;
      }

      &.red {
        border-left-color: $error;
      }

      &.white {
        border-left-color: $white;
      }
    }
  }

  .rfq-list {
    margin: -10px;

    .panel-body {
      padding-top: $small;

      .auto-fill {
        gap: $small;

        i {
          color: var(--primary-color);
          cursor: pointer;
        }

        .react-toggle {
          margin-left: $small;
        }
      }
    }

    .footer-row {
      margin-right: 16px;

      .value-highlight {
        margin-top: 5px;
      }
    }

    .btn-solid-primary {
      margin-left: $small;
      width: 100px;
    }
  }
}
