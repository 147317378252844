@import '../../../theme/helpers/all';

.outgoing-quotes-dialog {
  .modal-dialog {
    .modal-header {
      padding: 1em;
    }

    .modal-content {
      min-width: 50em;
    }

    .modal-body {
      padding: 1em;
      overflow: auto;

      span {
        white-space: pre-wrap;
      }
    }

    .modal-body::-webkit-scrollbar {
      width: 1em;
      height: 1em;
    }
    .modal-body::-webkit-scrollbar-thumb {
      height: 0.5em;
      border: 4px solid transparent;
      background-clip: padding-box;
      -webkit-border-radius: 0.5em;
      border-radius: 0.5em;
      background-color: $light-grey;
    }
    .modal-body::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }
    .modal-body::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    .modal-footer {
      padding: 1em;

      .btn {
        min-width: 100px;
      }
    }
  }

  .center-labels {
    text-align: center;
  }
}
