.pie-chart {
  position: relative;

  .legend {
    position: absolute;
  }
}

.pieLabel > div {
  color: white !important;
  font-size: 140% !important;
}
