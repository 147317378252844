@import '../../theme/helpers/all';

$container-size: 400px;
$field-height: 85px;

.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: $huge;

  .form-container {
    margin-top: $normal;
    padding: $normal;
    border-radius: $tiny;
    background-color: $very-dark-grey;
    width: $container-size;

    h3 {
      font-weight: 600;
      text-align: center;
    }

    h4 {
      color: $grey;
      padding-bottom: $small;
    }

    .validated-field {
      padding: 0;
      min-height: $field-height;

      .error-message {
        padding-top: $hair;
      }
    }

    .actions-container {
      display: flex;
      justify-content: center;
      padding-top: $tiny;

      button {
        flex-basis: 50%;
      }
    }
  }

  > button {
    border: 0;
    padding: $small;
    color: $grey;
    background: transparent;

    &:hover {
      color: $yellow;
    }
  }
}
