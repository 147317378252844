@import '../../theme/helpers/all';

$border-radius-box: 10px;

.tenors-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  .tenor {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    border: 4px solid var(--background-dark-color);
    border-radius: $border-radius-box;
    position: relative;
    color: var(--secondary-text-color);
    font-size: 1.2em;
    border-width: 1px;
    height: 9rem;
    padding: 1.6rem;

    &.disabled {
      justify-content: space-between;
    }

    .icon {
      position: absolute;
      font-size: 1.2rem;
      top: $tiny;
      left: $tiny;
      cursor: pointer;
    }

    input {
      border: hidden;
      border-bottom: 1px solid transparent;
      background-color: transparent;
      padding: 0;
      text-align: right;
      pointer-events: initial;
      height: 100%;
      width: 100%;
    }

    input:hover,
    textarea:focus,
    input:focus {
      outline: none;
      border-color: var(--secondary-text-color);
      background-color: transparent;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    @include transition(all 0.4s);

    .percentage {
      display: flex;
      align-items: center;
      font-size: 2.2rem;
      font-weight: bold;
      color: var(--secondary-text-color);
      padding-top: 5px;
      width: 100%;
      justify-content: flex-end;
      @include transition(color 0.4s);
    }

    &.active {
      background-color: var(--background-light-color);
      border-color: var(--primary-color);
      color: var(--background-light-contrast-color);

      .percentage {
        color: var(--background-light-contrast-color);
      }
    }

    &.declined {
      > * {
        color: $grey-70;
      }
    }

    &:hover {
      background-color: var(--background-light-color);
      color: var(--background-light-contrast-color);

      .percentage {
        color: var(--background-light-contrast-color);
      }
    }

    &.winner {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: var(--primary-contrast-color);
      font-weight: bold;

      .percentage {
        color: var(--primary-contrast-color);
      }
    }

    &.is-dirty {
      border: 1px solid var(--primary-color);
    }

    &.disabled {
      pointer-events: none;

      .empty-percentage {
        font-size: 4rem;
      }

      *:not(.disable-icon) {
        pointer-events: none;
        opacity: 0.4;
      }

      .disable-icon {
        pointer-events: initial;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &.read-only {
    pointer-events: none;

    .tenor:not(.winner) {
      background-color: transparent;
      color: $light-grey;

      .percentage {
        color: $grey-70;
      }
    }
  }

  &.editable .tenor,
  &.read-only .tenor {
    cursor: default;

    .empty-percentage {
      font-size: inherit;
      padding-bottom: $tiny;
    }
  }

  .drawer & {
    .tenor {
      border-width: 1px;
      min-height: 7rem;
      padding: 1.6rem;
      font-size: 1.4rem;
      text-transform: none;

      .percentage {
        font-size: 2rem;
        font-weight: bold;
      }

      &.is-dirty & :not(.read-only) {
        border-color: $mid-grey;
      }

      &.disabled * {
        opacity: 1;
      }
    }
  }
}

@media (max-width: $small-desktop-width) {
  .tenors-grid {
    grid-gap: 1.3rem;

    .tenor {
      font-size: 1.25rem;

      .percentage {
        font-size: 3rem;
      }
    }
  }
}

@media (max-width: $tiny-desktop-width) {
  .tenors-grid {
    min-height: 55vh;
    grid-gap: 1.25rem;

    .tenor {
      font-size: 1rem;

      .percentage {
        font-size: 2.5rem;
      }
    }
  }
}
