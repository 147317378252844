@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';

.datepicker-range {
  min-height: 86px;

  .datepicker-from {
    margin-right: $small;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &-error {
    padding-top: $tiny;
    margin-bottom: 0;
    color: $error;
  }
}
