@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables';

$select-text-color: $light-grey;
$select-input-placeholder: $light-grey;
$select-clear-color: $light-grey;
$select-arrow-color: $light-grey;
$select-input-border-color: $mid-grey;
$select-input-bg: $mid-grey;
$select-option-color: $light-grey;
$select-input-border-focus: $light-grey;
$select-input-border-color: transparent;
$select-input-bg-disabled: $mid-grey;
$select-input-height: $input-height-small;

.Select {
  &.is-disabled {
    opacity: 0.3;
  }
  &.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
  }
}

.form-group.has-error {
  border-color: $error;

  .Select,
  .Select:not(.is-focused) {
    .Select-control {
      @include transition(border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s);
    }
  }
}

.has-error {
  .form-control:focus,
  .input-group-addon,
  .Select:not(.is-open) > .Select-control {
    border-color: $error;
  }
}
