html,body,.container
{
    height:100%;
}

.container
{
    display:table;
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px;
}

.row-login
{
    height: 100%;
    display: table-row;
}

.row .no-float {
    display: table-cell;
    float: none;
  }

.login-text {
  padding-right: 30px;
}

.login-text p {
  margin: 0;
}

.left-container {
  background-color: #3a3c44;
}

.right-container {
  padding-left: 30px;
  background-color: white;
}

.vertical-center {
    display: inline-block;
    vertical-align: middle;
}

.login-logo {
  margin-bottom: 40px;
}

.login-forgot-password {
  padding-top: 10px;
  display: table;
  margin: 0 auto;
  color: #3a3c44;
  font-size: 12px;
  font-weight: lighter;
}
