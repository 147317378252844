@import '../../theme/helpers/all';

$desktop-width: 1400px;

.holding-results-filter {
  display: flex;

  .dropdown-filters-container {
    > div:first-of-type .my-react-select__menu-list {
      max-height: initial;
    }

    display: flex;

    .input-search {
      width: 150px;
      margin-right: 15px;
    }
  }

  .filter-value-row {
    display: flex;
    align-items: center;
    padding: $small 0 0 $small;
  }

  .select-filter-container {
    border: 0;

    .select-filter {
      width: 14rem;
      max-width: 14rem;
      margin-right: 15px;

      p.grey-color.text-ellipsis {
        margin-bottom: 5px;
      }
    }

    @media (max-width: $desktop-width) {
      .select-filter {
        min-width: 90px;
      }
    }
  }

  .monthly-reports {
    display: flex;
    margin-left: auto;
  }

  .filter-value-row {
    .form-group,
    .reset-filter-button {
      margin-top: 25px;
      min-height: $input-height;
    }

    .investments-count {
      margin-left: 0.5em;
    }

    .reset-filter-button {
      background-color: $dark-grey;
      border: transparent;
      height: $input-height;
    }
  }

  .reset-filter-button {
    margin-top: 30px;
    height: $input-height;
  }
}
