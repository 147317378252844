@import '@tame-your-theme/scss';

@import '../../theme/helpers/all';

$notes-max-width: 90px;

.holding-list {
  .table-responsive {
    overflow: visible;
  }

  .trade-tooltip {
    text-align: left;
  }
  .results {
    border-radius: $border-radius;
  }

  .download-trades {
    width: 180px;
    margin-top: 30px;
  }

  .filter-row {
    display: flex;
    justify-content: space-between;

    .monthly-report-selector {
      margin-left: auto;
      display: flex;

      .select-filter {
        min-width: 160px;
        margin-right: 15px;

        p.grey-color.text-ellipsis {
          margin-bottom: 5px;
        }
      }

      .loader {
        width: 90px;
        align-self: center;
      }

      button {
        width: 90px;
        margin-top: 24px;
        height: $input-group-height;
      }
    }
  }

  .results-presenter {
    .results {
      .allocation-code-column {
        text-transform: capitalize;
      }
    }

    .panel.panel-filled {
      padding: 0;
    }

    .blob {
      cursor: pointer;
    }

    td.action-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0;
      margin-left: 0;
      margin-right: $small;
      position: relative;

      > svg {
        font-size: 14px;
        margin-bottom: 2px;
        cursor: pointer;

        &.is-downloading {
          @include includeLoadingSkeleton($rounded: true, $dark: true);
          cursor: progress;
        }
      }

      .dropdown-menu .blob {
        position: absolute;
        bottom: 2px;
        left: 0;
      }

      button.action-menu {
        color: $light-grey;
        padding-right: 0;

        &:hover {
          color: white;
        }
      }
    }

    td.pulse-blob-column {
      padding-right: 0;

      .blob {
        margin: 0;
        float: right;
      }
    }

    .text-ellipsis {
      display: block;
      max-width: $notes-max-width;
    }
  }
}

.update-trade-dialog {
  &.is-loading {
    .form-group {
      input,
      .select-control,
      textarea {
        @include includeLoadingSkeleton($include-content: true, $dark: true);
      }

      .select-control::before {
        content: none;
      }
    }

    .summary-highlight > span {
      display: block;
      @include includeLoadingSkeleton($include-content: true, $dark: true);
    }
  }
}

.dialog-header {
  &:last-child {
    margin: $small 0;
  }

  .summary-highlight {
    min-width: 150px;
  }
}
