@import '../../theme/helpers/all';
@import '../../theme/helpers/mixins.scss';

.header {
  padding: 0 20px 0 5px;
  .navbar-nav > li:last-child > div.logo {
    margin: 10px 25px 10px 15px;
  }
  i.glyphicon.glyphicon-menu-hamburger {
    font-size: 20px;
  }
  .logo {
    @include image-2x('imperium-logo', 287px, 49px);
  }
  .header-dropdown.open {
    > a {
      background-color: transparent;
    }
  }
  .header-dropdown {
    > ul {
      margin-right: 25px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    .header-dropdown-name {
      text-transform: none;
      &.padding-right-5 {
        padding-right: 5px;
      }
    }
    .header-dropdown-name,
    .header-dropdown-caret {
      padding-top: 10px;
    }
    .header-dropdown-image {
      background-color: $dark-orange;
      color: $white;
      font-size: 135%;
      height: 32px;
      margin: 4px 2px 0 5px;
      padding-top: 5px;
      width: 32px;
      text-align: center;
    }
  }
}
