.maturing-today-summary-item {
  cursor: pointer;
}

.maturing-today-summary-item-drawer {
  .results-presenter {
    .actions {
      button {
        flex: 1;
      }
    }
  }
}
