@import '../../theme/helpers/all';

.confirm-dialog {
  .modal-dialog {
    .modal-body {
      > span {
        white-space: pre-wrap;
      }
    }

    .modal-footer {
      .btn {
        min-width: 100px;

        & + .btn {
          margin-left: $small;
        }
      }
    }

    .form-label {
      color: $white;
    }
  }

  .center-labels {
    text-align: center;
  }

  &.is-confirming {
    pointer-events: none;

    .modal-footer {
      @include includeLoadingSkeleton($include-content: false, $dark: true);

      * {
        visibility: hidden;
      }

      button {
        border: transparent;
      }
    }
  }
}
