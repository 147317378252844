@import '../../theme/helpers/all';

.show-message {
  background-color: $very-dark-grey;
  padding: $normal;

  .logo {
    width: 50px;
    margin: $small;
  }
}
