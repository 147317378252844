@import '../../theme/helpers/palette.scss';
@import '../../theme/helpers/guideline.scss';

$font-size: 1.8rem;
$item-height: 48px;

.header-filters {
  height: 100%;
  padding: $tiny;

  .currency-filter {
    height: $item-height;
    flex: 1;
    padding: 0;
  }

  .tenant-filter {
    height: $item-height;
    flex: 1;
    flex: 1 1 9rem;
    padding: 0;
  }

  .as-at-filter {
    height: $item-height;
    flex: 1;
    padding: 0;
  }

  .item,
  .imperium-datepicker,
  .item-empty {
    height: $item-height;
    padding-top: 3px;
    background-color: $white;
    margin-right: $tiny;
    border-radius: $border-radius;

    input,
    .input-group-addon {
      border-bottom: none;
    }

    input {
      font-size: $font-size;
      color: $dark-grey-color;
      font-weight: bold;

      &::placeholder {
        color: $dark-grey-color;
        font-weight: normal;
      }
    }

    .select-control.is-open .Select-menu-outer {
      background-color: $white;
      border-radius: $border-radius;
      border: none;

      .Select-option {
        background-color: $white;
      }

      .Select-option:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      .Select-option.is-selected {
        background-color: $light-silver;
        font-weight: bold;
      }

      .Select-option.is-focused {
        background-color: $light-silver;
      }
    }

    .Select {
      .Select-control {
        background-color: transparent;
        color: $dark-grey-color;
        border: none;

        .Select-placeholder {
          font-size: $font-size;
          font-weight: bold;
          line-height: inherit;
          color: $grey-60;
          padding-left: 0;
        }

        .Select-input {
          padding: 0;

          input {
            margin-left: 0;
            padding: 0;
          }
        }

        .Select-value {
          padding-left: 0;
          line-height: inherit;
        }

        .Select-value-label {
          font-size: $font-size;
          font-weight: bold;
        }

        .Select-arrow-zone {
          top: 0;
          vertical-align: top;
        }

        .arrow-icon {
          width: $small;
          height: $tiny;
          vertical-align: top;
        }
      }

      &.has-value > .Select-control .Select-value .Select-value-label {
        color: $dark-grey-color;
      }
    }
  }

  .item-empty {
    padding: $tiny $small;
    color: $dark-grey-color;
    font-size: 1rem;

    .selected-label {
      font-size: $font-size;
      font-weight: bold;
    }
  }

  .imperium-datepicker {
    padding: $tiny $small 0;

    input {
      padding: 0;
    }

    p {
      font-size: 1rem;
      color: $dark-grey-color;
      margin: 0;
    }
  }

  .select-filter {
    padding: $tiny $small 0;

    .input-group-addon {
      padding: 0;

      i {
        font-size: 2rem;
      }
    }

    input {
      height: $normal;
      margin-left: 0;
    }

    p {
      font-size: 1rem;
      color: $dark-grey-color;
      margin: 0;
    }
  }
}
