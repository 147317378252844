@import '../../theme/helpers/all';

.charts-presenter {
  display: flex;

  .panel.panel-filled {
    background-color: transparent;
    flex: 1;

    .title-container {
      margin-bottom: $small;
    }

    .chart {
      background-color: $very-dark-grey;
      padding: $small;
    }

    .no-content {
      margin-top: $normal;
    }

    &:first-child {
      margin-right: $small;
    }

    &:last-child {
      margin-left: $small;
    }

    &:only-of-type {
      width: 100%;
      margin: 0 0 $normal;
    }
  }

  h4 {
    color: $white;

    &.chart-title {
      display: inline-block;
      font-size: 1.8rem;
    }
  }
}
