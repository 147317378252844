@import '../../../theme/helpers/palette.scss';

.form {
  margin-top: 20px;

  form {
    width: 50%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .Select-option {
    background-color: $mid-grey;
  }

  .btn-save {
    width: 120px;
    font-weight: bold;
  }

  .btn-save:only-of-type {
    width: 100%;
  }

  textarea {
    margin-bottom: 10px;
  }

  input {
    border: solid 1px transparent;
  }

  input[type='text'],
  input[type='number'],
  input[type='tel'],
  textarea,
  .select-control.is-open > .Select-control {
    &:active,
    &:focus {
      border-color: $light-orange;
    }
  }

  .is-open .Select-control {
    border: 1px solid $light-orange;
    border-color: $light-orange;
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
  }

  .select-control.is-open .Select-menu-outer {
    border: 1px solid $light-orange;
    border-color: $light-orange;
    border-top: none;
    box-shadow: -2px 1px 0px rgba(0, 0, 0, 0.5);
    width: calc(100% - 1px);
  }
}

.form-section {
  & > .row,
  .header-component > .row {
    margin-left: 30px;
    margin-right: 30px;

    &:last-child {
      & > div {
        padding: 0;
      }
    }
  }

  .panel {
    padding: 20px;
  }
}
