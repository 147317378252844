@import '../../theme/helpers/all';

.allocation-list {
  .panel {
    min-height: 250px;
  }

  .results-presenter {
    .loader {
      padding-top: 80px;
    }
  }
}
