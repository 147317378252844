@import '../../../theme/helpers/palette.scss';
@import '../../../theme/helpers/guideline.scss';

.presenter-options {
  padding-top: $small;

  .refresh-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $tiny $small;

    img {
      width: 20px;
    }
  }

  .updated-message {
    margin-left: $small;
    font-size: 1rem;
  }
}
