@import '../../theme/helpers/all';

.maturity-list {
  .panel {
    min-height: 200px;

    .charts-presenter {
      .loader {
        padding-top: 20px;
      }

      .no-records-message {
        padding-top: 45px;
      }
    }
  }
}
