@import 'mixins';
@import 'guideline';
@import '../helpers/palette.scss';

:root {
  --loading-first-color: var(--grey-50);
  --loading-second-color: var(--grey-500);

  --loading-black-first-color: #{$base-black};
  --loading-black-second-color: #{$tundora};
}

.is-loading-skeleton {
  background: linear-gradient(
    -90deg,
    var(--loading-first-color) 0%,
    var(--loading-second-color) 50%,
    var(--loading-first-color) 100%
  );
  pointer-events: none;
  color: transparent;
  animation: pulse 1.2s ease-in-out infinite;
  background-size: 400% 400%;

  * {
    visibility: hidden;
  }
}

@mixin tabs($num-of-tabs, $tab-width) {
  @for $i from 1 through $num-of-tabs {
    &:nth-of-type(#{$i}) {
      &.active {
        ~ .slide {
          left: calc((#{$tab-width}) * #{$i - 1});
        }
      }
    }
  }
}

@mixin includeLoadingSkeleton($include-content: true, $dark: false, $rounded: false, $size: 'lg') {
  @extend .is-loading-skeleton;

  @if $size == 'sm' {
    width: 30%;
  }

  @if $size == 'md' {
    width: 70%;
  }

  @if $dark {
    background: linear-gradient(
      -90deg,
      var(--loading-black-first-color) 0%,
      var(--loading-black-second-color) 50%,
      var(--loading-black-first-color) 100%
    );
    background-size: 400% 400%;
  }

  @if $rounded {
    border-radius: 1.5rem;
  }

  @if $include-content {
    &::before {
      content: '\00a0';
    }
  }
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}

%rounded-button {
  $button-size: 30px;
  width: $button-size;
  height: $button-size;
  border-radius: 50%;
  background-color: var(--background-dark-color);
  color: var(--background-dark-contrast-color);
  text-shadow: none;
  font-size: 3rem;
  border: 0;

  &:focus {
    outline: none;
  }
}

%primary-button {
  border-color: $yellow;
  > span {
    color: $yellow;
  }

  &:hover {
    background-color: $light-orange;

    > span {
      color: $grey-900;
    }
  }
}
