@import '../../theme/helpers/all';

.auto-quote-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12em;
  height: 2em;

  .react-toggle {
    &:not(.react-toggle--checked) {
      .react-toggle-track {
        background-color: var(--background-main-contrast-color);
      }
    }
  }

  &.is-loading {
    .react-toggle .react-toggle-track {
      @include includeLoadingSkeleton($dark: true, $rounded: true);
    }
  }
}
