@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';

$circle-size: 20;
$inner-circle-size: $circle-size / 2;
$inner-circle-position: $inner-circle-size / 2;
$box-padding: $small;

.radio-group {
  .radio-item {
    margin: 0;
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    font-weight: bold;

    input {
      opacity: 0;

      &:checked ~ .circle {
        background-color: $silver;
      }
      ~ .circle:after {
        display: block;
      }
    }

    .circle {
      position: absolute;
      right: $box-padding;
      height: $circle-size + px;
      width: $circle-size + px;
      background-color: $grey;
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        display: none;
        top: $inner-circle-position + px;
        left: $inner-circle-position + px;
        width: $inner-circle-size + px;
        height: $inner-circle-size + px;
        border-radius: 50%;
        background: $mid-grey;
      }
    }

    &-box {
      background-color: var(--background-dark-color);
      color: var(--background-dark-contrast-color);
      padding: $box-padding;
      border: 1px solid transparent;
      border-radius: $border-radius;

      &:not(:last-of-type) {
        margin-bottom: $small;
      }
    }

    border-color: 1px solid transparent;

    &.active {
      background-color: white;
      color: $very-dark-grey;
      border-color: var(--primary-color);

      .circle:after {
        background: $toggle-green;
      }
    }

    &:hover {
      background-color: white;
      color: $very-dark-grey;

      input ~ .circle {
        background-color: $silver;
      }
    }
  }

  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
