@import '../../../theme/helpers/all.scss';

.summary-item {
  flex-basis: 25%;
  margin: 0 $small;

  .action-button {
    flex: 1;
    display: contents;
    background-color: transparent;
    text-align: initial;
    border: none;
    padding: 0;
  }

  .summary-content {
    flex: 1;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.summary-content {
  padding: $small 0 $small $common-padding;
  border-radius: 10px;
  font-size: 1.2rem;

  &.is-loading h4 {
    @include includeLoadingSkeleton($dark: true, $rounded: true, $size: 'md');
  }

  &.is-loading div {
    @include includeLoadingSkeleton($dark: true, $rounded: true, $size: 'sm');
  }

  h4 {
    font-size: 3rem;
    margin: $tiny 0;
  }

  p {
    margin-bottom: $tiny;
    color: $grey-dark;
  }

  .bottom-content {
    display: flex;
    align-items: center;
    height: $normal;

    .highlight-icon {
      $icon-height: auto;
      $icon-width: 16px;

      padding-right: $normal;

      @include image-2x('arrow-right-icon', $icon-width, $icon-height, '../../../assets/icons/portfolio/');

      width: auto;
      background-position-x: right;
      background-position-y: center;
    }

    button.highlight-icon:focus {
      outline: 0;
    }

    .highlight {
      display: inline-flex;
      align-items: center;
      color: $yellow;
    }
  }

  .light & {
    @include transition(background-color 0.4s);

    color: var(--dark-white-background-contrast-color);
    background-color: var(--dark-white-background-color);

    &:hover {
      background-color: var(--primary-color);
    }

    p,
    span {
      color: var(--dark-white-background-contrast-color);

      &.highlight {
        font-weight: bold;
      }
    }

    &.highlight {
      background-color: lighten-theme-color(--primary-color, 10%);
    }

    &.is-loading div {
      @include includeLoadingSkeleton($dark: false, $rounded: true, $size: 'sm');
    }

    &.is-loading h4 {
      @include includeLoadingSkeleton($dark: false, $rounded: true, $size: 'md');
    }
  }

  .popover.light & {
    border: 1px solid lighten-theme-color(--dark-white-background-contrast-color, 70%);
  }
}

.summary-item:first-child {
  padding-left: 0;
}

.summary-content.highlight {
  background-color: $dark-grey;
  padding-left: $common-padding;
}

.summary-content.highlight:hover {
  background-color: $mid-grey;
}
