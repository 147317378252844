@import '../../../../../theme/helpers/all';

$table-size: 100% !important;
$header-left-width: 0 !important;
$cell-height: 40px !important;

.update-ratesheet-drawer {
  margin: $normal;
  color: $base-black;

  .ratesheet-header {
    padding: 0 $tiny;
    margin-bottom: $normal;

    > .react-display-flex-column {
      > dd {
        font-weight: bold;
      }

      > dt {
        color: $dark-orange;
        font-weight: normal;
        opacity: 0.6;
      }
    }
  }

  .ratesheet-alert {
    gap: $small;
    padding: 0 $tiny;
    margin-bottom: $normal;

    svg {
      color: var(--primary-color);
    }

    p {
      width: 90%;
      font-size: 1.2rem;
      margin: 0;
    }
  }

  .ratesheet-note {
    padding: 0 $tiny;
    margin-bottom: $normal;
    font-size: 1.1rem;
    opacity: 0.5;
  }

  .ratesheet-table.handsontable {
    .ht_clone_top .wtHider,
    .ht_clone_top .wtSpreader,
    .ht_clone_top .htCore,
    .ht_master .wtHolder,
    .ht_master .wtHider,
    .ht_master .wtSpreader,
    .ht_master .htCore {
      width: $table-size;
    }

    table.htCore {
      thead tr th {
        height: $large;
        background-color: $grey-50;
        border-width: 0;
        border-right: 1px solid $grey-10;

        &:last-child {
          border-right-width: 0;
        }
      }

      tbody tr {
        td,
        th {
          border-color: $grey-10;
        }

        th {
          background-color: $grey-50;
        }
      }
    }

    .ht_clone_top table.htCore thead tr th > div {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: $small;
      font-weight: bold;
    }

    .ht_master .wtHolder,
    .ht_master .wtHolder .wtHider {
      height: $table-size;
    }

    .ht_clone_left {
      width: $header-left-width;
    }

    .ht_master table tbody tr {
      th > div {
        padding: $tiny $small;
        text-align: initial;
      }

      td {
        padding: $tiny $small;
      }
    }

    .handsontableInputHolder textarea {
      padding: $tiny $small;
      max-height: 30px;
    }
  }

  > button {
    width: 100%;
    margin-top: $normal;

    &.btn-link {
      padding: 0;
    }
  }
}
