@import '../../theme/helpers/palette.scss';

.checkbox {
  input[type='checkbox'] {
    display: none;
  }

  .inner-text {
    margin-left: 5px;
  }

  label {
    position: relative;

    // distance between fake checkbox and label
    // checkbox width + borders + # space
    padding-left: 28px;
  }

  label::before,
  label::after {
    position: absolute;
    content: '';
    display: inline-block;
    margin-top: 2px;
  }

  // fake checkbox base style (unchecked)
  label::before {
    background: $light-grey;
    border: 2px solid $mid-grey;
    border-radius: 3px;

    width: 18px;
    height: 18px;

    left: 0;
    top: 0;
  }

  // fake checkmark
  label::after {
    color: $white;
    height: 5px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;

    transform: rotate(-45deg);

    left: 4px;
    top: 6px;
  }

  // checkbox style (checked)
  input[type='checkbox']:checked + label::before {
    background: $yellow;
    border-color: $yellow;
  }

  // unchecked - hide fake checkmark
  input[type='checkbox'] + label::after {
    content: none;
  }

  // checked - show fake checkmark
  input[type='checkbox']:checked + label::after {
    content: '';
  }
}
