@import '@tame-your-theme/scss';

@import '../../../theme/helpers/all';

:root {
  @include create-theme-color(--datepicker-primary-color, $yellow);
  @include create-theme-color(--datepicker-primary-contrast-color, $dark-grey-color);
  @include create-theme-color(--datepicker-background-color, $base-dark);
  @include create-theme-color(--datepicker-background-contrast-color, $grey-40);
}

@mixin change-datepicker-colors(
  $background-color-name: --datepicker-background-color,
  $background-contrast-color-name: --datepicker-background-contrast-color,
  $primary-color-name: --datepicker-primary-color,
  $primary-contrast-color-name: --datepicker-primary-contrast-color
) {
  $datepicker-border-color: darken-theme-color(#{$background-contrast-color-name}, 50%);

  &.border-bottom-only {
    border-radius: 0;
    border-color: transparent;
    border-bottom-color: $datepicker-border-color;

    input {
      padding-left: 0;
    }

    i {
      padding-right: 0;
    }
  }

  input {
    width: 100%;
    border: 0;
    height: $input-height - 2;
    border-radius: 0.4rem 0 0 0.4rem;
    background-color: transparent;
    color: var(#{$background-contrast-color-name});
    padding: $tiny $small;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .react-datepicker {
    background-color: var(#{$background-color-name});
    border: 1px solid $datepicker-border-color;
    font-size: 1.4rem;
    padding: 1.6rem;

    &__header {
      background-color: var(#{$background-color-name});
      border: 0;
    }

    &-popper {
      .react-datepicker__triangle {
        &::after {
          border-bottom-color: var(#{$background-color-name});
        }

        &::before {
          border-bottom-color: $datepicker-border-color;
        }
      }
    }

    &__month-select,
    &__year-select {
      font-size: 1.8rem;
      background-color: var(#{$background-color-name});
      color: var(#{$background-contrast-color-name});
      padding: $small;
      padding-top: 0;
      border-color: transparent;
      appearance: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    &__month-select {
      padding-right: 0;
    }

    &__year-select {
      padding-left: 0;
    }

    &-time__header {
      color: var(#{$background-contrast-color-name});
      font-size: 1.8rem;
      margin-bottom: 1rem;
    }

    &__current-month {
      display: none;
    }

    &__year-dropdown-container {
      .react-datepicker__year-dropdown {
        color: var(#{$background-contrast-color-name});
      }

      .react-datepicker__year-read-view--down-arrow {
        border-top-color: var(#{$background-contrast-color-name});
        top: 0.5rem;
      }

      .react-datepicker__year-read-view--selected-year {
        color: var(#{$background-contrast-color-name});
      }

      .react-datepicker__navigation--years {
        height: 2rem;
        border-width: 0.8rem;
      }
      .react-datepicker__navigation--years-upcoming {
        top: -0.6rem;
      }
      .react-datepicker__navigation--years-previous {
        top: 0.6rem;
        margin-bottom: -1rem;
      }
      .react-datepicker__year-option:hover {
        .react-datepicker__navigation--years-upcoming {
          border-bottom-color: var(#{$primary-color-name});
        }
        .react-datepicker__navigation--years-previous {
          border-top-color: var(#{$primary-color-name});
        }
      }
    }

    &__day {
      width: 4rem;
      margin: 0;
      padding: 1rem;
      color: var(#{$background-contrast-color-name});
      display: table-cell;
      border-radius: 0;

      &:not(.react-datepicker__day--outside-month) {
        border: 1px solid $datepicker-border-color;
      }

      &:not([class*='selected']):not(&--disabled):hover {
        color: var(#{$primary-contrast-color-name});
        background-color: lighten-theme-color(#{$primary-color-name}, 20%);
      }

      &--disabled {
        color: var(#{$background-contrast-color-name});
        opacity: 0.2;
      }

      &--weekend {
        color: darken-theme-color(#{$background-contrast-color-name}, 20%);
      }

      &--outside-month {
        color: darken-theme-color(#{$background-contrast-color-name}, 50%);
      }

      &--in-range:not(&--in-selecting-range):not(&--disabled) {
        opacity: 0.2;
      }

      &--outside-month.react-datepicker__day--in-selecting-range {
        background-color: lighten-theme-color(#{$primary-color-name}, 20%);
      }

      &--selected,
      &--in-selecting-range,
      &--in-range {
        color: var(#{$primary-contrast-color-name});
        background-color: var(#{$primary-color-name});
        border: 1px solid $datepicker-border-color;

        &:hover {
          background-color: darken-theme-color(#{$primary-color-name}, 10%);
        }
      }

      &--in-selecting-range:not(&--in-range) {
        background-color: darken-theme-color(#{$primary-color-name}, 20%);
      }

      &:focus-visible {
        outline: transparent;
      }
    }

    &__day-name {
      width: 4rem;
      margin: 0;
      padding: 0 1rem;
      font-size: 1.2rem;
      color: var(#{$background-contrast-color-name});
    }

    &__navigation {
      &--previous {
        top: 2.8rem;
        border-right-color: var(#{$background-contrast-color-name});
      }

      &--next {
        top: 2.8rem;
        border-left-color: var(#{$background-contrast-color-name});
      }
    }

    &__month {
      display: table;
      border-collapse: collapse;
      border-spacing: 0;
    }

    &__week {
      display: table-row;
    }

    &__today {
      &-button {
        margin: 0 auto;
        width: 8rem;
        padding-top: $small;
        border-top: transparent;
        background-color: var(#{$background-color-name});
        color: var(#{$background-contrast-color-name});

        &:hover {
          color: var(#{$primary-color-name});
        }
      }
    }

    &__time-container {
      width: auto;
      margin-left: 1.6rem;
      color: var(#{$background-contrast-color-name});

      .react-datepicker__time-box {
        width: auto;
      }

      ul.react-datepicker__time-list {
        padding: 0 1rem;
        box-sizing: border-box;

        li.react-datepicker__time-list-item--selected {
          &,
          &:hover {
            background-color: var(#{$primary-color-name});
          }
        }
      }
    }
  }

  [class*='selected'] {
    color: var(#{$primary-contrast-color-name});
    background-color: var(#{$primary-color-name});
    border-radius: 0;
  }

  i {
    cursor: pointer;
    font-size: 2rem;
    position: absolute;
    right: 0;
    padding-right: $small;
  }
}
