@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';

.rfqs-history-container {
  .header {
    display: flex;
    justify-content: space-between;
    margin-left: 0;
    padding-left: 0;
    margin-right: 0;
    padding-right: 0;
  }

  .panel.panel-filled {
    margin: 0;
    padding: 0;

    .loader {
      text-align: center;
    }

    > .table-container {
      padding: 0;
    }

    table.table {
      > tbody > tr,
      > thead > tr {
        height: $table-row-height;

        > td,
        > th {
          vertical-align: middle;
          padding: 6px;
        }
      }
    }
  }

  .toolbar-container {
    display: flex;
    margin-bottom: 1em;
    gap: $small;
    align-items: flex-start;

    .imperium-select-container {
      min-width: 10rem;
    }

    .primary-button,
    .btn-default {
      min-width: 10rem;
      margin-top: 10px;
      height: $input-height;

      align-self: center;
    }

    .toolbar-item {
      &.toolbar-button {
        &.filter {
          width: 8em;
        }
      }

      &.push-left {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        padding-right: 0;
      }

      .datepicker-component {
        background-color: #434449;
        border-radius: 5px;

        input,
        span.input-group-addon {
          border-bottom: none;
        }

        input {
          font-size: 1.4rem;
          margin-left: 10px;
        }
      }
    }

    .toolbar-button.btn {
      line-height: 2.2rem;
      .select-filter {
        p.text-ellipsis {
          margin-bottom: 5px;
          color: $white;
        }
      }
    }

    .reset-filter-button {
      align-self: flex-end;
      margin: 0 1em;
    }

    .filter-dropdown {
      margin-left: 1em;
      padding: 0;
    }
  }

  .name-initials {
    text-decoration: underline;
  }
}
