@import '../../../theme/helpers/all';

.confirm-redemption {
  .message {
    span {
      display: block;
      text-align: center;
      font-size: 18px;
      padding: 20px 0px;
      &:first-child {
        font-size: 16px;
      }
    }
  }
  .confirm {
    width: 300px;
    height: 52px;
    margin: 0 auto;
    .toggle-button {
      display: inline-block;
      margin-top: 12px;
      cursor: default;
    }
    .glyphicon {
      display: inline-block;
      width: 60px;
      font-size: 24px;
      text-align: center;
    }
  }
}
