@import '../../../theme/helpers/all';

.rfq-list {
  .panel-body .results-presenter {
    .table {
      i {
        cursor: pointer;
        margin-left: $small;
        color: var(--primary-color);
        font-size: 12px;
      }

      td:nth-of-type(1) {
        width: 150px;
      }

      td:nth-of-type(2) {
        width: 250px;
      }

      td:nth-of-type(3) {
        width: 200px;
      }

      td.in-days-column > span {
        min-width: 60px;
        display: inline-block;
      }

      td.quote-column {
        display: flex;
        flex-direction: row;
        align-items: center;

        > input {
          width: 300px;
        }

        > div {
          flex: 1;
          margin-left: $small;
          font-size: 1.1rem;

          i {
            margin-right: $tiny;
          }
        }

        > .btn {
          width: 100px;
          cursor: default;

          &.accepted,
          &.confirmed {
            border-color: $success;
            background-color: $success;
            color: $white;

            &:hover {
              color: $white;
            }
          }

          &:hover {
            color: inherit;
          }
        }
      }
    }
  }
}
