@import '../../theme/helpers/all';

.toggle-button {
  display: flex;
  align-items: center;
  position: relative;
  height: 24px;
  width: 120px;
  background-color: $mid-grey;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
  }
  span {
    line-height: 24px;
    font-size: 13px;
    padding-left: 28px;
    color: $grey-400;
  }
  .toggle-caret {
    position: absolute;
    height: 18px;
    width: 18px;
    top: 3px;
    left: 4px;
    background-color: $grey-400;
    border-radius: 50%;
    transition: left 0.2s linear;
  }
  &.is-toggled {
    background-color: $light-green;
    span {
      padding-left: 18px;
      color: $white;
    }
    .toggle-caret {
      left: 98px;
      background-color: $white;
    }
  }
}
