@import '../../../theme/helpers/palette.scss';
@import '../../../theme/helpers/guideline.scss';

.input-group-button {
  .input-group-btn {
    &:last-child .btn {
      z-index: 4;
    }

    button {
      height: $input-group-height;
      padding: 0 $small;
      border-color: $grey-border-color;
      background-color: $mid-grey;

      &:hover,
      &.active {
        border-color: var(--primary-color);

        svg {
          color: var(--primary-color);
        }
      }
    }
  }

  &.light button {
    border-color: $grey-300;
    background-color: var(--background-light-color);

    &.active:focus {
      background-color: var(--background-light-color);
    }
  }
}
