.light-table table {
  width: 100%;
  position: relative;

  thead tr th {
    position: relative;
    color: $base-black;
    font-weight: bold;
    line-height: 2.4em;
    border-bottom: 1px solid $grey-10;
    background-color: $grey-50;
    padding: $small;

    &:not(:last-child):not(.rc-table-selection-column):not(.rc-table-row-expand-icon-cell):not([colspan]):before {
      position: absolute;
      top: $small;
      right: 0;
      width: 1px;
      height: 2em;
      background-color: $grey-200;
      content: '';
    }
  }

  tbody tr {
    td {
      color: $base-black;
      border-bottom: 1px solid $grey-10;
      line-height: 2.4em;
      padding: $small;

      svg {
        display: block;
        text-align: center;
        color: $grey-400;
      }

      .checkbox {
        margin: 0;
        top: $tiny;
      }
    }
  }
}
