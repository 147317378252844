@import '@tame-your-theme/scss';

@import './change-datepicker-colors.scss';
@import '../../../theme/helpers/all';

.imperium-datepicker {
  @include change-datepicker-colors();

  border: solid 1px darken-theme-color(--datepicker-background-contrast-color, 50%);
  border-radius: $hair;
  position: relative;
  width: 100%;
  min-width: 12rem;

  .header-filters & {
    border-color: transparent;
  }

  .add-trade &,
  .light & {
    @include create-theme-color(--datepicker-background-color, #fff);
    @include create-theme-color(--datepicker-background-contrast-color, $dark-grey-color);

    border-color: transparent;

    input {
      font-size: 16px;
      background-color: $grey-100;
    }
  }

  .tenor-maturity-date-modal & {
    --datepicker-primary-color: var(--primary-color);
    --datepicker-primary-color-h: var(--primary-color-h);
    --datepicker-primary-color-s: var(--primary-color-s);
    --datepicker-primary-color-h: var(--primary-color-h);

    --datepicker-background-color: var(--background-main-color);
    --datepicker-background-color-h: var(--background-main-color-h);
    --datepicker-background-color-s: var(--background-main-color-s);
    --datepicker-background-color-h: var(--background-main-color-h);

    --datepicker-background-contrast-color: var(--background-main-contrast-color);
    --datepicker-background-contrast-color-h: var(--background-main-contrast-color-h);
    --datepicker-background-contrast-color-s: var(--background-main-contrast-color-s);
    --datepicker-background-contrast-color-h: var(--background-main-contrast-color-h);

    --datepicker-primary-contrast-color: var(--primary-contrast-color);
    --datepicker-primary-contrast-color-h: var(--primary-contrast-color-h);
    --datepicker-primary-contrast-color-s: var(--primary-contrast-color-s);
    --datepicker-primary-contrast-color-h: var(--primary-contrast-color-h);

    .react-datepicker {
      border: none;
    }
  }

  .field-error & {
    border-color: $error;

    &:focus-within {
      box-shadow: 0 0 0 1px $error;
    }

    input {
      border-color: transparent;

      &::placeholder {
        color: $error;
      }
    }
  }
}

#imperium-datepicker-portal {
  @include change-datepicker-colors();

  .react-datepicker-popper {
    $modal-z-index-plus-one: 1051;

    z-index: $modal-z-index-plus-one;
  }
}
