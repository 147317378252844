$baseFactor: 6px;

$unitFactorTiny: 1;
$unitFactorSmall: 2;
$unitFactorNormal: 4;
$unitFactorLarge: 8;
$unitFactorHuge: 16;

$hair: calc($baseFactor / $unitFactorSmall); // 3
$tiny: $baseFactor * $unitFactorTiny; // 6
$small: $baseFactor * $unitFactorSmall; // 12
$normal: $baseFactor * $unitFactorNormal; // 24
$large: $baseFactor * $unitFactorLarge; // 48
$huge: $baseFactor * $unitFactorHuge; // 96

$border-radius: 5px;
$border-radius-medium: 10px;
$panel-margin-bottom: 20px;
$input-group-height: 34px;

$common-space: 30px;
$common-padding: $common-space;
$common-margin: $common-space;

$table-row-height: 50px;
$scrollbar: 15px;

$mobile-width: 768px;
$tiny-desktop-width: 1152px;
$small-desktop-width: 1366px;
$normal-desktop-width: 1536px;
$large-desktop-width: 1920px;
$huge-desktop-width: 2560px;

$small-desktop-height: 850px;
$large-desktop-height: 1150px;

$nav-bar-z-index: 1030;
$drawer-z-index: 9999;
$tooltip-z-index: 9999;
$popover-z-index: 9999;
$toast-z-index: $drawer-z-index + 1;

$input-group-height: 32px;
$input-height: 34px;
$button-min-height: 34px + 2px;
