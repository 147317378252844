@import '../../../theme/helpers/all';

.view-offer-drawer {
  .rc-collapse {
    background-color: $modal-content-color;
    border-color: $modal-border-color;
    border-right: 0;
    border-left: 0;

    p {
      margin: 0;
    }

    .rc-collapse-item {
      border-color: $modal-border-color;

      &:hover {
        background-color: $dark-grey-color;
      }
    }

    &-header {
      display: flex;
      flex-direction: row-reverse;

      > svg {
        // @extend %rounded-button;
        font-size: 2.5rem;
        // color: var(--background-light-color)

        & ~ span {
          color: $light-grey;
          font-size: 2rem;
          flex: 1;
        }
      }
    }

    &-content {
      background-color: $modal-content-color;
      padding-bottom: $normal;
    }

    * {
      outline: none;
    }

    .tenors-grid {
      .tenor {
        border-width: 1px;
        min-height: 7rem;
        padding: 1.6rem;
        font-size: 1.4rem;
        text-transform: none;

        .percentage {
          font-size: 2rem;
          font-weight: bold;
        }

        &.is-dirty {
          border-color: $mid-grey;
        }

        &.disabled * {
          opacity: 1;
        }
      }
    }
  }

  .dialog-header {
    padding: $normal;
    gap: $normal;
  }

  .drawer-content-wrapper-footer {
    justify-content: flex-end;

    button {
      width: 12rem;
      @extend %primary-button;
    }
  }
}
