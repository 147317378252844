@import '../../../theme/helpers/palette.scss';
@import '../../../theme/helpers/guideline.scss';

$pill-radius: 4px;

.unsolicited-ratesheets-dialog {
  .modal-dialog {
    .modal-body {
      padding: 1em;
      overflow: auto;

      span {
        white-space: pre-wrap;
      }
    }
  }

  .center-labels {
    text-align: center;
  }
}
