@import '../../theme/helpers/guideline.scss';
@import '../../theme/helpers/palette.scss';

$box-min-width: 330px;

.reports-tab-container {
  .btn,
  .primary-button {
    min-width: 10rem;
    margin-top: 10px;
  }

  &-header {
    margin: $normal 0;

    > span {
      font-size: 1.8rem;
      font-weight: bold;
      color: $white;
    }
  }

  &-body {
    margin-bottom: $normal;

    .results-presenter {
      flex: 1;
    }

    > div > div {
      > span {
        display: block;
        color: $light-silver;
        margin-bottom: $tiny;
      }
    }
  }

  .monthly-report-selector {
    display: flex;
    margin-right: $small;

    .format,
    .datepicker-from,
    .datepicker-to {
      > span {
        color: $light-silver;
        margin-bottom: $tiny;
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .reports-tab-container {
    &-body {
      padding: 20px;

      > div {
        width: 100%;
        flex-direction: column;

        > div {
          margin: $tiny 0;
        }
      }
    }
  }
}
