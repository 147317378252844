@import '../../theme/helpers/all';

.summary-container {
  display: flex;
  align-items: center;
  padding-left: 0;
  padding-right: 1em;
  padding-bottom: 10px;
  font-size: 180%;

  &.incoming {
    display: flex;
    justify-content: space-between;
  }

  .summary-title {
    color: $white;
    cursor: default;
    font-size: 0.9em;
  }

  .counter-item {
    border-bottom: 2px solid;
    border-radius: 2px;
    text-align: center;
    color: $light-grey;
    height: 1.5em;
    width: 1.2em;
    margin-left: 0.4em;
    cursor: default;

    &.pending {
      border-color: $curious-blue;
    }
    &.highlighted-pending {
      background-color: $curious-blue;
      border-color: $curious-blue;
      color: $white;
    }
    &.ongoing {
      border-color: $yellow;
    }
    &.closed {
      border-color: $success;
    }
    &.won {
      border-color: $success;
    }
    &.lost {
      border-color: $error;
    }
    &.cancelled {
      border-color: $light-grey;
    }
  }

  .counters-container {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 0;
  }

  .push-right {
    margin-left: auto;
  }
}
