@import '../../../theme/helpers/palette.scss';
@import '../../../theme/helpers/guideline.scss';

$pill-radius: 4px;

.unsolicited-ratesheets-snapshots-table {
  padding: 1em;
  width: 100%;

  td,
  th {
    padding: 0.5em 1em;
  }

  tr td {
    &:first-child {
      cursor: pointer;
      max-width: 20rem;
    }

    label {
      width: 80px;
    }

    &.text-center {
      text-align: center;
    }
  }

  tr {
    .centralize-text {
      text-align: center;
    }

    .accepted-quote {
      color: $white;
      background-color: $success;
      padding: $hair $small;
      border-radius: $pill-radius;
    }

    .best-coupon {
      border: 1px solid $best-quote;
      padding: $hair $small;
      border-radius: $pill-radius;
    }
  }
}
