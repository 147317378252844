@import '../../theme/helpers/guideline';

.incoming-page {
  padding: 0 $common-padding;

  .tab-content {
    .enter-quotes {
      padding: $small 0;
    }
  }
}
