@import '../../theme/helpers/all';

.bar-chart-tooltip {
  background-color: $white;
  border-width: 1px;
  border-color: $very-dark-grey;
  border-style: solid;
  color: $very-dark-grey;
  padding: 8px;
  border-radius: $border-radius;
}
