@import '../../../../theme/helpers/palette.scss';
@import '../../../../theme/helpers/guideline.scss';

.table-settings {
  &-content {
    padding: 24px;

    table tbody tr {
      cursor: move;

      &.drop-over-downward {
        border-bottom: 2px solid $curious-blue;
      }
      &.drop-over-upward {
        border-top: 2px solid $curious-blue;
      }
    }

    > div > button {
      margin-top: $normal;
    }
  }
}
