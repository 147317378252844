@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';

.item-selector {
  flex: 1;
  background-color: $dark-grey;
  border-radius: $border-radius-medium;

  > .item-selector__item {
    border: 0;

    span {
      opacity: 1;
    }
  }

  &-body {
    min-height: 42rem;

    .item-selector__item:last-child {
      border: 0;
    }
  }

  &__item {
    padding: $small 0;
    margin: 0 $normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $mid-grey;

    label {
      cursor: pointer;
      margin-bottom: 0;
    }

    &--title {
      flex: 1;

      & + label span {
        margin-right: $small;
      }
    }

    .checkbox-container {
      margin: $small 0;
      .checkbox {
        margin: 0;
        line-height: 20px;
      }

      > span {
        font-size: 1.2rem;
        margin-right: $tiny;
        color: $very-light-green;
        opacity: 1;
      }
    }

    .checkbox {
      $checkbox-offset-width: 18px;

      label::before {
        background-color: $dark-grey;
        border: 1px solid $grey-border-color;
      }

      label {
        padding-left: $checkbox-offset-width;
      }
      .inner-text {
        margin-left: 0;
      }
    }

    span {
      color: white;
      opacity: 0.5;
    }

    &--select-all > span:first-of-type {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__item--select-all {
    margin: 0 $normal;

    > label > span {
      color: $grey-dark;
      font-size: 14px;
    }

    .checkbox-container > span {
      display: none;
    }
  }
}
