@import '../../theme/helpers/all';

.action-button {
  width: 95%;
}

.action-button:hover {
  &.pending-action-button {
    color: $white;
    border-color: $curious-blue;
    background-color: #3d404c;
  }
}

.pending-row {
  color: $white;
}

.pending-action-button {
  border-color: $curious-blue;
}

div.kyc-ok {
  color: $success;
  font-size: xx-small;
  display: inline;
  font-weight: bolder;
  padding: 0 2px;
}

a.white-link {
  color: $white;
}

.status-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: auto;
  margin-top: 5px;

  &.buy {
    background-color: $success;
  }
  &.sell {
    background-color: $success;
  }
  &.pending {
    background-color: $curious-blue;
  }
  &.ongoing {
    background-color: $yellow;
  }
  &.won {
    background-color: $success;
  }
  &.closed {
    background-color: $success;
  }
  &.lost {
    background-color: $error;
  }
  &.cancelled {
    background-color: $light-grey;
  }
}
