@import '../../theme/helpers/all';

.btn-toggle {
  label.btn-default {
    &.active,
    &:active,
    &:focus,
    &.active:hover,
    &.active.focus {
      background-color: var(--primary-color);
      color: var(--primary-contrast-color);
      border-color: var(--primary-color);
    }
  }

  label.btn.btn-default.or {
    border: 0;
    width: 25px;
    position: absolute;
    margin-left: -13px;
    z-index: 5;
    height: 25px;
    padding-top: 1px;
    margin-top: 4px;
    text-align: center;
    padding-left: 6px;
    border-radius: 20px !important;
    background: $very-dark-grey !important;
  }
}
