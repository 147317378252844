.error {
  padding: 80px 15px;
  text-align: center;
  margin: 0 auto;

  p span {
    white-space: pre;
  }

  h1 {
    font-size: 50px;
    font-weight: normal;
  }

  .error-actions {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .error-actions .btn {
    margin-right: 10px;
  }
}
