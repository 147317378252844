@import '@tame-your-theme/scss';

@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';

@import './change-select-colors.scss';

$text-contrast-color: $dark-grey-color;

.imperium-select-container {
  .my-react-select {
    @include change-select-colors();

    &__control {
      min-height: $input-height;
      height: $input-height;

      &--is-focused {
        .my-react-select__single-value {
          color: var(--react-select-primary-color);
        }
      }
    }

    &__value-container {
      padding-top: 0;
      padding-right: 0;
    }

    &__menu {
      margin-top: $tiny;
    }
  }

  .header-filters & {
    $text-contrast-color: $dark-grey-color;

    @include create-theme-color(--react-select-background-color, #fff);
    @include create-theme-color(--react-select-background-contrast-color, $text-contrast-color);

    .my-react-select {
      &__control {
        min-height: 30px;
        height: 30px;
        border: transparent;
        cursor: pointer;

        &--is-focused {
          border: transparent;
          box-shadow: none;
        }

        &--is-focused {
          .my-react-select__single-value {
            color: var(--react-select-background-contrast-color);
          }
        }
      }

      &__indicators {
        align-items: flex-start;
      }

      &__indicator {
        padding: 0;
      }

      &__input-container,
      &__placeholder,
      &__single-value {
        padding: 0;
        margin: 0;
        font-size: 1.8rem;
        font-weight: bold;
      }

      &__value-container {
        padding-left: 0;
      }

      &--is-selected {
        font-weight: bold;
      }
    }
  }

  .add-trade &,
  .light & {
    @include create-theme-color(--react-select-background-color, $grey-100);
    @include create-theme-color(--react-select-background-contrast-color, $text-contrast-color);

    .my-react-select {
      border-color: transparent;

      &__control {
        border-color: transparent;

        &--is-focused {
          .my-react-select__single-value {
            color: var(--react-select-background-contrast-color);
          }
        }
      }
    }
  }

  form.form-switcher & {
    @include create-theme-color(--react-select-background-color, #fff);
    @include create-theme-color(--react-select-background-contrast-color, $text-contrast-color);

    .my-react-select {
      border-color: transparent;

      &__control {
        $item-margin: 18px; // needs to be the same as the one from src/components/common/Dropdown/dropdown.scss

        border: none;
        cursor: pointer;
        padding-left: $item-margin;

        &--is-focused {
          border: none;
          box-shadow: none;

          .my-react-select__single-value {
            color: var(--react-select-background-contrast-color);
          }
        }
      }

      &__indicator {
        padding-top: 0;
      }

      &__indicators {
        align-items: flex-start;
      }

      &__input-container,
      &__placeholder,
      &__single-value {
        padding: 0;
        margin: 0;
        font-size: 1.8rem;
        font-weight: bold;
      }

      &__value-container {
        padding-left: 0;
      }

      &--is-selected {
        font-weight: bold;
      }
    }
  }

  .field-error & {
    .my-react-select {
      border-color: transparent;

      &__control {
        border-color: $error;

        &--is-focused {
          box-shadow: 0 0 0 1px $error;
        }
      }

      &__placeholder {
        color: $error;
      }
    }
  }

  .offer-container & {
    @include create-theme-color(--react-select-background-color, $grey-100);
    @include create-theme-color(--react-select-background-contrast-color, $text-contrast-color);

    --react-select-primary-color: var(--primary-color);
    --react-select-primary-color-h: var(--primary-color-h);
    --react-select-primary-color-s: var(--primary-color-s);
    --react-select-primary-color-h: var(--primary-color-h);

    --react-select-primary-contrast-color: var(--primary-contrast-color);
    --react-select-primary-contrast-color-h: var(--primary-contrast-color-h);
    --react-select-primary-contrast-color-s: var(--primary-contrast-color-s);
    --react-select-primary-contrast-color-h: var(--primary-contrast-color-h);

    .my-react-select__control {
      &--is-focused {
        .my-react-select__single-value {
          color: var(--react-select-background-contrast-color);
        }
      }
    }
  }
}
