@import '../../../theme/helpers/all';

.reinvest-on-behalf-dialog .modal-dialog {
  .modal-header {
    padding: 3rem 2rem;
  }

  .modal-content {
    min-width: 120rem;

    .offer-container {
      padding: $normal;

      .tenors-grid .tenor {
        min-height: 13rem;
        padding: 2.5rem;

        .percentage {
          font-size: 3rem;
        }
      }

      @media (max-height: $small-desktop-height) {
        .tenors-grid {
          grid-gap: 1rem;
          min-height: 30vh;

          .tenor {
            font-size: 1.25rem;
            min-height: 5rem;
            padding: 2rem;

            .percentage {
              font-size: 2rem;
            }
          }
        }
      }

      .select-tenor > :first-child > span:first-of-type {
        font-size: 2rem;
      }

      .offer-actions {
        > div.flex > div {
          margin-bottom: 0;
        }

        .button-row > button,
        .confirm-reinvestment > button {
          font-size: 1.8rem;
        }

        .additional-funds input {
          font-size: 2rem;
          height: 4.5rem;
        }

        .selected-offer .rate {
          margin-bottom: $small;

          .percentage {
            height: initial;
          }
        }

        .not-interested {
          display: none;
        }

        &.cp,
        &.cd {
          .amount {
            margin-bottom: $normal;
          }
        }
      }
    }
  }

  .modal-body {
    padding: 0;
  }
}
