@import '../../../../theme/helpers/palette.scss';

.table-container {
  padding: 0 2em 1em 0;
  max-height: 75%;
  overflow-y: auto;
  font-size: small;
  tbody tr td:not(.action-cell) {
    padding-top: 15px;
  }

  .table {
    .securities-column {
      display: none;
    }

    &.has-securities {
      .securities-column {
        display: table-cell;
      }
    }
  }
}

.table-container::-webkit-scrollbar {
  width: 1em;
  height: 1.5em;
}

.table-container::-webkit-scrollbar-thumb {
  height: 0.5em;
  border: 4px solid transparent;
  background-clip: padding-box;
  -webkit-border-radius: 0.5em;
  border-radius: 0.5em;
  background-color: $light-grey;
}

.table-container::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.table-container::-webkit-scrollbar-corner {
  background-color: transparent;
}

.status-won {
  color: $light-green;
}
