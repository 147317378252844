@import '@tame-your-theme/scss';
@import '../../../theme/helpers/all';

$font-size: 1.8rem;
$item-height: 48px;
$item-margin: 18px;
$select-line-height: 26px;

.select-dropdown-container {
  .item {
    height: $item-height;
    padding-top: 3px;
    background-color: $white;
    margin-right: $tiny;
    border-radius: $border-radius;

    p {
      margin: 0;
      margin-left: $item-margin;
      font-size: $small;
      font-weight: 500;
      color: $dark-grey-color;
    }

    input,
    .input-group-addon {
      border-bottom: none;
      margin-left: $item-margin;
    }

    input {
      font-size: $font-size;
      color: $dark-grey-color;
      font-weight: bold;

      &::placeholder {
        color: $dark-grey-color;
        font-weight: normal;
      }
    }

    .select-control.is-open .Select-menu-outer {
      background-color: $white;
      border-radius: $border-radius;

      .Select-option {
        background-color: $white;
      }

      .Select-option:first-child {
        border-top-right-radius: $border-radius;
        border-top-left-radius: $border-radius;
      }

      .Select-option.is-selected {
        background-color: $light-silver;
        font-weight: bold;
      }

      .Select-option.is-focused {
        background-color: $light-silver;
      }
    }

    .Select.is-focused:not(.is-open) > .Select-control {
      border-color: transparent;
    }

    .Select {
      .Select-control {
        background-color: transparent;
        border-color: transparent;
        margin-left: $tiny;
        color: $dark-grey-color;

        .Select-arrow-zone {
          width: 60px;
          top: 0;
          vertical-align: top;
          padding-top: 2px;
        }

        .Select-placeholder {
          font-size: $font-size;
          font-weight: bold;
          line-height: $select-line-height;
          color: $dark-grey-color;
        }

        .Select-input {
          height: initial;

          input {
            margin-left: 0;
            padding: 0;
            line-height: $select-line-height;
          }
        }

        .Select-value {
          line-height: $select-line-height;
        }

        .Select-value-label {
          font-size: $font-size;
          font-weight: bold;
        }

        .arrow-icon {
          width: $small;
          height: $tiny;
          vertical-align: top;
        }

        &:hover {
          box-shadow: none;
        }
      }

      &.has-value > .Select-control .Select-value .Select-value-label,
      &.has-value.Select--single > .Select-control .Select-value .Select-value-label {
        color: $dark-grey-color;
      }
    }
  }
}
