@import '../../theme/helpers/all';

.counterparty-list {
  .results {
    border-radius: $border-radius;

    .records-loading {
      margin: $panel-margin-bottom;
    }
  }
}
