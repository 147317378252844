@import '@tame-your-theme/scss';

@import '../../theme/helpers/palette.scss';
@import '../../theme/helpers/guideline.scss';

aside.navigation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(to right, var(--nav-color), darken-theme-color(--nav-color, 5%));

  nav {
    background-color: transparent;

    > p {
      font-size: 1.2rem;
      font-weight: bold;
      color: var(--primary-color);
      text-transform: uppercase;
      margin: 16px $common-margin;
    }

    .luna-nav.nav {
      padding-top: 0;

      li a {
        color: var(--nav-contrast-color);
        font-size: 1.8rem;
        line-height: 2.8rem;
        padding-left: $common-padding;

        &:hover {
          color: var(--nav-contrast-hover);
        }
      }

      li.active a {
        background-color: var(--background-dark-color);
        border-left: 6px solid var(--primary-color);
        padding-left: 26px;
        color: var(--primary-text-color);
        font-weight: bold;
      }
    }
  }

  .user-container {
    background-color: var(--background-dark-color);
    padding: 10px;

    .user-empty {
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      height: $common-space;
      width: $common-space;
      background-color: $dark-grey-color;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 24px;
      }
    }

    p {
      color: var(--background-dark-contrast-color);
      font-size: 1.6rem;
      margin: 0 0 0 $tiny;
    }

    button {
      color: var(--primary-color);
    }
  }
}
