@import '../../theme/helpers/all.scss';

.select-period {
  .btn {
    min-width: 10rem;
    height: $input-height;
    margin-top: 8px;
  }

  &-header {
    margin: $normal 0;

    > span {
      font-size: 1.8rem;
      font-weight: bold;
      color: $white;
    }
  }

  .monthly-report-selector {
    display: flex;
    margin-right: $small;

    .format,
    .datepicker-from,
    .datepicker-to {
      > span {
        color: $light-silver;
        margin-bottom: $tiny;
      }
    }
  }
}
