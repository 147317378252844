@import '../../theme/helpers/palette.scss';

.user-notification {
  .alert {
    position: fixed;
    width: 600px;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    background-color: $very-dark-grey;
    border-color: $light-orange;
    margin-top: 90px;
    z-index: 2000;
    border: 0;
    color: $white;

    span {
      text-align: center;
    }
  }

  .alert-info {
    background-color: $curious-blue;
  }

  .alert-success {
    background-color: $success;
  }

  .alert-warning {
    background-color: $warning;
  }

  .alert-danger {
    background-color: $error;
  }

  .alert-curious {
    background-color: $curious-blue;
  }
}
