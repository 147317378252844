@import '../../../theme/helpers/all';

.rfqs {
  .table {
    tr {
      height: 40px;

      .actions {
        text-align: center;

        button {
          min-width: 86px;
        }
      }
    }
  }
  .btn-table {
    &.large {
      width: 100%;
      max-width: 100%;
    }
  }
  .redeem,
  .redeem:hover,
  .redeem:active,
  .redeem:focus,
  .redeem.btn-warning:active {
    border-color: $error;
  }

  .td-1-space {
    width: 8.3333%;
  }

  .td-2-space {
    width: 16.6666%;
  }

  .td-3-space {
    width: 24.9999%;
  }

  .loader {
    text-align: center;
  }
}
