$currentDirectory: "./";

@font-face {
  font-family: "Proxima-Nova";
  src: url($currentDirectory + "ProximaNova-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "ProximaNova-Bold.woff") format("woff"),
    url($currentDirectory + "ProximaNova-Bold.ttf") format("truetype"),
    url($currentDirectory + "ProximaNova-Bold.svg#ProximaNova-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}
