@import '../../../theme/helpers/palette.scss';
@import '../../../theme/helpers/guideline.scss';

.close-buy-sell-bond-container {
  .results-presenter .panel-filled .action-column {
    padding-right: 0;
  }

  .action-column {
    text-align: right;

    button {
      width: 80%;

      &.accepted {
        border-color: $success;
        background-color: $success;
        color: $white;
      }

      &.confirmed {
        border-color: $success;
        background-color: $success;
        color: $white;
        pointer-events: none;
        font-weight: bold;
      }

      &.waiting-confirmation {
        border-color: $pending;
        background-color: $pending;
        color: $white;
        pointer-events: none;
        font-weight: bold;
      }
    }
  }

  .btn-default {
    margin: 0 $small;
  }

  span.quote-value {
    padding: $tiny;
    &.best-quote {
      border: 1px solid $best-quote;
    }
  }

  .cancel-button {
    &:hover,
    &:active,
    &:focus {
      border-color: $error;
      color: $error;
    }
  }
}
