@import '../../../../../../theme/helpers/palette.scss';
@import '../../../../../../theme/helpers/guideline.scss';

.ultimate-curve-details {
  .tenors-grid {
    grid-template-columns: repeat(4, 15rem);
    justify-content: space-between;
    gap: 1.5rem;

    .tenor {
      height: auto;
      padding: 1rem;
      border-width: 1px;

      .footer {
        width: 80%;
      }

      .percentage {
        justify-content: center;
      }

      span.empty-percentage {
        font-size: 4rem;
        padding-bottom: 0;
      }
    }
  }

  .rc-collapse-header dl {
    grid-template-columns: 4rem repeat(4, minmax(10rem, 1fr));
  }

  dl {
    padding: 0;
    border-bottom: none;
  }

  h4 {
    margin: $normal 0;
    color: $grey-700;
  }
}

.ultimate-curve-popover {
  .popover-content {
    padding: $tiny;
  }

  color: var(--dark-white-background-contrast-color);
}

.rate-curve-chart {
  height: 500px;
  width: 100%;
}
