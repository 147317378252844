@import '../../theme/helpers/all';

.quotes {
  padding: 0 $common-padding;

  .summary {
    display: flex;

    .summary-content {
      flex-basis: 25%;
      padding: $small 0;
    }
  }

  .quotes-rfqs-list {
    .panel {
      flex: 1;

      .panel-header {
        display: flex;
        flex: 1;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .footer-row {
        margin-top: 1em;

        .value-highlight {
          margin-top: 0;
        }
      }

      .action-footer-row {
        display: flex;
        flex: 1;
        gap: $tiny;

        .btn {
          width: 13em;
        }

        .cancel-button {
          border-color: $light-orange;
        }
      }
    }
  }

  .results-presenter {
    flex: 1;

    .panel.panel-filled {
      .result-title {
        color: $yellow;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .table {
        th {
          svg {
            margin-left: $tiny;
            color: var(--primary-color);
          }
        }

        td {
          vertical-align: middle;
          height: 45px;
          $issuer-width: 250;
          $received-at-width: 100;
          $td-padding-width: 8;
          &.col-quote-issuer-adi {
            width: $issuer-width + px;
            label {
              cursor: default;
              width: $issuer-width + px;
            }
          }

          &.col-quote-received-at {
            width: $received-at-width + px;

            + td {
              text-align: center;
            }
          }

          &.col-waiting-quote-issuer-adi {
            $waiting-issuer-width: $issuer-width + $received-at-width + ($td-padding-width * 2);
            width: $waiting-issuer-width + px;

            label {
              cursor: default;
              width: $issuer-width + px;
            }
          }

          &.col-quote-coupon {
            .btn {
              svg {
                height: 0.8em;
                width: 0.8em;
                margin-left: $tiny;
              }
              width: 100px;
              &.rejected,
              &.accepted {
                cursor: default;
              }
              &.btn-success {
                border-color: $success;

                &:hover {
                  color: var(--primary-color);
                }
              }
              &.active,
              &.nominated {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
                color: var(--primary-contrast-color);
                font-weight: bold;

                &:hover {
                  background-color: var(--primary-color-hover);
                }
              }
              &.rejected:hover {
                color: inherit;
              }
              &.accepted:hover {
                background-color: var(--primary-color-hover);
              }
              &.btn-zero {
                border-color: $sandbox-red;
              }
            }
          }
        }
      }
    }
  }

  .btn {
    &.btn-link {
      border-top-width: 0;
    }
  }
}

@media (max-width: $tiny-desktop-width) {
  .quotes .quotes-rfqs-list .panel .footer-row {
    flex-direction: column;

    > div {
      margin-bottom: $small;
    }
  }
}
