@import '../../../../theme/helpers/all';

$verbose-width: 15rem;

.sell-bond-rfq-status {
  width: $small;
  height: $small;
  border-radius: 50%;
  display: block;

  &:not(.verbose) {
    margin: auto;
    margin-top: $tiny;
  }

  &.verbose {
    display: inline-block;
    color: $dark-grey-color;
    width: $verbose-width;
    height: initial;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
  }

  &.cancelled,
  &.expired {
    background-color: $cancelled;
  }

  &.closed {
    background-color: $closed;
  }

  &.won {
    background-color: $won;
    color: $white;
  }

  &.lost {
    background-color: $lost;
    color: $white;
  }

  &.ongoing {
    background-color: $ongoing;
  }

  &.pending,
  &.waiting-confirmation {
    background-color: $pending;
    color: $white;
  }
}

td.rfq-status-column {
  width: $verbose-width;
}
