.btn:active,
.btn.active {
  box-shadow: none;
}

.btn {
  color: inherit;
  background-color: transparent;
}

.buttons-margin .btn {
  margin-bottom: 5px;
}

.btn-default {
  border-color: #585d6e;
}

.btn.btn-min-width-sm {
  min-width: 80px;
}

.btn.btn-min-width-md {
  min-width: 120px;
}

.btn.btn-min-width-lg {
  min-width: 160px;
}

.btn.btn-rounded {
  border-radius: 50px;
}

.btn.btn-squared {
  border-radius: 0;
}

.btn-accent {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #f6a821;
}

.btn-accent:focus,
.btn-accent.focus {
  color: #ffffff;
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f6a821;
  outline: 0;
  box-shadow: none;
}

.btn-accent:hover {
  color: #ffffff;
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f6a821;
}

.btn-accent[disabled] {
  border-color: #616779;
}

.btn-accent:active,
.btn-accent.active,
.open > .dropdown-toggle.btn-accent {
  color: #ffffff;
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f6a821;
}

.btn-accent:active:hover,
.btn-accent.active:hover,
.open > .dropdown-toggle.btn-accent:hover,
.btn-accent:active:focus,
.btn-accent.active:focus,
.open > .dropdown-toggle.btn-accent:focus,
.btn-accent:active.focus,
.btn-accent.active.focus,
.open > .dropdown-toggle.btn-accent.focus {
  color: #ffffff;
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f8bb52;
  outline: 0;
  box-shadow: none;
}

.btn-accent:active,
.btn-accent.active,
.open > .dropdown-toggle.btn-accent {
  background-image: none;
}

.btn-accent.disabled:hover,
.btn-accent[disabled]:hover,
fieldset[disabled] .btn-accent:hover,
.btn-accent.disabled:focus,
.btn-accent[disabled]:focus,
fieldset[disabled] .btn-accent:focus,
.btn-accent.disabled.focus,
.btn-accent[disabled].focus,
fieldset[disabled] .btn-accent.focus {
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f6a821;
}

.btn-accent .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-default {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #616779;
}

.btn-default:focus,
.btn-default.focus {
  color: #ffffff;
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #616779;
  outline: 0;
  box-shadow: none;
}

.btn-default:hover {
  color: #ffffff;
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #616779;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #ffffff;
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #616779;
}

.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #ffffff;
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #798094;
  outline: 0;
  box-shadow: none;
}

.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #616779;
}

.btn-default .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-primary {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #0f83c9;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: rgba(15, 131, 201, 0.1);
  border-color: #0f83c9;
  outline: 0;
  box-shadow: none;
}

.btn-primary:hover {
  color: #ffffff;
  background-color: rgba(15, 131, 201, 0.1);
  border-color: #0f83c9;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: rgba(15, 131, 201, 0.1);
  border-color: #0f83c9;
}

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: rgba(15, 131, 201, 0.1);
  border-color: #1d9fee;
  outline: 0;
  box-shadow: none;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: rgba(15, 131, 201, 0.1);
  border-color: #0f83c9;
}

.btn-primary .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-success {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #1bbf89;
}

.btn-success:focus,
.btn-success.focus {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
  outline: 0;
  box-shadow: none;
}

.btn-success:hover {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #2ce1a5;
  outline: 0;
  box-shadow: none;
}

.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
}

.btn-success .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-info {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #56c0e0;
}

.btn-info:focus,
.btn-info.focus {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
  outline: 0;
  box-shadow: none;
}

.btn-info:hover {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
}

.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #81d0e8;
  outline: 0;
  box-shadow: none;
}

.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
}

.btn-info .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-warning {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #f7af3e;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
  outline: 0;
  box-shadow: none;
}

.btn-warning:hover {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
}

.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f9c36f;
  outline: 0;
  box-shadow: none;
}

.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
}

.btn-warning .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-danger {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #db524b;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
  outline: 0;
  box-shadow: none;
}

.btn-danger:hover {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
}

.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #e47b75;
  outline: 0;
  box-shadow: none;
}

.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
}

.btn-danger .badge {
  color: transparent;
  background-color: #aaaaaa;
}

.btn-default:hover,
.btn-default:active,
.btn-default:active:focus,
.btn-default:focus,
.btn-default.active {
  background-color: #3d404c;
}

.btn-link:hover,
.btn-link:active,
.btn-link:active:focus,
.btn-link:focus,
.btn-link.active {
  color: #f6a821;
  text-decoration: none;
}

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  background: #fff;
  overflow: hidden;
}

.pace .pace-progress {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 200px;
  z-index: 2000;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #f6a821;
}

.pace.pace-inactive {
  display: none;
}

.pace-done .content > div {
  opacity: 1;
}

.text-muted {
  color: #868e96;
}

.text-accent {
  color: #f6a821;
}

.text-primary {
  color: #0f83c9;
}

.text-success {
  color: #1bbf89;
}

.text-info {
  color: #56c0e0;
}

.text-warning {
  color: #f7af3e;
}

.text-danger {
  color: #db524b;
}

/* MARGINS & PADDINGS */
.p-xxs {
  padding: 5px !important;
}

.p-xs {
  padding: 10px !important;
}

.p-sm {
  padding: 15px !important;
}

.p-m {
  padding: 20px !important;
}

.p-md {
  padding: 25px !important;
}

.p-lg {
  padding: 30px !important;
}

.p-xl {
  padding: 40px !important;
}

.m-xxs {
  margin: 2px 4px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-t-none {
  margin-top: 0;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-xxl {
  margin-top: 50px;
}

.m-t-xxxl {
  margin-top: 60px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-r-none {
  margin-right: 0;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-b-none {
  margin-bottom: 0;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.space-15 {
  margin: 15px 0;
}

.space-20 {
  margin: 20px 0;
}

.space-25 {
  margin: 25px 0;
}

.space-30 {
  margin: 30px 0;
}

.label-accent {
  background-color: #f6a821;
}

.content {
  margin-top: 60px;
  position: relative;
  transition: margin-left 0.3s ease-out;
  -webkit-transition: margin-left 0.3s ease-out;
}

.navigation {
  margin-top: 60px;
  width: 290px;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  transition: left 0.3s ease-out;
  -webkit-transition: left 0.3s ease-out;
  z-index: 2;
}

.navigation nav {
  background-color: #2a2d35;
}

.navigation:before {
  transition: left 0.3s ease-out;
  -webkit-transition: left 0.3s ease-out;
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: -1;
  left: 0;
  width: 290px;
}

.navigation {
  left: -290px;
  z-index: 2;
}

.navigation:before {
  left: -290px;
}

body.nav-toggle .content {
  margin-left: 0;
}

body.nav-toggle .navigation {
  left: 0;
}

body.nav-toggle .navigation:before {
  left: 0;
}

body.blank .content {
  margin: 0;
}

.container-center {
  max-width: 400px;
  margin: 10% auto 0;
  padding: 20px;
}

.container-center.sm {
  max-width: 200px;
}

.container-center.md {
  max-width: 600px;
}

.container-center.lg {
  max-width: 800px;
}

/* Various */
a {
  color: #f6a821;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #f8bb52;
  outline: none;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #3d404c;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #f6a821;
  background-color: #24262d;
  border-radius: 3px;
}

pre {
  font-size: 90%;
  color: #aaaaaa;
  background-color: #24262d;
  border-radius: 3px;
  border: none;
}

mark {
  background-color: #e9e599;
  border-radius: 2px;
  padding: 0.1em 0.4em;
}

label {
  color: #ffffff;
  font-weight: 500;
}

sup {
  font-size: 12px;
  top: -0.8em;
}

.help-block {
  color: #6d757d;
}

.bs-example {
  background-color: #2f323b;
  padding: 10px;
  border-radius: 3px;
}

.show-grid [class^='col-'] {
  background-color: #2f323b !important;
  border: 1px solid #3d404c;
  padding-bottom: 10px;
  padding-top: 10px;
}

.slight {
  font-size: 11px;
  font-weight: 300;
}

.show-grid {
  margin: 10px 0 !important;
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-left: 10px;
  padding-right: 10px;
}

/* Icons */
.font-example-box:hover {
  color: #ffffff;
}

.font-example-box {
  text-align: center;
  min-height: 90px;
  margin: 10px 0;
  font-size: 42px;
}

.font-example-box i {
  font-size: 22px;
}

.icon-example-name {
  display: block;
  font-size: 11px;
}

.back-link {
  float: left;
  width: 100%;
  margin: 10px;
}

/* UI Sortable */
.ui-sortable .panel-body {
  cursor: move;
}

.ui-sortable-placeholder {
  border: 1px dashed #535868 !important;
  visibility: visible !important;
}

.navbar-default .navbar-brand,
.navbar-default .navbar-brand:focus {
  width: 230px;
  background-color: #f6a821;
  height: 60px;
  /*    padding: 20px 25px; */
  font-weight: 400;
  letter-spacing: 10px;
  color: #ffffff;
}

.navbar-default .navbar-brand {
  color: #ffffff;
}

.navbar-default .navbar-brand:hover {
  color: #ffffff;
  background-color: #f5a212;
}

.navbar-brand span {
  letter-spacing: normal;
  float: right;
  font-size: 12px;
  margin-left: -15px;
  color: #fce2b4;
}

.left-nav-toggle {
  float: left;
  padding: 9px 10px 8px 25px;
}

.left-nav-toggle:hover a {
  color: #f6a821;
}

.left-nav-toggle a:hover,
.left-nav-toggle a:focus {
  text-decoration: none;
}

.navbar-form {
  margin-top: 11px;
  padding: 0;
}

.navbar-form input.form-control {
  border: none;
  box-shadow: none;
  color: #aaaaaa;
}

.navbar-form input.form-control::-webkit-input-placeholder {
  color: #6f7780;
}

.navbar-form input.form-control:-moz-placeholder {
  color: #6f7780;
}

.navbar-form input.form-control::-moz-placeholder {
  color: #6f7780;
}

.navbar-form input.form-control:-ms-input-placeholder {
  color: #6f7780;
}

.navbar-nav > li > a {
  padding: 20px 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.navbar-nav > li:first-child > a {
  padding-left: 0;
}

.navbar-nav > li:last-child > a {
  padding: 10px 25px 10px 15px;
}

.navbar-nav.navbar-right a .label {
  margin: -5px 0 0 5px;
}

.profil-link img {
  height: 40px;
  margin-left: 10px;
}

.profil-link .profile-address {
  text-transform: lowercase;
}

#mobile-menu {
  display: none;
}

@media (max-width: 767px) {
  #mobile-menu {
    display: block;
  }
}

#mobile-menu {
  float: right;
}

#mobile-menu .left-nav-toggle {
  padding-right: 25px;
}

.luna-nav.nav {
  padding-top: 15px;
}

.luna-nav.nav li > a,
.luna-nav.nav .nav-category {
  padding: 8px 15px 8px 25px;
  margin: 0 0;
}

.luna-nav.nav .nav-info {
  margin-top: 50px;
  padding: 20px 25px 25px 25px;
  font-size: 12px;
  background-color: #24262d;
}

.luna-nav.nav .nav-info i {
  font-size: 34px;
}

.luna-nav.nav .nav-second li > a {
  padding-left: 40px;
}

.luna-nav.nav li.active .nav-second li > a {
  padding-left: 40px;
  border-left: none;
  color: #848c94;
}

.luna-nav.nav li.active .nav-second li.active a {
  padding-left: 34px;
  border-left: 6px solid #c37e08;
  color: #c0c4c8;
}

.luna-nav.nav li.active .nav-second li a:hover {
  color: #c0c4c8;
}

.luna-nav.nav li > a {
  color: #848c94;
}

.luna-nav.nav li.active a {
  border-left: 6px solid #f6a821;
  padding-left: 19px;
  color: #c0c4c8;
}

.luna-nav.nav li a:hover,
.luna-nav.nav li a:focus {
  color: #c0c4c8;
  background: inherit;
}

.luna-nav.nav .nav-category {
  color: #ffffff;
  margin-top: 10px;
}

.luna-nav.nav .sub-nav-icon {
  float: right;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
}

.panel {
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #aaaaaa;
  border-radius: 3px;
}

.panel .panel-body {
  padding: 5px 15px 15px 15px;
}

.panel.panel-filled .panel-body {
  padding-top: 10px;
}

.panel .panel-footer {
  background-color: transparent;
  border: none;
}

.panel > .panel-heading {
  color: #ffffff;
}

.panel-tools {
  display: inline-block;
  float: right;
  margin-top: 0;
  padding: 0;
  position: relative;
}

.panel-tools a {
  margin-left: 2px;
  color: #aaaaaa;
  cursor: pointer;
}

.panel-tools a:hover {
  color: #ffffff;
}

.panel.panel-c-white {
  border-left: 3px solid #aaaaaa;
}

.panel.panel-c-accent {
  border-left: 3px solid #f6a821;
}

.panel.panel-c-primary {
  border-left: 3px solid #0f83c9;
}

.panel.panel-c-warning {
  border-left: 3px solid #f7af3e;
}

.panel.panel-c-info {
  border-left: 3px solid #56c0e0;
}

.panel.panel-c-success {
  border-left: 3px solid #1bbf89;
}

.panel.panel-c-danger {
  border-left: 3px solid #db524b;
}

.panel.panel-b-accent {
  background-color: #f6a821;
  color: #ffffff;
}

.panel.panel-b-white {
  background-color: white;
  color: #61686f;
}

.panel.panel-b-white .panel-heading {
  color: #61686f;
}

.panel.panel-b-white .panel-footer {
  color: #61686f;
}

.panel.panel-filled {
  background-color: #33353c;
}

.view-header {
  margin: 20px 0;
  min-height: 50px;
  padding: 0 15px;
}

.view-header .header-icon {
  font-size: 60px;
  color: #f6a821;
  width: 68px;
  float: left;
  margin-top: -8px;
  line-height: 0;
}

.view-header .header-title {
  margin-left: 68px;
}

.view-header .header-title h3 {
  margin-bottom: 2px;
}

.panel.collapsed .panel-body,
.panel.collapsed .panel-footer {
  display: none;
}

.panel.collapsed .fa.fa-chevron-up:before {
  content: '\f078';
}

.panel.collapsed .fa.fa-chevron-down:before {
  content: '\f077';
}

.pagination > li > a,
.pagination > li > span {
  color: #aaaaaa;
  background-color: transparent;
  border: 1px solid #585d6e;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #ffffff;
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #616779;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #ffffff;
  background-color: rgba(97, 103, 121, 0.1);
  border-color: #616779;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #aaaaaa;
  background-color: transparent;
  border-color: #4a4f5d;
}

/* Progress bar */
.progress {
  border-radius: 2px;
  margin-bottom: 10px;
}

.progress-bar {
  background-color: #2f323b;
  text-align: right;
  padding-right: 10px;
  color: #aaaaaa;
}

.progress-small,
.progress-small .progress-bar {
  height: 10px;
}

.progress-bar-primary {
  border-right: 4px solid #0d74b1;
}

.progress-bar-success {
  border-right: 4px solid #18a979;
}

.progress-bar-info {
  border-right: 4px solid #0d74b1;
}

.progress-bar-warning {
  border-right: 4px solid #f6a526;
}

.progress-bar-danger {
  border-right: 4px solid #d73e36;
}

.full .progress-bar-success {
  background-color: #1bbf89;
  border-right: 4px solid #18a979;
}

.full .progress-bar-info {
  background-color: #0f83c9;
  border-right: 4px solid #0d74b1;
}

.full .progress-bar-warning {
  background-color: #f7af3e;
  border-right: 4px solid #f6a526;
}

.full .progress-bar-danger {
  background-color: #db524b;
  border-right: 4px solid #d73e36;
}

.full .progress-bar-primary {
  background-color: #0f83c9;
  border-right: 4px solid #0d74b1;
}

.full .progress-bar {
  color: #ffffff;
}

.modal-content .modal-title {
  font-size: 22px;
}

.modal-content .modal-header {
  border-color: #3d404c;
  padding: 40px 20px;
}

.modal-content .modal-body {
  padding: 40px;
}

.modal-content .modal-footer {
  border-color: #3d404c;
  padding: 20px;
}

.modal-backdrop.in {
  filter: alpha(opacity=10);
  opacity: 0.1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--background-main-contrast-color);
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 10px;
}

.c-accent {
  color: #f6a821;
}

.c-white {
  color: #ffffff;
}

.c-text {
  color: #aaaaaa;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 600;
}

.font-extra-bold {
  font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
  color: #a4aab0;
}

.label-default {
  background-color: #3a3b41;
}

.label-primary {
  background-color: #0f83c9;
}

.label-success {
  background-color: #77b546;
}

.label-info {
  background-color: #56c0e0;
}

.label-warning {
  background-color: #f7af3e;
}

.label-danger {
  background-color: #ff3e3d;
}

.badge {
  background-color: transparent;
  border: 1px solid #3a3b41;
  font-weight: 300;
  color: #aaacb5;
}

.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 98%;
  margin: 0 auto;
}

.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

.v-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

.v-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #3d404c;
}

.vertical-timeline-content .btn {
  float: right;
}

.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}

.vertical-timeline-block:after {
  content: '';
  display: table;
  clear: both;
}

.vertical-timeline-block:first-child {
  margin-top: 0;
}

.vertical-timeline-block:last-child {
  margin-bottom: 0;
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 1px solid #3d404c;
  text-align: center;
  background: #2f323b;
  color: #ffffff;
}

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background-color: rgba(68, 70, 79, 0.5);
  border-radius: 0.25em;
  border: 1px solid #3d404c;
}

.vertical-timeline-content:after {
  content: '';
  display: table;
  clear: both;
}

.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}

.vertical-timeline-content p {
  margin: 1em 0 0 0;
  line-height: 1.6;
}

.vertical-timeline-content .vertical-date {
  font-weight: 500;
  text-align: right;
}

.vertical-date small {
  color: #ffffff;
  font-weight: 400;
}

.vertical-timeline-content:after,
.vertical-timeline-content:before {
  right: 100%;
  top: 20px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.vertical-timeline-content:after {
  border-color: transparent;
  border-right-color: #3d404c;
  border-width: 10px;
  margin-top: -10px;
}

.vertical-timeline-content:before {
  border-color: transparent;
  border-right-color: #3d404c;
  border-width: 11px;
  margin-top: -11px;
}

.vertical-timeline-content h2 {
  font-size: 16px;
}

/* Nestable list */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 10px 10px;
  color: #aaaaaa;
  text-decoration: none;
  border: 1px solid #3d404c;
  background-color: rgba(68, 70, 79, 0.5);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background-color: rgba(68, 70, 79, 0.8);
  cursor: pointer;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 10px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action='collapse']:before {
  content: '-';
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: transparent;
  border: 1px dashed #535868;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #3d404c;
  min-height: 100px;
  background-color: transparent;
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
* Nestable Extras
*/
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #e4e5e7;
  border-bottom: 2px solid #e4e5e7;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0;
}

#nestable-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial, sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable-output {
  font-size: 14px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.nav-tabs {
  border: none;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  background-color: rgba(68, 70, 79, 0.5);
  color: #ffffff;
  border-width: 0 0 1px 0;
}

.tab-pane .panel-body {
  border-top: none;
  background-color: rgba(68, 70, 79, 0.5);
  margin-bottom: 20px;
}

.nav-tabs > li a:hover {
  background: transparent;
  border-color: transparent;
}

.nav-tabs > li > a {
  color: #aaaaaa;
}

.tabs-below > .nav-tabs,
.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tabs-left .panel-body {
  position: static;
}

.tabs-left > .nav-tabs,
.tabs-right > .nav-tabs {
  width: 20%;
}

.tabs-left .panel-body {
  width: 80%;
  margin-left: 20%;
}

.tabs-right .panel-body {
  width: 80%;
  margin-right: 20%;
}

.tab-content > .tab-pane,
.pill-content > .pill-pane {
  display: none;
}

.tab-content > .active,
.pill-content > .active {
  display: block;
}

.tabs-below > .nav-tabs > li {
  margin-bottom: 0;
}

.tabs-below > .nav-tabs > li > a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
}

.tabs-left > .nav-tabs > li > a {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
}

.tabs-right > .nav-tabs > li > a {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 0px solid #3d404c;
}

.table > thead > tr > th {
  border-bottom: none;
  font-weight: normal;
  white-space: nowrap;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba(68, 70, 79, 0.5);
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #494b54;
}

.table-hover > tbody > tr:hover {
  background-color: #494b54;
}

.table-bordered,
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: #3d404c;
}

.panel-filled .table > thead > tr > th,
.panel-filled .table > tbody > tr > th,
.panel-filled .table > tfoot > tr > th,
.panel-filled .table > thead > tr > td,
.panel-filled .table > tbody > tr > td,
.panel-filled .table > tfoot > tr > td {
  border-color: #484c5a;
}

.panel-filled .table-bordered,
.panel-filled .table-bordered > thead > tr > th,
.panel-filled .table-bordered > tbody > tr > th,
.panel-filled .table-bordered > tfoot > tr > th,
.panel-filled .table-bordered > thead > tr > td,
.panel-filled .table-bordered > tbody > tr > td,
.panel-filled .table-bordered > tfoot > tr > td {
  border-color: #484c5a;
}

.panel-filled .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #3a3b41;
}

.panel-filled .table-hover > tbody > tr:hover {
  background-color: #494b54;
}

th {
  color: #ffffff;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: rgba(27, 191, 137, 0.2);
  border: none;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #18a979;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: rgba(86, 192, 224, 0.2);
  border: none;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #40b8dc;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: rgba(247, 175, 62, 0.2);
  border: none;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #f6a526;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: rgba(219, 82, 75, 0.2);
  border: none;
}

.table > thead > tr > td.numeric,
.table > tfoot > tr > td.numeric,
.table > thead > tr > th.numeric,
.table > tfoot > tr > th.numeric {
  text-align: center;
}

.table > tbody > tr > th.numeric,
.table > tbody > tr > td.numeric {
  text-align: right;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #d73e36;
}

div.dt-button-info {
  background-color: #21242a;
  border-radius: 6px;
  border: none;
}

div.dt-button-info h2 {
  background-color: transparent;
  border-color: #3d404c;
}

.table-responsive {
  border-color: #484c5a !important;
}

.alert-success {
  color: #ffffff;
  border-color: #1bbf89;
  background-color: #1bbf89;
}

.alert-success .alert-link {
  color: #1bbf89;
}

.alert-warning .alert-link {
  color: #f7af3e;
}

.alert-info {
  color: #ffffff;
  border-color: #56c0e0;
  background-color: #56c0e0;
}

.alert-info .alert-link {
  color: #56c0e0;
}

.alert-danger {
  color: #ffffff;
  border-color: #db524b;
  background-color: #db524b;
}

.alert-danger .alert-link {
  color: #db524b;
}

.toast-success {
  color: #ffffff;
  border-color: #1bbf89;
  background-color: #1bbf89;
}

.toast-warning {
  color: #ffffff;
  border-color: #f7af3e;
  background-color: #f7af3e;
}

.toast-info {
  color: #ffffff;
  border-color: #56c0e0;
  background-color: #56c0e0;
}

.toast-error {
  color: #ffffff;
  border-color: #db524b;
  background-color: #db524b;
}

#toast-container > div {
  opacity: 1;
  margin-top: 20px;
  border-radius: 4px;
  padding: 20px 20px 20px 50px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

#toast-container > div:hover {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
}

#toast-container.toast-top-right > div {
  margin-top: 60px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #2c2e34;
  opacity: 1;
}

.navbar-form .form-control {
  background-color: #ffffff;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: #159269;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: #f59b0d;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #cb3029;
}

.has-success .form-control {
  border-color: #159269;
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.has-warning .form-control {
  border-color: #f59b0d;
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.has-error .form-control {
  border-color: #cb3029;
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.support-question a {
  color: #aaaaaa;
}

.support-question a:hover {
  text-decoration: none;
}

.support-question a:focus {
  text-decoration: none;
}

.support-question:hover {
  background-color: rgba(68, 70, 79, 0.7);
}

.support-question.active {
  border-left: 3px solid #f6a821;
}

.support-question.active .panel-body {
  padding-left: 13px;
}

.jqstooltip {
  padding: 5px !important;
  border: none !important;
  border-radius: 10px;
  background-color: #f6a821 !important;
  width: auto !important;
  height: auto !important;
}

/* Charts */
.flot-chart {
  display: block;
  height: 300px;
  position: relative;
  color: #545454;
}

.flot-chart-analytics {
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  height: 150px;
  position: relative;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
  color: #545454;
}

.flot-text {
  color: #aaaaaa !important;
}

.hoverinfo {
  background-color: #f6a821 !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5) !important;
  border: none !important;
  border-radius: 4px !important;
  color: #ffffff !important;
  padding: 6px 12px !important;
}

.btn-solid-primary {
  color: #292d33;
  background-color: #f6a820;
  border-color: #f6a820;
}

.btn-solid-primary:focus,
.btn-solid-primary.focus {
  color: #ffffff;
  background-color: rgba(246, 168, 32, 0.1);
  border-color: #f6a821;
  outline: 0;
  box-shadow: none;
}

.btn-solid-primary:active,
.btn-solid-primary.active,
.open > .dropdown-toggle.btn-solid-primary {
  color: #ffffff;
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f6a821;
}

.btn-solid-primary:active:hover,
.btn-solid-primary.active:hover,
.open > .dropdown-toggle.btn-solid-primary:hover,
.btn-solid-primary:active:focus,
.btn-solid-primary.active:focus,
.open > .dropdown-toggle.btn-solid-primary:focus,
.btn-solid-primary:active.focus,
.btn-solid-primary.active.focus,
.open > .dropdown-toggle.btn-solid-primary.focus {
  color: #ffffff;
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f8bb52;
  outline: 0;
  box-shadow: none;
}

.btn-solid-primary:active,
.btn-solid-primary.active,
.open > .dropdown-toggle.btn-solid-primary {
  background-image: none;
}

.btn-solid-primary.disabled:hover,
.btn-solid-primary[disabled]:hover,
fieldset[disabled] .btn-solid-primary:hover,
.btn-solid-primary.disabled:focus,
.btn-solid-primary[disabled]:focus,
fieldset[disabled] .btn-solid-primary:focus,
.btn-solid-primary.disabled.focus,
.btn-solid-primary[disabled].focus,
fieldset[disabled] .btn-solid-primary.focus {
  background-color: rgba(246, 168, 33, 0.1);
  border-color: #f6a821;
}

.btn-solid-primary .badge {
  color: transparent;
  background-color: #aaaaaa;
}

/*

1.2. btn-solid-success

*/

.btn-solid-success {
  color: #ffffff;
  background-color: #1bbf89;
  border-color: #1bbf89;
}

.btn-solid-success:focus,
.btn-solid-success.focus {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
  outline: 0;
  box-shadow: none;
}

.btn-solid-success:hover {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
}

.btn-solid-success:active,
.btn-solid-success.active,
.open > .dropdown-toggle.btn-solid-success {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
}

.btn-solid-success:active:hover,
.btn-solid-success.active:hover,
.open > .dropdown-toggle.btn-solid-success:hover,
.btn-solid-success:active:focus,
.btn-solid-success.active:focus,
.open > .dropdown-toggle.btn-solid-success:focus,
.btn-solid-success:active.focus,
.btn-solid-success.active.focus,
.open > .dropdown-toggle.btn-solid-success.focus {
  color: #ffffff;
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #2ce1a5;
  outline: 0;
  box-shadow: none;
}

.btn-solid-success:active,
.btn-solid-success.active,
.open > .dropdown-toggle.btn-solid-success {
  background-image: none;
}

.btn-solid-success.disabled:hover,
.btn-solid-success[disabled]:hover,
fieldset[disabled] .btn-solid-success:hover,
.btn-solid-success.disabled:focus,
.btn-solid-success[disabled]:focus,
fieldset[disabled] .btn-solid-success:focus,
.btn-solid-success.disabled.focus,
.btn-solid-success[disabled].focus,
fieldset[disabled] .btn-solid-success.focus {
  background-color: rgba(27, 191, 137, 0.1);
  border-color: #1bbf89;
}

.btn-solid-success .badge {
  color: transparent;
  background-color: #aaaaaa;
}

/*

1.3. btn-solid-info

*/

.btn-solid-info {
  color: #ffffff;
  background-color: #56c0e0;
  border-color: #56c0e0;
}

.btn-solid-info:focus,
.btn-solid-info.focus {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
  outline: 0;
  box-shadow: none;
}

.btn-solid-info:hover {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
}

.btn-solid-info:active,
.btn-solid-info.active,
.open > .dropdown-toggle.btn-solid-info {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
}

.btn-solid-info:active:hover,
.btn-solid-info.active:hover,
.open > .dropdown-toggle.btn-solid-info:hover,
.btn-solid-info:active:focus,
.btn-solid-info.active:focus,
.open > .dropdown-toggle.btn-solid-info:focus,
.btn-solid-info:active.focus,
.btn-solid-info.active.focus,
.open > .dropdown-toggle.btn-solid-info.focus {
  color: #ffffff;
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #81d0e8;
  outline: 0;
  box-shadow: none;
}

.btn-solid-info:active,
.btn-solid-info.active,
.open > .dropdown-toggle.btn-solid-info {
  background-image: none;
}

.btn-solid-info.disabled:hover,
.btn-solid-info[disabled]:hover,
fieldset[disabled] .btn-solid-info:hover,
.btn-solid-info.disabled:focus,
.btn-solid-info[disabled]:focus,
fieldset[disabled] .btn-solid-info:focus,
.btn-solid-info.disabled.focus,
.btn-solid-info[disabled].focus,
fieldset[disabled] .btn-solid-info.focus {
  background-color: rgba(86, 192, 224, 0.1);
  border-color: #56c0e0;
}

.btn-solid-info .badge {
  color: transparent;
  background-color: #aaaaaa;
}

/*

1.4. btn-solid-warning

*/

.btn-solid-warning {
  color: #ffffff;
  background-color: #f7af3e;
  border-color: #f7af3e;
}

.btn-solid-warning:focus,
.btn-solid-warning.focus {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
  outline: 0;
  box-shadow: none;
}

.btn-solid-warning:hover {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
}

.btn-solid-warning:active,
.btn-solid-warning.active,
.open > .dropdown-toggle.btn-solid-warning {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
}

.btn-solid-warning:active:hover,
.btn-solid-warning.active:hover,
.open > .dropdown-toggle.btn-solid-warning:hover,
.btn-solid-warning:active:focus,
.btn-solid-warning.active:focus,
.open > .dropdown-toggle.btn-solid-warning:focus,
.btn-solid-warning:active.focus,
.btn-solid-warning.active.focus,
.open > .dropdown-toggle.btn-solid-warning.focus {
  color: #ffffff;
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f9c36f;
  outline: 0;
  box-shadow: none;
}

.btn-solid-warning:active,
.btn-solid-warning.active,
.open > .dropdown-toggle.btn-solid-warning {
  background-image: none;
}

.btn-solid-warning.disabled:hover,
.btn-solid-warning[disabled]:hover,
fieldset[disabled] .btn-solid-warning:hover,
.btn-solid-warning.disabled:focus,
.btn-solid-warning[disabled]:focus,
fieldset[disabled] .btn-solid-warning:focus,
.btn-solid-warning.disabled.focus,
.btn-solid-warning[disabled].focus,
fieldset[disabled] .btn-solid-warning.focus {
  background-color: rgba(247, 175, 62, 0.1);
  border-color: #f7af3e;
}

.btn-solid-warning .badge {
  color: transparent;
  background-color: #aaaaaa;
}

/*

1.5. btn-solid-danger

*/

.btn-solid-danger {
  color: #ffffff;
  background-color: #db524b;
  border-color: #db524b;
}

.btn-solid-danger:focus,
.btn-solid-danger.focus {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
  outline: 0;
  box-shadow: none;
}

.btn-solid-danger:hover {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
}

.btn-solid-danger:active,
.btn-solid-danger.active,
.open > .dropdown-toggle.btn-solid-danger {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
}

.btn-solid-danger:active:hover,
.btn-solid-danger.active:hover,
.open > .dropdown-toggle.btn-solid-danger:hover,
.btn-solid-danger:active:focus,
.btn-solid-danger.active:focus,
.open > .dropdown-toggle.btn-solid-danger:focus,
.btn-solid-danger:active.focus,
.btn-solid-danger.active.focus,
.open > .dropdown-toggle.btn-solid-danger.focus {
  color: #ffffff;
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #e47b75;
  outline: 0;
  box-shadow: none;
}

.btn-solid-danger:active,
.btn-solid-danger.active,
.open > .dropdown-toggle.btn-solid-danger {
  background-image: none;
}

.btn-solid-danger.disabled:hover,
.btn-solid-danger[disabled]:hover,
fieldset[disabled] .btn-solid-danger:hover,
.btn-solid-danger.disabled:focus,
.btn-solid-danger[disabled]:focus,
fieldset[disabled] .btn-solid-danger:focus,
.btn-solid-danger.disabled.focus,
.btn-solid-danger[disabled].focus,
fieldset[disabled] .btn-solid-danger.focus {
  background-color: rgba(219, 82, 75, 0.1);
  border-color: #db524b;
}

.btn-solid-danger .badge {
  color: transparent;
  background-color: #aaaaaa;
}

/*

1.6. btn-block-login

*/

.btn-block-login {
  display: block;
  width: 100%;
  margin-top: 15px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: bold;
}

input[type='submit'].btn-block-login,
input[type='reset'].btn-block-login,
input[type='button'].btn-block-login {
  width: 100%;
}

.validation-error-text {
  color: #ff3e3d;
}

.text-center {
  text-align: center;
}

/* Our color palette */

.base-black {
  background-color: #2a2d33;
  border: 1px solid #ffffff;
}

.base-black-color {
  color: #2a2d33;
}

.very-dark-grey {
  background-color: #33353c;
}

.very-dark-grey-color {
  color: #33353c;
}

.dark-grey {
  background-color: #3a3b41;
}

.dark-grey-color {
  color: #3a3b41;
}

.mid-grey {
  background-color: #434449;
}

.mid-grey-color {
  color: #434449;
}

.grey {
  background-color: #808080;
}

.grey-color {
  color: #808080;
}

.light-grey {
  background-color: #aaaaaa;
}

.light-grey-color {
  color: #aaaaaa;
}

.light-orange {
  background-color: #f6a821;
}

.light-primary-color {
  color: #f6a821;
}

.white {
  background-color: #fff;
}

.white-color {
  color: #fff;
}

/* Pie Charts */
.chart-orange1 {
  background-color: #ffefa2;
}

.chart-orange2 {
  background-color: #ffd170;
}

.chart-orange3 {
  background-color: #e09f3e;
}

.chart-orange4 {
  background-color: #f6a821;
}

.chart-orange5 {
  background-color: #b88619;
}

.chart-orange6 {
  background-color: #a16a0e;
}

.chart-orange7 {
  background-color: #8f592d;
}

/* Material */

.mdl-textfield {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  font-size: 110%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 20px 0;
  font-weight: lighter;
}

.mdl-textfield__input {
  border: none;
  border-bottom: 1px solid #b3b3b3;
  display: block;
  font-size: 110%;
  margin: 0;
  padding: 4px 0;
  width: 100%;
  background: none;
  text-align: left;
  border-radius: 0px;
  color: #30333a;
}

.mdl-textfield__input[type='number'] {
  -moz-appearance: textfield;
}

.mdl-textfield__input[type='number']::-webkit-inner-spin-button,
.mdl-textfield__input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mdl-textfield.is-focused .mdl-textfield__input {
  outline: none;
}

.mdl-textfield.is-invalid .mdl-textfield__input {
  border-color: #ff3e3d;
  box-shadow: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__input,
.mdl-textfield.is-disabled .mdl-textfield__input {
  background-color: transparent;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield__label {
  bottom: 0;
  color: rgba(0, 0, 0, 0.26);
  font-size: 16px;
  left: 0;
  right: 0;
  pointer-events: none;
  position: absolute;
  display: block;
  top: 24px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}
.mdl-textfield.is-dirty .mdl-textfield__label,
.mdl-textfield.has-placeholder .mdl-textfield__label {
  visibility: hidden;
}
.mdl-textfield--floating-label .mdl-textfield__label {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  transition: none;
}

fieldset[disabled] .mdl-textfield .mdl-textfield__label,
.mdl-textfield.is-disabled.is-disabled .mdl-textfield__label {
  color: rgba(0, 0, 0, 0.26);
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  color: #f6a821;
  font-size: 12px;
  top: 4px;
  visibility: visible;
}
.mdl-textfield--floating-label.is-focused .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__expandable-holder .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__expandable-holder .mdl-textfield__label {
  top: -16px;
}
.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #ff3e3d;
}
.mdl-textfield__label:after {
  bottom: 15px;
  content: '';
  height: 2px;
  left: 45%;
  position: absolute;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  visibility: hidden;
  width: 10px;
}
.mdl-textfield.is-focused .mdl-textfield__label:after {
  background-color: #f6a821;
  left: 0;
  visibility: visible;
  width: 100%;
}
.mdl-textfield.is-invalid .mdl-textfield__label:after {
  background-color: #ff3e3d;
}

.mdl-textfield--floating-label.is-focused .mdl-textfield__label,
.mdl-textfield--floating-label.is-dirty .mdl-textfield__label,
.mdl-textfield--floating-label.has-placeholder .mdl-textfield__label {
  color: #f6a821;
}

.mdl-textfield--floating-label.is-invalid .mdl-textfield__label {
  color: #ff3e3d;
}

select.mdl-textfield__input:focus {
  outline: 0;
}

.mdl-textfield__error {
  color: #ff3e3d;
  position: absolute;
  font-size: 12px;
  margin-top: 3px;
  visibility: hidden;
  display: block;
}

.mdl-textfield.is-invalid .mdl-textfield__error,
*.is-invalid .mdl-textfield__error {
  visibility: visible;
}

.uppercase {
  text-transform: uppercase;
}
