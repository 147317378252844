@import '../../theme/helpers/all';

$border-radius-box: 10px;
$border-right-radius: 0 4px 4px 0;

%border-bottom-radius {
  border: 1px solid $mid-grey;
  border-radius: 0 0 $border-radius-box $border-radius-box;
}

.offer-container {
  padding: 3rem;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  .tenors-grid {
    min-height: 60vh;
  }

  .header {
    padding-right: 0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding-bottom: $normal;

    .summary-highlight p ~ div {
      color: var(--primary-text-color);
      font-weight: 500;
    }

    &.is-loading .summary-highlight p ~ div {
      @include includeLoadingSkeleton();
    }
  }

  .offer-tenors {
    margin-right: $large;
  }

  .select-tenor {
    .is-loading {
      @include includeLoadingSkeleton();
    }

    > :first-child {
      padding: 3rem 0;
      align-items: center;

      > span:first-of-type {
        font-size: 3rem;
        color: var(--primary-text-color);
      }

      .updated-at {
        width: 150px;
        display: inline-block;
      }
    }
  }

  .offer-actions {
    .grey-box {
      margin-top: $small;
      margin-bottom: $normal;
      border: 1px solid;
      background-color: $grey-10;
      border-radius: 5px;
      font-size: 2.5rem;
      text-align: center;
      height: 6rem;
      display: flex;
      align-items: center;
    }

    width: 350px;
    border-radius: $border-radius-box;
    color: $dark-grey-color;

    button {
      color: $dark-grey-color;
      padding: $small;

      &:hover {
        color: $dark-grey-color;
      }
    }

    > :first-child.flex {
      border-radius: $border-radius-box $border-radius-box 0 0;
      padding-bottom: $small;
    }

    > div.flex {
      &:not(.button-row) {
        padding: $normal;
        background-color: var(--background-light-color);

        &:first-child {
          background-color: var(--paper-color);
          color: var(--paper-contrast-color);
        }
      }

      > div {
        margin-bottom: $tiny;
      }

      .amount,
      .option-selected {
        font-size: 3.5rem;
        font-weight: bold;

        &.has-error {
          color: $error;
        }
      }

      .required {
        color: $error;
      }

      .Select {
        $text-color: $white;
        $background-grey: $grey;
        cursor: pointer;
        margin-top: $small;
        margin-bottom: $tiny;

        .Select-control {
          background-color: $grey;
          color: $text-color;
          border-color: $grey;

          .Select-input:focus {
            background-color: $background-grey;
          }

          .Select-placeholder {
            color: $text-color;
          }

          .Select-arrow {
            border-color: $text-color transparent transparent;
          }
        }

        &.is-focused.is-open {
          > .Select-control,
          .Select-input:focus {
            background-color: $mid-grey;
          }
        }

        &.is-open {
          > .Select-control {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-color: $background-grey;
          }

          .Select-arrow {
            border-color: transparent transparent $text-color;
          }
        }

        &.has-value > .Select-control .Select-value .Select-value-label {
          color: $text-color;
        }
      }

      .accrued {
        font-weight: 500;

        + div {
          min-height: 6.5rem;
        }

        span {
          &:first-of-type {
            padding-right: $tiny;
          }

          &:last-of-type {
            font-weight: bold;
          }
        }

        .toggle-button {
          width: 90px;
          padding: 0 $tiny;
          border-radius: 20px;

          &:not(.is-toggled) {
            background-color: $grey;
          }

          &.is-toggled {
            background-color: var(--primary-color);

            .toggle-caret {
              left: 68px;
            }

            span {
              color: $dark-grey-color;
            }
          }

          span {
            color: var(--primary-text-color);
          }

          .toggle-caret {
            background-color: var(--primary-text-color);
          }
        }
      }

      &.button-row {
        cursor: pointer;
        align-items: center;
        flex-wrap: wrap;

        > button {
          &:focus {
            outline: none;
          }

          border-radius: 0;
          width: 100%;
          cursor: pointer;
          font-size: 2rem;
          border: none;
          background-color: var(--background-light-color);
          @include transition(background-color 0.4s);

          &:hover {
            background-color: $grey-20;
          }

          span {
            margin-left: $tiny;
          }
        }

        .add-or-remove-funds-button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: $normal;
        }
      }

      &.rejected-offer {
        @extend %border-bottom-radius;
        border-color: var(--primary-text-color);
      }

      .upper-case span {
        font-weight: bold;
        font-size: 1.6rem;
      }

      &.selected-offer {
        cursor: default;

        .rate {
          @extend .grey-box;
          color: $grey-60;
          padding-bottom: 0;
          padding-left: $small;
          border-color: $grey-40;
          min-height: 62px;
          cursor: pointer;
          @include transition(background-color 0.4s);

          > * {
            flex: 1;
          }

          .maturity-date > span {
            font-size: 1.2rem;
            color: $grey;
            text-align: left;
          }

          .edit {
            padding: 18px $small;
            background-color: $grey;
            color: var(--background-light-color);
            border-radius: $border-right-radius;
            @include transition(background-color 0.4s);
          }

          &.selected {
            color: $dark-grey-color;
            background-color: var(--background-light-color);

            .maturity-date {
              &.has-custom-rate > div span:last-of-type,
              &.has-custom-maturity-date > span:last-of-type {
                color: var(--primary-color);
                font-weight: bold;
              }
            }

            &:hover {
              background: $grey-10;

              .edit {
                background-color: var(--primary-color-hover);
              }
            }
          }

          .percentage {
            border-left: 1px solid $grey-40;
            border-radius: 0;
            height: 6rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &.additional-funds {
        padding: 30px $normal $tiny;
        background-color: var(--background-light-color);

        .fa-stack {
          cursor: pointer;

          .fa-circle {
            color: $grey-40;
          }

          .fa-times {
            color: $mid-grey;
          }
        }

        input {
          @extend .grey-box;
          color: $dark-grey-color;
          padding: $tiny;
          text-align: left;
          border-color: $grey-40;

          &.has-error {
            color: $error;
          }
        }

        .currency-input {
          margin-bottom: $tiny;

          &:not(.has-error) {
            margin-bottom: 25px;
          }

          &::placeholder {
            color: $grey-60;
          }
        }

        .currency-input-error-message {
          span {
            color: $error;
            font-weight: unset;
          }
        }
      }

      &.confirm-reinvestment {
        @extend %border-bottom-radius;
        border: 2px solid var(--background-light-color);
        padding: $normal;

        > button {
          background-color: $grey-40;
          border-radius: $tiny;
          border-color: transparent;
          color: $dark-grey-color;
          cursor: not-allowed;
          font-size: 2rem;
          flex: 1;

          &:hover {
            background-color: $grey-40;
          }
        }
      }

      &.extra-actions-row {
        gap: $normal;
        margin-top: $normal;
        padding: 0 $normal;

        button.extra-action {
          background-color: transparent;
          border-radius: $tiny;
          border: 2px solid var(--primary-text-color);
          color: var(--primary-text-color);
          font-size: 1.6rem;
          flex: 1 1 12rem;
          @include transition(all 0.4s);

          &:last-child {
            flex-basis: 20rem;
          }

          &:hover {
            background-color: transparent;
            color: var(--primary-color);
            border-color: var(--primary-color);
            text-decoration: none;
          }
        }
      }
    }

    &.active > div.flex {
      &.confirm-reinvestment > button {
        border: 2px solid var(--primary-color);
        background-color: var(--primary-color);
        color: var(--paper-contrast-color);
        cursor: pointer;
        pointer-events: initial;
        @include transition(background-color 0.4s);

        &:hover {
          color: var(--paper-contrast-color);
          border-color: var(--primary-color);
          background-color: var(--primary-color-hover);
        }

        &:disabled,
        &[disabled] {
          cursor: not-allowed;
          border-color: $grey-40;
          background-color: $grey-40;
          color: $mid-grey;
        }
      }
    }

    &.rejected > div.flex {
      .accrued + div.flex {
        display: none;
      }

      &.rejected-offer {
        display: inherit;
      }

      &.selected-offer,
      &.additional-funds {
        display: none;
      }
    }

    &.cp,
    &.cd {
      .amount {
        margin-bottom: $normal;
      }
    }

    hr {
      margin: 0;
      border-top-width: 1px;
      border-top-color: transparent;
    }
  }

  .toggle-button-group {
    padding-top: $small;

    &.btn-group > .btn {
      background-color: transparent;
      color: inherit;
      border: 1px solid $grey-40;
      height: 35px;

      &:last-child {
        border-left: 1px solid transparent;
      }

      &:first-child {
        border-right: 1px solid transparent;
      }

      &.active {
        background-color: $grey;
        border: 1px solid $grey;
        color: $white;
      }

      &.btn-default {
        span {
          font-weight: unset;
        }
      }
    }
  }

  &.closed,
  &.reinvested,
  &.reinvested-on-behalf,
  &.accepted,
  &.rejected,
  &.cancelled,
  &.has-error {
    pointer-events: none;

    .tenors-grid .tenor:not(.winner) {
      background-color: transparent;
    }

    .offer-actions {
      > div.flex.selected-offer {
        border: none;
        @extend %border-bottom-radius;

        ~ div:not(.additional-funds),
        ~ div i {
          display: none;
        }

        .rate {
          background-color: $grey-10;
          color: $mid-grey;
        }

        .rate .percentage {
          border-color: $grey-40;
        }

        .Select-control {
          background-color: $grey-10;
          border-color: $grey-40;

          .Select-value > .Select-value-label {
            color: $mid-grey;
          }

          .Select-arrow {
            display: none;
          }
        }
      }

      > div.flex.rejected-offer {
        .option-selected {
          font-size: 3rem;
          padding-bottom: $small;
        }
      }

      .accrued .toggle-button {
        display: none;
      }

      &.has-additional-funds {
        div.flex.selected-offer {
          border-radius: 0;
        }

        .btn-group {
          display: none;
        }

        .additional-funds {
          border: none;
          @extend %border-bottom-radius;

          input {
            color: $mid-grey;
          }
        }
      }
    }
  }

  &.investor-offer-container {
    color: var(--primary-text-color);

    .tenors-grid .tenor.is-dirty {
      border: 4px solid var(--background-dark-color);
    }
  }
}

.tenor-maturity-date-modal .modal-dialog {
  min-width: 250px;

  .modal-header,
  .modal-body {
    padding: 20px;
  }
}

@media (max-width: $large-desktop-width) {
  .offer-container {
    .offer-actions {
      > div.flex {
        &:not(.button-row) {
          padding: 20px;
        }
      }
    }
  }
}

@media (max-width: $small-desktop-width) {
  .offer-container {
    .select-tenor {
      > :first-child {
        padding: 2.5rem 0;
      }
    }

    .offer-actions {
      width: 315px;

      > div.flex {
        div {
          margin-bottom: 0;
        }

        &:not(.button-row) {
          padding: $small;

          .rate {
            margin: 0;
            margin-bottom: $tiny;
          }
        }

        .accrued {
          margin-bottom: 0;
        }

        .Select {
          margin-top: 0;
        }

        &.button-row {
          .add-or-remove-funds-button {
            padding: $normal;
          }
        }

        &.additional-funds {
          input {
            height: $large;
          }
        }

        &.confirm-reinvestment {
          padding: $small;
        }

        &.extra-action {
          padding: $tiny $small;

          > button {
            margin-top: $small;
          }
        }
      }
    }
  }
}

@media (max-width: $tiny-desktop-width) {
  .upper-case span {
    font-size: 1rem;
  }

  .offer-container {
    padding: 2rem;

    .select-tenor {
      > :first-child {
        padding: 1.5rem 0;
      }
    }

    .offer-actions {
      width: 275px;

      button {
        padding: $tiny;
        font-size: 1rem;

        span {
          font-size: 1.5rem;
        }
      }

      > div.flex {
        .amount {
          font-size: 3rem;
        }

        .accrued {
          font-size: 1.25rem;
        }

        &:not(.button-row) {
          .rate {
            margin: 0;
            margin-bottom: $tiny;
            height: 40px;
            min-height: 40px;

            .calendar {
              height: 40px;
              border-radius: $border-right-radius;
            }

            .maturity-date {
              font-size: 1.35rem;

              > span {
                font-size: 1rem;
              }
            }
          }
        }

        .accrued {
          margin-bottom: 0;
        }

        &.button-row {
          .add-or-remove-funds-button {
            padding: $small;
          }
        }

        &.additional-funds {
          label {
            font-size: 1.25rem;
          }
        }

        &.confirm-reinvestment {
          padding-top: $small;
        }
      }
    }
  }
}
