@import '../../../theme/helpers/guideline';

.tab-container {
  .light-tabs .tab-content {
    padding: $common-padding 0 0 0;
  }

  .summary-section {
    > .flex {
      flex: 1;
    }

    height: 130px;
    margin-bottom: 20px;

    .summary-content:first-of-type:not(.highlight) {
      padding-left: 0;
    }
  }

  .new-rfq-button {
    height: $large;
    width: $huge;
  }
}
