@import '../../theme/helpers/all';

.results-filter {
  padding-bottom: $normal;

  .reset-filter-button {
    margin-left: $small;
    height: $input-group-height;
  }

  .input-search.form-group,
  .reset-filter-button {
    margin-top: 30px;
  }

  .select-filter-container {
    border: 0;

    .select-filter {
      p.grey-color.text-ellipsis {
        margin-bottom: $tiny;
      }
    }
  }

  .counter-results {
    padding: $normal 0;
    margin-top: $tiny;
  }
}
