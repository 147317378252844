@import '../../../../theme/helpers/all';

.history-data {
  height: 100%;

  &-header {
    height: 50px;
    gap: $tiny;
    align-items: center;
    padding: 0 $normal;
    border-top: 1px solid $grey-400;
    border-bottom: 1px solid $grey-400;

    > span {
      font-weight: bold;
      color: $base-black;
      text-transform: uppercase;
      opacity: 0.5;

      &:first-child {
        opacity: 1;
      }
    }

    button.close {
      @extend %rounded-button;
      background-color: transparent;
      color: var(--background-dark-color);
      opacity: 1;
      margin-left: $small;
      border-radius: $border-radius;

      &:hover {
        background-color: var(--paper-color);
      }
    }
  }

  > .react-display-flex-column {
    flex: 1;
  }

  .rc-table {
    padding: $small;

    thead tr th {
      background-color: $white;
      font-size: 1.2rem;
    }

    tbody tr td {
      font-size: 1.1rem;

      .won {
        border: 1px solid $middle-green;
        padding: 2px $tiny;
        border-radius: 3px;
      }

      .custom-winning-tenor {
        gap: $tiny;
      }
    }
  }
}
