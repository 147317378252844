@import '../../theme/helpers/guideline.scss';
@import '../../theme/helpers/palette.scss';

$box-min-width: 800px;
$box-max-width: $box-min-width;
$box-min-height: 140px;

.capital-value-container {
  .grey-box {
    min-width: $box-min-width;
    max-width: $box-max-width;
    min-height: $box-min-height;
    padding: $small;
    padding-left: $normal;
    padding-right: $normal;
    position: relative;

    .close-button {
      position: absolute;
      top: $small;
      right: $small;
      color: $white;
      cursor: pointer;
      font-size: 20px;
    }
  }

  .btn-solid-primary {
    margin-bottom: 4px;
  }

  .add-button-wrapper {
    margin-top: $normal;

    .btn-add-another {
      min-width: 600px;
    }

    .btn-download-reports,
    .loader {
      height: 40px;
    }
  }

  h1 {
    margin-top: $large;
    margin-bottom: $normal;
  }
}
