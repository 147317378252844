@import '../../../../../../theme/helpers/palette.scss';
@import '../../../../../../theme/helpers/guideline.scss';

.drawer.unsolicited-ratesheet-details {
  .unsolicited-ratesheet-details-content {
    > h3 {
      margin: 16px 16px;
    }
  }

  .rc-collapse {
    .results-presenter {
      margin: 0;
    }
  }

  .lost-trades {
    .rc-collapse-content-box > div {
      overflow-x: auto;
    }
  }

  h3 {
    flex: 1;
  }

  .alert {
    margin-bottom: $normal;

    svg {
      margin-right: $tiny;
    }
  }

  .update-ratesheet-button {
    margin-bottom: $normal;
  }

  .tenors-grid {
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 11rem);

    .tenor {
      max-width: 11rem;
    }
  }

  .rc-collapse-header dl {
    grid-template-columns: 4rem repeat(4, minmax(10rem, 1fr));
  }

  dl {
    padding: 0;
    border-bottom: none;
  }

  h4 {
    margin: $normal 0;
    color: $grey-700;
  }
}
