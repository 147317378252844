@import '@tame-your-theme/scss';

.popover {
  @include setDarkWhiteTheme(dark);
  background: var(--dark-white-background-color);

  &-title {
    background: var(--dark-white-background-color);
    border-color: $modal-border-color;
    color: var(--dark-white-background-contrast-color);

    white-space: pre-line;
  }

  &-content {
    background: var(--dark-white-background-color);
  }

  &.bottom > .arrow:after {
    border-bottom-color: var(--dark-white-background-color);
  }

  &.top > .arrow:after {
    border-top-color: var(--dark-white-background-color);
  }

  &.left > .arrow:after {
    border-left-color: var(--dark-white-background-color);
  }

  .confirmation-popover {
    gap: $small;

    button {
      flex: 1;
      min-width: 10rem;
    }

    &.is-confirming {
      pointer-events: none;
    }
  }

  &.light {
    h4 {
      color: var(--dark-white-background-contrast-color);
    }

    @include setDarkWhiteTheme(white);

    .popover-title {
      border-color: var(--grey-300);
    }

    input.form-control,
    .form-group:not(.has-error) .form-control {
      background-color: $grey-100;
      color: var(--dark-white-background-contrast-color);

      &::selection {
        background-color: darken-theme-color(--dark-white-background-color, 20%);
      }
    }
  }
}
