@import '../../theme/helpers/all';

.update-ratesheet-presenter {
  button {
    margin-left: $small;
  }

  .panel-filled {
    border-radius: $border-radius;

    .records-loading {
      margin-bottom: $panel-margin-bottom;
    }
  }

  .results {
    padding: $normal;
    border-radius: $border-radius;
  }

  .no-records-message {
    margin-bottom: $panel-margin-bottom;
  }

  h4 {
    color: $white;

    &.result-title {
      display: inline-block;
      padding-bottom: 20px;
      font-size: 150%;
    }
  }

  .header-options {
    display: flex;
    justify-content: space-between;
  }

  /* Every even row */
  .ht_master tr > td {
    background-color: $very-dark-grey;
  }

  .handsontable th {
    border: 0;
  }

  .ht_clone_top th {
    border: 0;
  }

  .result-title {
    margin-bottom: 40px;
  }

  .table-header {
    padding-bottom: 20px;
    font-size: 150%;
  }

  i {
    margin-right: $small;
    font-size: 150%;
    color: var(--primary-color);
    cursor: pointer;
  }

  .handson-table-container {
    #hot {
      background-color: $very-dark-grey;
    }
  }

  .htSelectEditor {
    background-color: $very-dark-grey;
  }
}
