@import '@tame-your-theme/scss';

@import '../../theme/helpers/all';
@import '../../theme/components/common';

$highlight-border-bottom-width: 3px;
$tab-width: 170px;
$num-of-tabs: 7;

.slide-tabs {
  @include create-theme-color(--tab-background-color, $very-dark-grey);

  font-size: 1.2rem;
  margin: 20px 30px 0 30px;

  .nav-tabs {
    background-color: var(--tab-background-color);
    border-radius: $border-radius;
    padding-right: 15px;

    & > li:first-child > a {
      padding-left: 30px;
    }

    & > li {
      width: $tab-width;

      &.pull-right {
        width: 140px;
      }

      &:focus {
        outline: none;
      }

      text-align: center;
      div {
        display: inline-block;
      }

      @include tabs($num-of-tabs, $tab-width);

      &:first-of-type {
        &.active {
          ~ .slide {
            left: 0;
          }
        }
      }

      ~ .slide {
        background: $light-orange;
        width: $tab-width;
        height: 3px;
        position: relative;
        top: 50px;
        transition: left 0.3s ease-out;
      }

      & > a {
        border: 0;
        margin: 0;
        padding-right: 20px;
        padding-left: 20px;
        line-height: 30px;
        border-bottom-width: $highlight-border-bottom-width;

        span {
          margin: 10px;

          &.success {
            color: $success;
          }
          &.fail {
            color: $error;
          }
          &.neutral {
            color: $light-orange;
          }
        }
      }
    }

    :not(.secondary &) {
      & > li {
        &:hover,
        &.active,
        a:focus {
          background-color: lighten-theme-color(--tab-background-color, 5%);
        }
      }
    }

    & > li {
      &.active {
        > a {
          border-bottom: 1px solid transparent;
          background-color: transparent;
        }
      }
    }

    & > li:first-child.active {
      border-bottom-left-radius: $border-radius;
    }

    .reset-filter-button {
      margin-top: 10px;

      span:first-child {
        padding-right: 10px;
      }
    }

    .item-container .blob {
      margin: 0;
    }
  }

  $icon-size: 22px;

  .loader {
    vertical-align: middle;
    line-height: 15px;
    width: $icon-size;
    height: $icon-size;
  }

  .fa-exclamation-triangle {
    font-size: 150%;
    color: $error;
  }
}
