@import '~loaders.css/src/loaders';

@import '../helpers/guideline.scss';

.loader-sm {
  .loader-inner.ball-pulse {
    > div {
      width: $tiny;
      height: $tiny;
    }
  }

  .ball-clip-rotate {
    height: 20px;
    width: 25px;

    > div {
      height: 15px;
      width: 15px;
    }
  }

  .loader-inner.ball-scale-ripple {
    > div {
      height: 15px;
      width: 15px;
    }
  }
}
