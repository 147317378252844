$currentDirectory: "./";

@font-face {
  font-family: "Proxima-Nova";
  src: url($currentDirectory + "ProximaNova-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url($currentDirectory + "ProximaNova-Semibold.woff") format("woff"),
    url($currentDirectory + "ProximaNova-Semibold.ttf") format("truetype"),
    url($currentDirectory + "ProximaNova-Semibold.svg#ProximaNova-Semibold")
      format("svg");
  font-weight: 600;
  font-style: normal;
}
