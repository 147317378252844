@import '../../../theme/helpers/palette.scss';
@import '../../../theme/helpers/guideline.scss';

$horizontal-padding: 2px;
$pill-radius: 4px;

.outgoing-quotes-row {
  &.your-adi-row {
    color: $white;
  }

  .centralize-text {
    text-align: center;
  }

  .accepted-quote {
    color: $white;
    background-color: $success;
    padding: $horizontal-padding $small;
    border-radius: $pill-radius;
    margin-left: 0;
  }

  .best-coupon {
    border: 1px solid $best-quote;
    padding: $horizontal-padding $small;
    border-radius: $pill-radius;
    margin-left: 0;
  }
}
