@import '../../theme/helpers/all';

.records {
  padding: 0;

  .is-compliant {
    text-align: center;
    width: 100px;
  }

  .green {
    color: $success;
  }

  .red {
    color: $error;
  }

  .positive-number {
    padding-left: 12px;
  }

  th i {
    padding-right: 5px;
  }

  .column-link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      color: var(--primary-color);

      .sort-icon {
        color: var(--primary-color);
      }

      .sort-icon-inactive {
        opacity: 0.5;
        color: var(--primary-color);
      }
    }
  }

  .selected {
    font-weight: bolder;
    color: var(--primary-color);

    .sort-icon {
      color: var(--primary-color);
    }
  }

  .table-responsive {
    border: 0;
  }

  td.action {
    width: 80px;
  }

  .sort-icon {
    margin-right: 4px;
    transition: all 0.2s ease;
  }

  .sort-icon-inactive {
    opacity: 0.1;
  }
}
