@import '../../../theme/helpers/all';

.blotter-rfq-container {
  .summary {
    display: flex;
    flex: 1;
    margin-bottom: $normal;

    .summary-content {
      flex-basis: 25%;
      padding: $small 0;
    }
  }

  .filters {
    .select-filter {
      min-height: 86px;
    }
  }

  .download-button {
    width: 8em;
  }

  .rfqs-history-panels {
    .no-data {
      padding: 0;
      text-align: center;
      padding-bottom: 20px;
    }
  }

  .panel.panel-filled {
    margin: 0;
  }

  .toolbar-container {
    display: flex;
    align-items: center;

    button.toolbar-button {
      margin-top: 10px;
    }

    .filters {
      gap: $small;

      .imperium-select-container {
        width: 12rem;
      }
    }

    .toolbar-item {
      width: 11em;

      &.toolbar-button {
        width: 8em;
      }

      &.push-left {
        margin-left: auto;
      }

      .no-data {
        padding: 0;
      }

      .is-loading .select-control .Select-control {
        display: inline-block;

        @include includeLoadingSkeleton($dark: true, $rounded: true, $size: 'md');
      }
    }
  }
}
