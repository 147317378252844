$field-min-height: 95px;

.validated-input {
  min-height: $field-min-height;

  .form-group {
    margin-bottom: 20px;
  }

  span.help-block {
    display: inline-block;
    padding-bottom: 0.2em;
    span {
      position: absolute;
    }
  }
}
