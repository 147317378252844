@import '@tame-your-theme/scss';
@import '../../../../../../theme/helpers/all.scss';

$status-width: 15;

.unsolicited-ratesheet-container {
  .unsolicited-ratesheet-filter {
    padding-bottom: 30px;
    justify-content: space-between;

    > div {
      gap: $tiny;
    }

    .form-group {
      margin-bottom: 0;
    }

    button {
      align-self: center;
      margin-top: $small;
    }

    .text-ellipsis,
    p:not(.datepicker-range-error) {
      color: $white;
    }

    .select-filter {
      width: 16rem;
    }

    .btn-solid-primary {
      font-weight: bold;
    }
  }

  .results-presenter {
    --unsolicited-ratesheet-status-sent: #aaaaaa;
    --unsolicited-ratesheet-status-accepted: #77b546;
    --unsolicited-ratesheet-status-unactioned: #249dda;

    table {
      margin-bottom: $huge;

      tr.is-loading-trades {
        .unsolicited-ratesheet-status {
          @include includeLoadingSkeleton($dark: true, $rounded: true);
        }

        .text-right > span {
          display: block;
          @include includeLoadingSkeleton($dark: true, $rounded: true);
        }
      }

      tr {
        td {
          &.status {
            width: $status-width + 2rem;
          }

          &:last-of-type.actions {
            width: 2rem;
            .btn-group button.btn.dropdown-toggle {
              min-width: initial;
            }
          }
        }
      }
    }

    td.with-principal {
      width: 20rem;
    }

    .unsolicited-ratesheet-status {
      background-color: var(--unsolicited-ratesheet-status-unactioned);
      display: inline-block;
      color: $dark-grey-color;
      width: $status-width + rem;
      text-align: center;
      border-radius: 10px;
      font-weight: bold;

      &.pending,
      &.bespoke-sent {
        background-color: var(--unsolicited-ratesheet-status-sent);
      }

      &.unsolicited-ratesheet-status-created-trades {
        background-color: var(--unsolicited-ratesheet-status-accepted);
      }
    }

    .select-checkbox {
      width: 1rem;
    }
  }

  .react-toggle {
    .react-toggle-track {
      border: 1px solid darken-theme-color(--background-main-contrast-color, 50%);
    }
  }

  .customer-filter .react-toggle {
    margin-top: $tiny;
    margin-right: $large;
  }
}

.unsolicited-ratesheet-create-trade-on-behalf {
  max-width: initial;
  width: 38rem;

  .actions {
    padding-top: $small;
    padding-right: $normal;
    padding-left: $normal;
    gap: $small;

    button {
      width: 11rem;
    }
  }

  > .popover-title {
    border-radius: $border-radius;
    background-color: var(--dark-white-background-contrast-color);

    label {
      color: var(--background-main-contrast-color);
    }
  }

  > .popover-content {
    padding: $small 0;
    background-color: $white;

    h3 {
      font-size: 1.8rem;
      font-weight: 400;
      color: var(--dark-white-background-contrast-color);
      padding-left: $small;
      padding-bottom: $small;
    }

    .validated-field {
      min-height: 100px;
      padding-top: 0;
      padding-bottom: 0;

      &.additional-funds {
        min-height: 115px;
      }
    }
  }
}
