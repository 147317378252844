@import '../../../../theme/helpers/all.scss';

.maturing-trades-transactions-chart-container {
  background-color: $very-dark-grey;
  margin-top: $normal;
  padding: $normal;

  h4 {
    margin-top: 0;
    color: $light-grey;
  }

  > .maturing-trades-transactions-chart {
    $maturing-trades-transactions-chart-bar-height: 15px;
    height: $maturing-trades-transactions-chart-bar-height;
  }
}
