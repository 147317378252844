@import '../../theme/helpers/all';

$padding-top-form: 5px;
$header-height: 233px;

.rfq-container {
  .rfq-header {
    position: fixed;
    left: $common-space;
    right: $common-space;
    background-color: var(--background-main-color);
    z-index: 10;

    &.no-scroll {
      right: $common-space;
    }

    > .table-responsive {
      margin-top: $common-margin;
    }
  }

  .trade-rfq-header {
    margin-top: $normal;
    padding: 0 $normal;
    height: 36rem;
    opacity: 1;

    @include transition(all 0.4s);

    &.collapsed {
      height: 0;
      overflow: hidden;
      opacity: 0;
    }
  }

  .rfq-body {
    > .table-responsive {
      margin-bottom: $common-margin;
    }
  }

  .skeleton-table {
    margin: $common-margin;
  }

  .summary {
    .summary-content {
      padding: $small;
    }

    .amount-total {
      display: flex;

      span:first-child {
        padding-right: 5px;
      }
    }
  }

  .header-row {
    padding: 0;
  }

  .panel-presenter .panel-filled .tab-pane {
    margin-top: $normal;
  }

  .panel-presenter .panel-filled .tab-pane:not(.unsolicited-ratesheets-pane),
  .trade-settings-panel {
    background-color: $very-dark-grey;
    border-radius: 6px;
  }

  .rfq-maturities-panel {
    background-color: $very-dark-grey;
    border-radius: 6px;
    margin-top: $small;

    > div.panel {
      padding-top: 25px;
      padding-bottom: 15px;
    }

    .panel-filled {
      margin: 0;
      margin-bottom: 10px;
      padding: 0 10px;
    }

    .btn-toggle {
      width: 20%;
      align-self: flex-end;

      .btn {
        &:not(.active) {
          color: $white;
        }

        border-color: $very-dark-grey;

        &:hover,
        &:active,
        &.focus {
          border-color: $very-dark-grey;
        }

        &:not(.active) {
          background-color: $grey-border-color;
        }

        &:first-child {
          width: 50%;
          margin-right: 1px;
          border-right-width: 3px;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          width: 50%;
          border-left-width: 3px;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }

    .customize-button {
      align-self: flex-end;
    }

    .checkbox-inline {
      padding: 0 2px;

      input[type='checkbox'] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }
    }

    .flex-container {
      display: flex;
      align-items: baseline;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }

    .maturity-buttons {
      padding-left: 10px;
      padding-right: 10px;

      > div {
        .btn {
          width: 100%;
        }

        .btn-solid-primary {
          border-color: $grey-border-color;
        }
      }

      .row {
        margin-right: 20px;

        [class*='col'] {
          padding: 0px;

          .btn {
            border-radius: 0px;
          }
        }

        &:nth-child(8) {
          [class*='col'] {
            width: 50%;
          }
        }
      }
    }

    &.set {
      h4.result-title {
        font-size: 120%;
        padding-bottom: 0;
        margin-bottom: 10px;
      }
    }

    .row-customize {
      .btn {
        margin-top: 20px;
        float: right;
      }
    }
  }

  .trade-settings-panel {
    > div.panel {
      padding-bottom: 6px;
    }
    .result-title {
      padding-top: 10px;
      padding-bottom: 0px;
    }
    .row {
      display: flex;
      width: 100%;
      margin: 0;
    }
    .panel-filled {
      margin: 0;
      margin-bottom: 10px;
      padding: $padding-top-form 0;
      display: flex;
      justify-content: space-between;

      .form-section {
        flex: 1;
        width: 860px;
      }

      .settlement-date-wrapper {
        padding: 5px 10px 0px 5px;
        min-width: 170px;

        .settlement-date {
          cursor: pointer;
          border-bottom: 1px solid;
          width: 160px;

          input {
            background: transparent;
            border: 0;
            height: 30px;
            width: 80%;

            &:focus {
              outline: none;
              -webkit-appearance: none;
            }

            &:hover {
              cursor: pointer;
            }

            ~ .icon-calendario {
              font-size: 1.2em;
              padding-right: 12px;
            }
          }
        }
      }

      button {
        align-self: center;
        margin-right: $small;
      }
    }

    .form form {
      margin-top: 0;
      width: 100%;
    }

    .validated-input .form-group {
      margin-bottom: 0;
    }

    .add-new-rfq-form .panel-filled {
      margin-bottom: 0;
    }
  }

  &.is-loading {
    .rfq-maturities-panel,
    .trade-settings-panel {
      @include includeLoadingSkeleton($include-content: false, $dark: true);
    }
  }

  .slide-tabs {
    margin: 0;

    .nav-tabs {
      > li ~ .slide {
        left: 0;
      }
    }

    .badge {
      color: $white;
      margin: 0;
      margin-bottom: 2px;
      margin-left: $tiny;
    }

    .value-highlight {
      margin-top: 10px;

      .white-color {
        font-size: 150%;
      }
    }
  }

  .btn-success:focus,
  .btn-success.focus {
    background-color: transparent;
  }

  .current-issuer-text {
    font-weight: bold;
    color: $white;
  }

  .issuer-name-container {
    gap: $tiny;
    align-items: center;

    .fossil-fuels-badge {
      color: $grey;
    }
  }

  .issuers-panel {
    .loader {
      text-align: center;
      width: 100%;
      padding-top: 10px;
      margin-bottom: $panel-margin-bottom;
    }

    > div.panel {
      margin-left: 0px;
    }

    .panel-filled {
      margin: 0;
      margin-bottom: 10px;
      padding: 5px 0;
    }

    .results-presenter .results {
      margin: 0;
      padding: 0;

      .table {
        td {
          vertical-align: middle;

          &.actions-column {
            width: 130px;

            label.react-display-flex {
              margin-bottom: 0;
              gap: $tiny;

              .react-toggle {
                &--checked + span {
                  color: var(--primary-color);
                  font-weight: bold;
                }
              }
            }

            svg {
              cursor: pointer;
              font-size: larger;
            }
          }

          &.issuer-cannot-quote {
            opacity: 0.4;

            ~ td {
              opacity: 0.4;
            }

            .react-display-flex {
              gap: $tiny;
            }
          }

          span.kyc-ok {
            font-size: 1rem;
            background-color: $success;
            color: $white;
            border-radius: $border-radius-medium;
            padding: 0 $tiny;
            font-weight: bold;
            margin-left: $tiny;
          }
        }

        .btn-table {
          width: 100%;

          .checkbox-inline {
            padding: 0;
          }
        }
      }
    }

    .tab-pane:not(.unsolicited-ratesheets-pane) {
      margin: 0 $normal;
    }

    .tab-pane.unsolicited-ratesheets-pane {
      margin-top: $tiny;
    }
  }
}

.settlement-modal .modal-dialog {
  min-width: 250px;

  .modal-header,
  .modal-body {
    padding: 20px;
  }

  .settlement-date-modal {
    .datepicker.datepicker-inline {
      margin: 0 auto;
    }
  }
}

.customize-date-modal {
  .modal-content .modal-body {
    .table-responsive {
      height: 300px;

      tr.is-custom {
        .input-group-addon,
        .imperium-datepicker {
          border-bottom-color: var(--primary-color);
          color: var(--primary-color);

          input {
            color: var(--primary-color);
          }
        }
      }

      .customize-dates-table {
        td:nth-of-type(1) {
          width: 100px;
        }
        td:nth-of-type(2) {
          width: 150px;
        }
        td:nth-of-type(3) {
          width: 250px;
        }

        tr {
          height: 52px;
        }
      }
    }
  }
  .btn {
    width: 100px;
  }
}

@media (min-height: $large-desktop-height) {
  .rfq-container .rfq-header {
    right: $common-space;
  }
}
