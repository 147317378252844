@import '../../theme/helpers/all';

.panel-presenter {
  > div {
    padding-left: 0;
    padding-right: 0;
  }

  .panel-filled {
    margin: 20px 30px 0;
    padding: 20px 20px 0;
    border-radius: $border-radius;

    .records-loading {
      margin-bottom: $panel-margin-bottom;
    }
  }

  .panel.panel-filled {
    background-color: transparent;
  }

  .no-records-message {
    margin-bottom: $panel-margin-bottom;
  }

  h4 {
    color: $white;

    &.result-title {
      display: inline-block;
      padding-bottom: 20px;
      font-size: 150%;
    }
  }
}
