@import '@tame-your-theme/scss';

@import '../../../theme/helpers/all.scss';

.file-uploader {
  border: 1px solid darken-theme-color(--background-main-contrast-color, 50%);
  padding: 0 $tiny;
  border-radius: $border-radius;
  width: 100%;
  max-width: 60rem;

  svg {
    font-size: 1.6rem;
    color: var(--background-main-contrast-color);

    &:last-of-type {
      margin-right: $tiny;

      &:hover {
        cursor: pointer;
        color: var(--primary-color);
      }
    }
  }

  button,
  label {
    height: 34px;
  }

  button {
    padding-left: $hair;
    display: flex;
    align-items: center;
    color: var(--background-main-contrast-color);
    width: 100%;

    &:hover {
      cursor: pointer;
      color: var(--primary-color);

      svg {
        color: var(--primary-color);
      }
    }

    &:active,
    &:focus {
      svg {
        color: var(--primary-color);
      }
    }
  }

  input[type='file'] {
    display: none;

    ~ label {
      padding: $tiny $small;
      padding-left: $hair;
      gap: $hair;
      margin-bottom: 0;
      color: var(--background-main-contrast-color);

      ~ span {
        flex: 1;
        margin-right: $small;
        color: var(--primary-color);
      }

      &:hover {
        cursor: pointer;
        color: var(--primary-color);

        svg {
          color: var(--primary-color);
        }
      }
    }
  }

  &.is-loading {
    @include includeLoadingSkeleton($dark: true);
  }

  &.is-downloading {
    cursor: progress;

    *,
    *:hover {
      cursor: progress;
    }

    button,
    svg {
      pointer-events: none;
    }
  }

  &.has-error {
    border-color: $error;

    svg {
      color: $error;
    }

    input[type='file'] ~ label {
      color: $error;

      :last-child {
        margin-left: $tiny;
      }
    }
  }
}
