@import '@tame-your-theme/scss';

@mixin border-radius($radius) {
  border-radius: $radius;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin button($primary-color, $primary-border, $secondary-color, $secondary-border) {
  @include border-radius(30px);

  color: $primary-color;
  background-color: $secondary-color;

  @if $primary-border {
    border: 1px solid $primary-color;
  } @else {
    border: 1px solid transparent;
  }

  padding: 8px 22px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 1.5px;
  box-shadow: none;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    background-color: $primary-color;
    color: $secondary-color;

    @if $secondary-border {
      border: 1px solid $secondary-color;
    } @else {
      border: 1px solid transparent;
    }
  }

  @include transition(all 0.4s);

  span {
  }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin box-shadow($args...) {
  -webkit-box-shadow: $args;
  box-shadow: $args;
}

@mixin image-2x($image, $width, $height, $path: '../../assets/', $extension: 'png') {
  width: $width;
  height: $height;
  background: url('#{$path}#{$image}.#{$extension}') no-repeat;
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    // on retina, use image that's scaled by 2
    background-image: url('#{$path}#{$image}@2x.#{$extension}');
    background-size: $width $height;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin setVariableColorWithRgb($color-name, $value, $hover: 0.4) {
  $red: red($value);
  $green: green($value);
  $blue: blue($value);
  #{$color-name}-rgb: $red, $green, $blue;
  #{$color-name}: unquote('rgb(var(#{$color-name}-rgb))');
  #{$color-name}-hover: unquote('rgba(var(#{$color-name}-rgb), #{$hover})');
}

@mixin setDarkWhiteTheme($theme) {
  @if $theme == dark {
    @include create-theme-color(--dark-white-background-color, $base-dark);
    @include create-theme-color(--dark-white-background-contrast-color, $white);
  } @else if $theme == white {
    @include create-theme-color(--dark-white-background-color, $white);
    @include create-theme-color(--dark-white-background-contrast-color, $base-dark);
  }
}

@mixin includeThemedSelect() {
  .Select {
    .Select-placeholder {
      color: var(--dark-white-background-contrast-color-hover);
    }

    .Select-control,
    .Select-control .Select-value {
      background-color: $grey-100;
      color: var(--dark-white-background-contrast-color);

      span.Select-value-label {
        color: var(--dark-white-background-contrast-color);
      }
    }

    &.is-pseudo-focused {
      .Select-control,
      .Select-control .Select-value {
        span.Select-value-label {
          color: var(--dark-white-background-contrast-color);
        }
      }
    }

    &.is-open {
      > .Select-control {
        &:active,
        &:focus-within,
        &:focus-visible,
        &:focus {
          border-color: transparent;
        }
      }

      .Select-menu-outer {
        background-color: var(--dark-white-background-color);
        box-shadow: none;
        border: 1px solid $grey-100;

        .Select-option {
          background-color: var(--dark-white-background-color);
          color: var(--dark-white-background-contrast-color-hover);
        }

        .Select-option.is-selected {
          background-color: $grey-100;
          font-weight: bold;
        }

        .Select-option.is-focused {
          background-color: $grey-100;
        }
      }
    }

    &.is-focused.is-open {
      > .Select-control,
      .Select-input:focus {
        background-color: $grey-100;
      }
    }
  }
}
