@import '../../theme/helpers/all';

div.header-row {
  padding: $common-padding 0;

  h1 {
    line-height: 5rem;
    margin: 0;
  }
}
