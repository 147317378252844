@import '../../theme/helpers/all';

$border-radius: 10px;

.alternative-actions-dialog {
  .modal-body {
    .flex {
      margin-bottom: $small;

      > span {
        font-size: 2rem;
        margin-bottom: $small;

        small {
          font-size: 1.2rem;
        }
      }
    }

    textarea {
      margin-bottom: $normal;
      border: 1px solid $modal-border-color;
      background-color: var(--background-main-color);
      color: var(--background-dark-contrast-color);
    }

    .flex-row {
      gap: $small;
      text-align: center;
      gap: $small;

      button {
        flex: 1;
        padding-top: $normal;
        padding-bottom: $normal;
        margin-bottom: $normal;
      }
    }

    .flex-column {
      > .btn-toolbar {
        margin: 0;
        display: flex;
        margin-bottom: $small;

        > .btn-group-vertical {
          flex: 1;

          .active {
            background-color: $yellow;
            color: $dark-grey-color;
          }
        }
      }
    }

    button {
      border-radius: 10px;
      font-size: 2rem;
      height: 6rem;
    }
  }

  &.is-loading {
    pointer-events: none;
  }
}
