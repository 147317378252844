@import '../../../../theme/helpers/guideline.scss';
@import '../../../../theme/helpers/palette.scss';

$field-min-height: 115px;
$border-error: 1px solid $error;

.validated-field {
  flex: 1;
  padding: $small;
  min-height: $field-min-height;

  .flex-row > span:first-child {
    padding-bottom: $tiny;
    color: $white;
    margin-right: $tiny;
  }

  .error-message {
    padding-top: $tiny;
    color: $error;
  }

  &.field-error {
    span:first-child {
      color: $error;
    }

    input.form-control {
      border: $border-error;

      &:focus {
        box-shadow: 0 0 0 1px $error;
      }
    }

    .datepicker-component {
      input {
        border: none;
        border-bottom: $border-error;

        + .input-group-addon {
          border-bottom: $border-error;
          color: $error;
        }
      }
    }
  }

  .popover & {
    .flex-row > span:first-child {
      color: var(--dark-white-background-contrast-color);
    }

    input.form-control,
    .form-group:not(.has-error) .form-control {
      border: transparent;
      background-color: darken-theme-color(--background-main-contrast-color, 50%);
    }
  }
}
