@import '../../theme/helpers/all';
@import '../../theme/helpers/mixins.scss';

.header-container.navbar {
  padding: 0;
  border: none;
  background-color: var(--header-color);
  border-bottom: 1px solid var(--header-color-hover);

  .container-fluid {
    padding-left: 0;
  }

  .btn-default {
    color: var(--header-contrast-color);
    border-color: var(--header-contrast-color);

    &:hover {
      color: var(--header-contrast-color-hover);
      border-color: var(--header-contrast-color-hover);
    }
  }

  .navbar-nav > li:last-child > div.logo {
    margin: 10px 25px 10px 15px;
  }

  .header-items {
    width: 50%;
  }

  .left-nav-toggle {
    position: relative;
    height: 60px;
    padding: 6px 12px;
    display: flex;
    align-items: center;

    a {
      margin-left: 6px;

      img {
        max-height: 50px;

        &.imperium-logo {
          width: 36px;
          height: 20px;
        }
      }
    }

    button {
      border: none;
      background-color: transparent;
      padding: 0;

      &:focus {
        outline: 0;
      }
    }

    > button:hover {
      color: $yellow;
    }
  }

  #mobile-menu .left-nav-toggle {
    background-color: $white;

    button {
      height: 100%;
    }
  }

  i.glyphicon.glyphicon-menu-hamburger {
    font-size: 20px;
    margin: 0 8px;
    color: var(--header-contrast-color);

    &:hover {
      color: var(--header-contrast-color-hover);
    }
  }

  .logo {
    @include image-2x('imperium-logo', 287px, 49px);
  }

  .header-dropdown {
    margin: 6px 3px;

    &:last-child {
      margin-right: $tiny;
    }

    > ul {
      margin-right: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    button.dropdown-toggle {
      height: 100%;
    }

    .dropdown-menu button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 13px;
      width: 100%;
      color: $dark-text;
      border: none;
      background-color: transparent;

      .shortcut {
        margin-left: 20px;
        font-size: 1rem;
        color: $grey-dark;
      }

      &:hover,
      &:focus {
        background-color: $grey-20;
        outline: 0;
      }
    }

    .header-dropdown-name {
      text-transform: none;

      &.padding-right-5 {
        padding-right: 5px;
      }
    }

    .header-dropdown-name,
    .header-dropdown-caret {
      padding-top: 10px;
    }

    .header-dropdown-image {
      background-color: $dark-orange;
      color: $white;
      font-size: 135%;
      height: 32px;
      margin: 4px 2px 0 5px;
      padding-top: 5px;
      width: 32px;
      text-align: center;
    }

    .header-dropdown-user {
      width: $large;
      height: $large;
      background-color: $light-silver;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 36px;
        height: 42px;
      }

      &:hover {
        background-color: $silver;
      }
    }

    .header-dropdown-actions {
      width: 125px;
      height: $large;
      font-weight: bold;
      color: var(--primary-contrast-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .navbar-right {
    display: flex;
    justify-content: center;
    align-items: center;

    li.notifications {
      svg {
        width: 2rem;
        height: 2rem;
        margin: 0 $small;
      }
    }
  }
}
