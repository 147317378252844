@import '../../../../../../theme/helpers/all.scss';

.bank-unsolicited-ratesheet-settings {
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  padding: 1rem;
  justify-content: end;
  margin-top: $small;

  .settings-column {
    display: grid;
    gap: 1rem;
    align-items: start;

    .toggle-bank-unsolicited-ratesheet {
      display: flex;
      justify-content: end;
      gap: 1rem;

      span {
        padding-top: $hair;
        width: 15rem;
      }

      .react-toggle {
        margin-top: 0;
        margin-right: 0;

        .react-toggle-track {
          border: 1px solid darken-theme-color(--background-main-contrast-color, 50%);
        }
      }

      &.is-loading {
        opacity: 0.7;
        pointer-events: none;

        .react-toggle .react-toggle-track {
          @include includeLoadingSkeleton($dark: true, $rounded: true);
        }
      }
    }

    .auto-quote-toggle-container {
      justify-content: end;
      width: 100%;

      span {
        width: 12rem;
      }
    }

    .bank-unsolicited-ratesheet-settings-max-amount {
      display: grid;

      label {
        text-align: right;
        cursor: pointer;
        color: $light-grey;
        font-weight: 500;
      }

      font-weight: bold;
      color: var(--secondary-text-color);

      input {
        font-size: 2.2rem;
        border-radius: 0;
        border: hidden;
        border-bottom: 1px solid transparent;
        background-color: transparent;
        padding: 0;
        text-align: right;
        height: 100%;
        width: 100%;

        &:hover,
        &:active,
        &:focus {
          outline: none;
          border-color: var(--secondary-text-color);
          background-color: transparent;
          box-shadow: none;
        }

        &:disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
