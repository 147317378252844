@import '../../theme/helpers/palette.scss';

.panel-container {
  display: flex;

  .loader-inner {
    margin-top: 1em;
    text-align: center;
  }

  .no-data {
    margin-bottom: 0.5em;
    text-align: center;
    color: $white;
  }

  .panel-presenter,
  .panel-filled {
    margin: 0;
    margin-top: 1em;
    min-height: 25em;
    height: 60em;
    width: 100%;
    padding-right: 0;
  }
}
