@import '../../../theme/helpers/guideline';
@import '../../../theme/helpers/palette';

$closed-drawer-height: 80px;
$draggable-container-size: 300px;
$popover-container-size: 350px;
$logo-size: 100px;

.proposed-tenant-drawer {
  &-header {
    width: 100%;
  }

  &,
  .drawer-content-wrapper,
  .drawer-content-wrapper .drawer-content {
    &:focus-visible {
      outline: none;
    }
  }

  .draggable-container {
    position: relative;
    width: $draggable-container-size;
    height: $draggable-container-size;
    margin: 15% auto;
    background-color: $grey-40;
    border-radius: $border-radius-medium;
    padding: $normal;

    &.drag-active {
      background-color: $grey-20;
      border: 1px dashed var(--dark-white-background-contrast-color);

      h3 {
        margin: auto 0;
      }
    }

    h3 {
      color: var(--dark-white-background-contrast-color);
      text-align: center;
      margin: 0;
      margin-bottom: $normal;
    }

    p,
    label {
      z-index: 1;
    }

    p {
      color: $grey-800;
      text-align: justify;
      margin-bottom: $normal;
    }

    img {
      width: $logo-size;
      margin: 0 auto;
    }

    input[type='file'] {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      left: 0;
      top: 0;
    }
  }

  .customers-container {
    margin: $normal;

    &-header {
      margin-bottom: $small;

      .error-message {
        color: $error;
        border-color: $error;
        margin-left: $normal;
      }

      .add-row {
        display: flex;
        align-items: center;
        gap: $tiny;
        margin-right: $small;
      }

      .save-button {
        padding: $tiny $normal;
      }
    }

    .handsontable td.htInvalid {
      background-color: $white !important;
      border: 2px solid $error;
    }
  }
}

.drawer-popover.error-list {
  max-width: $popover-container-size;

  ul {
    padding-left: $small;
    list-style-type: none;
  }
}
