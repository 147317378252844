@import '../../theme/helpers/all';
@import '../../theme/helpers/mixins.scss';

.menu-notifications {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }

  .blob {
    position: absolute;
    margin: 0;
    margin-top: 26px;
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.2;
  }
}

.notifications-popover {
  max-width: initial;

  .popover-content {
    width: 60rem;

    display: flex;
    flex-wrap: wrap;
    gap: $small;
    padding: $small;

    & > div {
      flex: 1 1 auto;
    }

    .summary-item {
      margin: 0;
    }
  }

  .summary-content {
    cursor: pointer;
    padding-left: $common-padding;
    padding-right: $common-padding;
  }
}
