@import '../../theme/helpers/all.scss';

$bootstrap-sass-asset-helper: false;
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/_bootstrap';

$font-size-base: 14px;

@import '../../theme/vendor/animate.css/animate.css';
@import '../../theme/vendor/bootstrap/css/bootstrap.css';
@import '../../assets/fonts/fonts';
@import '../../theme/vendor/animate.css/animate.css';
@import '../../theme/styles/style';
@import '../../theme/styles/login-style';
@import '../../theme/styles/react-select-override.scss';
@import '../../theme/styles/bootstrap-override.scss';
@import '../../theme/components/common.scss';
@import './theme.scss';
@import './popover.scss';

html,
body,
#root,
#main {
  height: 100%;
  overflow: auto;
}

html body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--background-main-color);
  @include transition(all ease-in-out 0.3s);
  font-family: 'Roboto', sans-serif;
  color: #aaaaaa;
}

.capitalize {
  text-transform: capitalize;
}

.upper-case {
  text-transform: uppercase;
}

.navbar {
  margin-bottom: 0px;

  &-fixed-top {
    z-index: $nav-bar-z-index;
  }
}

dl {
  margin-bottom: 0;
}

.initial-loader-container {
  align-items: center;
  display: flex;
  height: 100%;
  text-align: center;
  width: 100%;

  .loader {
    height: auto;
    width: 100%;
  }
}

.badge {
  cursor: pointer;
  &.full-width-badge {
    width: 100%;
    border-radius: 3px;
  }
}

.default-border-radius {
  border-radius: $border-radius;
}

.subnav {
  .badge {
    color: #777;
    margin-right: 5px;

    &.active {
      font-size: 110%;
      background-color: #f8bb52;
      color: #fff;
      font-weight: bold;
    }
  }
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#main {
  aside#navigation {
    z-index: 1029;
  }

  #content {
    position: relative;
    margin-top: 60px;
  }

  .fixed-initial-loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
  }
}

textarea {
  resize: none;
}

.visibility-hidden {
  visibility: hidden;
}

.btn-default {
  color: var(--grey-500);
  border-color: var(--grey-500);

  @include transition(all 0.4s);

  &:focus {
    color: var(--grey-500);
  }

  &:hover,
  &:focus,
  &:hover:active,
  &:active:focus,
  &.active:hover,
  &:active {
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary-color);
  }

  &:disabled {
    &:hover {
      background-color: transparent;
      color: var(--grey-500);
      border-color: var(--grey-500);
    }
  }
}

.btn-ghost-primary {
  color: var(--primary-color);
  border-color: var(--primary-color);

  &:hover,
  &:focus {
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color);
    color: var(--primary-contrast-color);
  }

  @include transition(background-color 0.4s);
}

.btn-solid-primary {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  border-color: var(--primary-color);
  @include transition(background-color 0.4s);

  &:hover,
  &:focus {
    background-color: var(--primary-color-hover);
    border-color: var(--primary-color);
    color: var(--primary-contrast-color);
  }

  &:not(:hover):not(:active):not(:focus) .loader-inner > div {
    background-color: var(--primary-contrast-color);
  }
}

.btn {
  line-height: initial;

  &.btn-link {
    outline: none;

    &:focus {
      outline: none;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
}

button.btn.btn-solid-primary {
  &:not(:hover):not(:active):not(:focus) {
    color: var(--primary-contrast-color);
  }
}

button.btn.btn-solid-primary:disabled,
.btn.btn-solid-primary:disabled {
  border-color: $mid-grey;
  background-color: $light-grey;

  &:hover {
    border-color: $mid-grey;
    background-color: $light-grey;
    color: var(--primary-contrast-color);
  }
}

.btn-table {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-right: 10px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
}

.btn span:first-child.glyphicon {
  padding-right: 5px;
}

.medium-blue {
  background-color: $medium-blue;
}

.btn.btn-blue-select {
  &.active,
  &.active.focus {
    background-color: $medium-blue;
    outline: 0;
    &:hover {
      background-color: $light-blue;
    }
    &:focus {
      outline: 0 !important;
    }
  }

  border: 1px solid $medium-blue;
}

.btn.btn-green-select {
  &:hover {
    background: $hover-green;
  }

  &.active,
  &.active.focus {
    background: $light-green;
    outline: 0;

    &:focus {
      outline: 0 !important;
    }
  }

  border: 1px solid $sandbox-green;
}

.btn.btn-red-disabled:disabled {
  border: 1px solid $error;
}

table.table {
  thead:first-child tr:first-child th {
    border-top: none;
  }

  &.middle {
    td {
      vertical-align: middle;
    }
  }

  > tbody > tr > td {
    padding: 8px;
    line-height: 1.4;

    &.text-right {
      text-align: right;
    }
  }

  > thead > tr > th {
    padding: 8px;

    &.text-right {
      text-align: right;
    }
  }

  tr td.actions button {
    border-color: $grey-70;

    > span {
      color: $grey-70;
    }

    &:last-of-type:not(.secondary) {
      border-color: $yellow;
      > span {
        color: $yellow;
      }

      &:hover {
        background-color: $light-orange;
      }
    }

    &:hover {
      &.btn > span {
        color: $dark-grey-color;
      }
      background-color: $grey-70;
    }
  }
}

.status-badge {
  background-color: $light-grey;
  display: inline-block;
  color: $dark-grey-color;
  width: 12rem;
  text-align: center;
  border-radius: 10px;
}

table.table-striped > tbody > tr:nth-of-type(even) {
  background-color: inherit;
}

.grey-box {
  background-color: $grey-box-background-color;
  width: 100%;
  padding: $normal;
  margin: $small;
}

.container-title {
  font-weight: bold;
  font-size: 60px;
}

.modal-dialog {
  .modal-header {
    padding: 3rem 2rem;
  }

  .modal-body {
    white-space: break-spaces;
  }

  button.close {
    @extend %rounded-button;
    opacity: 1;
  }
}

div.react-toggle {
  .react-toggle-track {
    background-color: var(--paper-contrast-color);
  }

  .react-toggle-thumb {
    background-color: var(--background-light-color);
  }

  &--checked {
    .react-toggle-thumb {
      border-color: $very-dark-grey;
    }
    .react-toggle-track {
      background-color: var(--primary-color);
    }
  }

  &:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--primary-color-hover);
  }
}

.tooltip {
  z-index: $tooltip-z-index;
}

.popover {
  z-index: $popover-z-index;
}

.drawer-popover {
  z-index: $drawer-z-index;
}

.light-primary-color {
  color: var(--primary-color);
}

.modal-content {
  $modal-darker-background: brightness(0.9);
  background-color: var(--background-main-color);
  color: var(--background-dark-contrast-color);

  .modal-header {
    background-color: var(--background-main-color);
    border-bottom-color: var(--background-dark-color);
    filter: $modal-darker-background;

    .modal-title {
      color: var(--background-dark-contrast-color);
    }
  }

  .modal-footer {
    border-top-color: var(--background-dark-color);
    background-color: var(--background-main-color);
    filter: $modal-darker-background;

    .btn.btn-default:not(.btn-solid-primary) {
      color: var(--primary-text-color);
      border-color: var(--primary-text-color);

      &:hover {
        color: var(--primary-color-hover);
        border-color: var(--primary-color-hover);
      }
    }
  }
}

.datepicker-component {
  table th {
    color: var(--background-dark-contrast-color);
  }
}

hr {
  border-color: var(--background-dark-color);
}

.navbar {
  $navbar-height: 50px;

  min-height: $navbar-height;
  border-bottom: 1px solid var(--header-color-hover);
  background-color: var(--header-color);
  color: var(--header-contrast-color);

  @include transition(all 0.5s);

  .navbar-collapse > ul > li {
    display: flex;
    flex-direction: row;
    min-height: $navbar-height;

    img {
      max-height: $navbar-height;
    }
  }

  .left-nav-toggle {
    padding-top: 12px;
  }

  .loader-inner > div {
    background-color: var(--primary-contrast-color);
  }

  .navbar-nav > li > a,
  .left-nav-toggle a i {
    color: var(--header-contrast-color);

    &:hover {
      color: var(--header-contrast-color-hover);
    }
  }
}

.Select.is-focused:not(.is-open) > .Select-control {
  border-color: transparent;
}

.is-disabled {
  opacity: 0.3;
  pointer-events: none;
}
