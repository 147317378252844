@import '@tame-your-theme/scss';

@import '../../../theme/helpers/guideline.scss';
@import '../../../theme/helpers/palette.scss';

:root {
  @include create-theme-color(--react-select-primary-color, $yellow);
  @include create-theme-color(--react-select-primary-contrast-color, $dark-grey-color);
  @include create-theme-color(--react-select-background-color, $base-dark);
  @include create-theme-color(--react-select-background-contrast-color, $grey-40);
}

@mixin change-select-colors(
  $background-color-name: --react-select-background-color,
  $background-contrast-color-name: --react-select-background-contrast-color,
  $primary-color-name: --react-select-primary-color,
  $primary-contrast-color-name: --react-select-primary-contrast-color
) {
  &__control {
    background-color: var(#{$background-color-name});
    color: var(#{$background-contrast-color-name});
    border-color: darken-theme-color(#{$background-contrast-color-name}, 50%);

    &--is-focused {
      border-color: var(#{$primary-color-name});
      box-shadow: 0 0 0 1px var(#{$primary-color-name});

      .my-react-select__single-value {
        color: var(#{$background-contrast-color-name});
      }

      &:hover {
        border-color: lighten-theme-color(#{$primary-color-name}, 10%);
      }
    }
  }

  &__indicator {
    padding: 0;

    &.my-react-select__dropdown-indicator {
      padding-right: $tiny;
    }

    svg {
      fill: var(#{$background-contrast-color-name});
    }
  }

  &__loading-indicator {
    color: var(#{$primary-color-name});
  }

  &__input-container {
    padding-bottom: $tiny;
  }

  &__input-container,
  &__placeholder,
  &__single-value {
    color: var(#{$background-contrast-color-name});
  }

  &__menu {
    background-color: lighten-theme-color(#{$background-color-name}, 5%);
    color: change-alpha(#{$background-contrast-color-name}, 0.4);
  }

  &__option {
    cursor: pointer;

    &:active,
    &--is-focused {
      background-color: lighten-theme-color(#{$primary-color-name}, 10%);
      color: var(#{$primary-contrast-color-name});
    }

    &--is-selected {
      background-color: var(#{$primary-color-name});
      color: var(#{$primary-contrast-color-name});
      font-weight: bold;
    }
  }
}
